import { useEffect } from 'react' 
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import FiltrosBoletos from "../../components/FiltrosBoletos";
import ListaDeBoletos from "../../components/ListaDeBoletos";

function Boletos() {

    useEffect (() => {
        document.title = 'Netclick - Boletos Bancários'
    }, [])

    return (
        <DivDados id = 'dados_boletos_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <>
                    <FiltrosBoletos />
                    <ListaDeBoletos />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Boletos;