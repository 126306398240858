import { useState, useEffect } from "react";
import usuarioLogado from "../../services/hg/usuarios";
import usuarioImage from '../../assets/img/usuario_perfil.png'
import './imagemusuarioperfil.css'

function ImagemUsuarioPerfil () {

    const [avatar, setAvatar] = useState <string> (usuarioImage)

    useEffect (() => {

        const avatarUsuario = async () => {
        
            try {
                const resposta = await usuarioLogado ()

                if (resposta.data.avatar === null || resposta.data.avatar === undefined) {
                    return
                }

                setAvatar (resposta.data.avatar)
        
            } catch (error: any) {

            }
        }

        avatarUsuario ()
        
    }, [setAvatar])

    return  (
        <>
            <img id = 'imagem_perfil' alt='logo do usuário logado' src={avatar}></img>
        </>
    )
}

export default ImagemUsuarioPerfil