import divProps  from '../../interfaces/propsDiv'
import { DivDadosDefault } from '../Styles'

function DivDados (props: divProps) {
    return (
        <DivDadosDefault id = {props.id}>
            {props.children}
        </DivDadosDefault>
    )
}

export default DivDados