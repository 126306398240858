import { createSlice } from "@reduxjs/toolkit"
import { assinaturaRequisicaoPaylod } from "../../../interfaces/assinaturas"

const filtroAssinaturasReduce = createSlice ({
    name: 'filtros_assinaturas',
    initialState: {
        situacao: 'ativa',
        cliente: ''
    } as assinaturaRequisicaoPaylod,
    reducers: {
        setFiltrosAssinatura (state, action) {
            Object.assign (state, {
                situacao: action.payload.situacao,
                cliente: action.payload.cliente
            })
        }
}})

export const { setFiltrosAssinatura } = filtroAssinaturasReduce.actions

export default filtroAssinaturasReduce.reducer