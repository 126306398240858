import dadosImg from '../../assets/img/sem_dados.png'
import dadosGraficoProps from '../../interfaces/propsNenhumDadoGrafico'
import './nenhumdadografico.css'

function NenhumDadoGrafico (props: dadosGraficoProps ) {

    return (
        <div id = 'sem_avaliacao_atendimento'>
            <img id = 'sem_dados_grafico' src = {dadosImg} alt = ''/>
            <p>{props.texto} <br/> {props.subtexto}</p>
        </div>
    )
}

export default NenhumDadoGrafico

