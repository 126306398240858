import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovoProcesso = createSlice ({
    name: 'modalNovoProcesso',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovoProcesso (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovoProcesso (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovoProcesso, setOcultarModalNovoProcesso } = modalNovoProcesso.actions

export default modalNovoProcesso.reducer