import nenhumResultado from '../../assets/img/procurar.png'
import './nenhumresultado.css'

function NenhumResultadoEncontrado () {

    return (
        <div id = 'nenhum_resultado_encontrato'>
            <img src= {nenhumResultado} alt = 'Nenhum Resultado da Pesquisa' />
            <p id = 'texto_nenhum_resultado_encontrato'>Nenhum Resultado Encontrato</p>
        </div>
    )
}

export default NenhumResultadoEncontrado

