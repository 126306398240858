import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuAdministrativo (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                {props.titulo}
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Colaboradores' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Notas Fiscais' />}
                {subMenu && <SubMenuOpcoes redirect = '/boletos' texto = 'Boletos Bancários' />}
                {subMenu && <SubMenuOpcoes redirect = '/tarefas' texto = 'Tarefas Automáticas' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Pesquisa de Satisfação' />}    
            </div>
                
        </div>
    )
}

export default MenuAdministrativo