import { createSlice } from "@reduxjs/toolkit"
import financeiroRequisicaoPaylod from "../../../interfaces/financeiroRequisicaoPayload"

let data = new Date()

let ano = data.getFullYear()

const filtroFinanceiroReduce = createSlice ({
    name: 'filtros_financeiro',
    initialState: {
        ano: `${ano}`
    } as financeiroRequisicaoPaylod,
    reducers: {
        setFiltrosFinanceiro (state, action) {
            Object.assign (state, {
                ano: action.payload.ano
            })
        }
}})

export const { setFiltrosFinanceiro } = filtroFinanceiroReduce.actions

export default filtroFinanceiroReduce.reducer