import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setExibirModalDadosTarefa } from '../../store/modules/modalVisualizarDadosTarefas'
import { tarefasAutoListaResposta } from '../../interfaces/tasks'
import { tarefasAuto, atualizarTarefa } from '../../services/hg/tasks'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { usuariosAtivos } from '../../services/hg/usuarios'
import listaDeServicos from '../../services/hg/servicosLista'
import servicosListaPayload from '../../interfaces/servicosListaPayload'
import desativarAutomacaoImg from   '../../assets/img/cancelar_boleto.png'
import VisualizarDadosTarefas from '../VisualizarDadosTarefas'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import './listatarefas.css'

function ListaDeTarefasAuto () {

    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const modalTarefasDadosEstado = useSelector ((store: RootStore) => store.modalVisualizarDadosTarefas)
    const filtrosAutoTarefas = useSelector ((store: RootStore) => store.filtrosAutoTarefas)
    const dispatch = useDispatch ()
    
    const [tarefas, setTarefas] = useState<tarefasAutoListaResposta []> ([])
    const [responsaveis, setResponsaveis] = useState <UsuariosAtivos []>([])
    const [servicoLista, setServicoLista] = useState<servicosListaPayload []>([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoServico, setConfirmacaoServico] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [modal, setModal] = useState (true)

    function exibirModalDadosTarefas (id: number) {

        dispatch (setExibirModalDadosTarefa ({
            exibir: true,
            id: id
        }))
    }

    async function updateTarefa (idTarefa: number) {

        setSolicitacao (true)

        const payload = {
            automacao: false
        }

        try {

            const resposta = await atualizarTarefa (idTarefa, payload)
            
            if ( resposta.status === 200) {
                setSolicitacao (false)
                setConfirmacao (true)
                
                setTimeout (() => {
                    setConfirmacao (false)
                    
                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2000)
            }
            
        } catch (error) {

            setSolicitacao (false)
            return alert ('Falha ao Salvar os Dados')
        }
    }

    async function updateTarefaServico (idTarefa: number, e: any) {

        setSolicitacao (true)

        const payload = {
            servico_id: e.target.value
        }

        try {

            const resposta = await atualizarTarefa (idTarefa, payload)
            
            if ( resposta.status === 200) {
                setSolicitacao (false)
                setConfirmacaoServico (true)
                
                setTimeout (() => {
                    setConfirmacaoServico (false)
                    
                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2000)
            }
            
        } catch (error) {

            setSolicitacao (false)
            return alert ('Falha ao Salvar os Dados')
        }
    }

    async function updateTarefaResponsavel (idTarefa: number, e: any) {

        setSolicitacao (true)

        const payload = {
            responsavel: e.target.value
        }

        try {

            const resposta = await atualizarTarefa (idTarefa, payload)
            
            if ( resposta.status === 200) {
                setSolicitacao (false)
                setConfirmacaoServico (true)
                
                setTimeout (() => {
                    setConfirmacaoServico (false)
                    
                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2000)
            }
            
        } catch (error) {

            setSolicitacao (false)
            return alert ('Falha ao Salvar os Dados')
        }
    }

    useEffect (() => {
        setModal (modalTarefasDadosEstado.exibir)
    }, [modalTarefasDadosEstado, modal])


    useEffect (() => {

        const listadeTarefas = async () => {

            setSolicitacao (true)
            
            const payload = {
                cliente: filtrosAutoTarefas.cliente,
                servico: filtrosAutoTarefas.servico,
                responsavel: filtrosAutoTarefas.responsavel
            }

            try {
                const resposta = await tarefasAuto (payload)
                setTarefas(resposta.data)
                setSolicitacao (false)
                setResultadoPesquisa (false)

                if (resposta.data.length === 0) {
                    setResultadoPesquisa (true)
                }
                
            } catch (error) {
                return alert ('Falha ao Recuperar os Dados')
            }
        }

        listadeTarefas ()

    }, [filtrosAutoTarefas, atualizarDados])

    useEffect (() => {

        const listaServicos = async () => {
        
            try {
                const resposta = await listaDeServicos()
                setServicoLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de Serviços')
            }
        }

        listaServicos ()

    }, [])

    useEffect (() => {

        const usuariosLista = async () => {

            try {
                const resposta = await usuariosAtivos ()
                return setResponsaveis (resposta.data)
                
            } catch (error) {
                return alert ('Erro ao Listar os Responsáveis')
            }
        }

        usuariosLista ()
    }, [])

    return (
        <div id = 'dados_tarefas'>
            {modal && <VisualizarDadosTarefas />}
            {solicitacao && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Automação Desativada com Sucesso' />}
            {confirmacaoServico && <ConfirmacaoOperacao texto='Tarefa Atualizada com Sucesso' />}
            {<table id = 'tabela_tarefas'>
                <thead>
                    <tr id = 'tarefa_Cabecalho'>
                        <th id='nome_cliente_tarefa'>CLIENTE</th>
                        <th id='titulo_tarefa'>TÍTULO</th>
                        <th id='servico_auto_tarefa_coluna'>SERVIÇO</th>
                        <th>TIPO</th>
                        <th>RESPONSÁVEL</th>
                        <th align='center'>PRIORIDADE</th>
                        <th align='center'>OPÇÕES</th>
                    </tr>
                </thead>

                <tbody>
                    {tarefas.map ((tarefa) => (
                        <tr key={tarefa.id} id = 'lista_tarefas'>
                            <td id = 'nome_cliente_tarefa' onClick={() => exibirModalDadosTarefas(tarefa.id)}>{tarefa.cliente.nome_fantasia.toUpperCase()}</td>
                            <td id = 'titulo_tarefa' onClick={() => exibirModalDadosTarefas(tarefa.id)}>{tarefa.titulo.toUpperCase()}</td>
                            <td>
                                <select id = 'select_lista_auto_tarefas' value={tarefa.servico.id} onChange={(e) => updateTarefaServico (tarefa.id, e)}>
                                    {servicoLista.map ((servico) => (
                                        <option key={servico.id} value={servico.id}>{servico.nome.toUpperCase()}</option>
                                    ))}
                                </select>
                            </td>
                            <td onClick={() => exibirModalDadosTarefas(tarefa.id)}>{tarefa.tipo.toUpperCase()}</td>
                            <td onClick={() => exibirModalDadosTarefas(tarefa.id)}>
                                <span id = 'responsavel_tarefa'>
                                    <img id = 'usuario_tarefa' src = {tarefa.usuario.avatar === null ? '' : tarefa.usuario.avatar} alt = ''/>
                                    {tarefa.usuario.nome.toUpperCase()} 
                                </span>
                            </td>
                            <td align='center' onClick={() => exibirModalDadosTarefas(tarefa.id)}>
                                <DivSituacao texto = {tarefa.prioridade.toUpperCase()} cor = {tarefa.prioridade.toLocaleLowerCase()}/>
                            </td>
                            <td align='center'>
                                <img id = 'excluir_tarefa_img' title='Desativar Automação' src = {desativarAutomacaoImg} alt = '' onClick={() => updateTarefa (tarefa.id)}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeTarefasAuto