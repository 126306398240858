import ImagemUsuarioPerfil from "../ImagemUsuarioPerfil";
import './constausuario.css'

function ContaUsuario () {

    return  (
        <div className = 'conta_usuario'>
            <div id = 'conta_usuario'>
                <ImagemUsuarioPerfil />
                <p>Minha Conta</p>
            </div>
        </div>
    )
}

export default ContaUsuario