import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { faturamentoMes } from '../../services/hg/graficos'
import { dadosGraficoFaturamentoMesRespostaPayload } from '../../interfaces/graficos'
import inadimplenciaImg from '../../assets/img/divida.png'
import CarregandoPontinhos from '../CarregandoPontinhos'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import Perfil from '../../middleware/perfil'
import './dadosfinanceiro.css'

function DadosFinanceiros () {

    const filtrosFinanceiro = useSelector ((store: RootStore) => store.filtrosFinanceiro)
    const [dados, setDados] = useState <dadosGraficoFaturamentoMesRespostaPayload []>([])
    const [valorMaximo, setValorMaximo] = useState(0)
    const [solicitacao, setSolicitacao] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {
        let valorMaximo = 0

        dados.forEach (valor => {
            if (Number(valor.faturamento) > valorMaximo) {
                valorMaximo = Number(valor.faturamento)
            }
        })

        return setValorMaximo(Number(valorMaximo.toFixed()))
    }, [dados])

    useEffect (() => {

        const graficoFaturamentoMes = async () => {

            const payload = {
                ano: filtrosFinanceiro.ano
            }
            setSemDados (false)
            setGrafico (false)
            setSolicitacao (true)

            try {
                const resposta = await faturamentoMes (payload)
                setSolicitacao (false)
                
                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                
                setGrafico (true)
                setDados (resposta.data)

            } catch (error) {
                
            }
        }

        graficoFaturamentoMes ()

    }, [filtrosFinanceiro])


    return (

        <Perfil perfil={['master']}>
            <div id = 'dadosFinancieros'>
                <section id = 'principaisIndicadoresFinanceiros'>
                    <span>
                        <img src={inadimplenciaImg} alt = '' width={70}/>
                        <div>
                            <h1>18,12%</h1>
                            <p>Inadimplência Instantânea</p>
                        </div>
                    </span>

                    <span>
                        <img src={inadimplenciaImg} alt = '' width={70}/>
                        <div>
                            <h1>10,45%</h1>
                            <p>Inadimplência Acumulada</p>
                        </div>
                    </span>
                </section>

                <section id = 'graficoBalancoMensal'>
                    <span>
                        <h1>R$ 1.2345,78</h1>
                        <p>Balanço Mensal</p>
                    </span>

                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>➕</p>
                            <p>Receitas</p>
                        </span>
                        <h1>R$ 1.2345,67</h1>
                    </span>
                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>➖</p>
                            <p>Despesas</p>
                        </span>
                        <h1>R$ 1.2345,67</h1>
                    </span>
                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>😍</p>
                            <p>Balanço Mensal</p>
                        </span>
                        <h1>R$ 1.2345,67</h1>
                    </span>
                </section>

                <section id = 'graficoBalancoAnual'>
                    <span>
                        <h1>R$ 10.2345,78</h1>
                        <p>Balanço Anual</p>
                    </span>

                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>➕</p>
                            <p>Receitas</p>
                        </span>
                        <h1>R$ 9.2345,67</h1>
                    </span>
                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>➖</p>
                            <p>Despesas</p>
                        </span>
                        <h1>R$ 10.2345,67</h1>
                    </span>
                    <span id = 'balanco_financeiro'>
                        <span>
                            <p id = 'sinais_financeiro'>😍</p>
                            <p>Balanço Anual</p>
                        </span>
                        <h1>R$ 1.2345,67</h1>
                    </span>
                </section>

                <section id = 'graficoFaturamentoMes'>
                    <p id = 'p_faturamento_mes'>FATURAMENTO x DESPESAS</p>
                    {solicitacao && <CarregandoPontinhos cor='pontinhosFundoBranco' />}
                    {grafico && <ResponsiveContainer>
                        <AreaChart data={dados} margin={{ top: 10, right: 50, left: 0, bottom: 0 }}>
                            <defs>
                                <linearGradient id="faturamento" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#00A6A6" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#00A6A6" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="periodo" />
                            <YAxis domain={[0, valorMaximo]} allowDecimals={false}/>
                            <CartesianGrid strokeDasharray="6 6" />
                            <Tooltip separator=': R$ '/>
                            <Area type="monotone" dataKey="faturamento" strokeWidth={2} stroke="#008000"  activeDot = {{r: 8}} fillOpacity={0.8} fill="url(#faturamento)" />
                        </AreaChart>
                    </ResponsiveContainer>}
                    {semDados && <NenhumDadoGrafico texto='Nenhum dado' subtexto='foi encontrado' />}
                </section>
            </div>
        </Perfil>
    )
}

export default DadosFinanceiros