import { useState, useEffect } from 'react'
import { listaBoletosInter } from '../../services/inter/boletos'
import { visualizarBoletoPdf } from '../../services/inter/boletos'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalCancelarBoleto } from '../../store/modules/ModalCancelarBoleto'
import ModalCancelarBoleto from '../ModalCancelarBoleto'
import CarregandoCirculo from '../CarregandoCirculo'
import boletosRespostaPayload from '../../interfaces/boletosRespostaPayload'
import visualizarBoletoImg from '../../assets/img/visualizar_boleto.png'
import enviarEmailImg from '../../assets/img/enviar_email.png'
import cancelarBoletoImg from   '../../assets/img/cancelar_boleto.png'
import ErrorOperacao from '../ErroOperacao'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import './listaboletos.css'

function ListaDeBoletos () {

    const filtrosBoletos = useSelector((store: RootStore) => store.filtrosBoletos)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const modalCancelarBoleto = useSelector ((store: RootStore) => store.ModalCancelarBoleto)
    const dispatch = useDispatch ()

    const [boletos, setBoletos] = useState<boletosRespostaPayload[]> ([])
    const [modal, setModal] = useState (false)
    const [solicitacao, setSolicitacao] = useState (false)
    const [processandoBoleto, setProcessandoBoleto] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)

    function exibirModalCancelarBoleto (nossoNumero: number) {

        dispatch (setExibirModalCancelarBoleto ({
            exibir: true,
            id: nossoNumero
        }))
    }


    function visualizarPdf (nossoNumero: string) {

        const pdf = async () => {

            setProcessandoBoleto (true)

            try {
                const resposta = await visualizarBoletoPdf (nossoNumero)
                const pdf = resposta.data

                window.open(pdf, '_blank')

                setProcessandoBoleto (false)

            } catch (error) {
                setProcessandoBoleto (false)
                return <ErrorOperacao texto='Ocorreu um Erro ao Gerar o Pdf. Tente Novamente' />
            }
        }

        pdf ()

    }

    useEffect (() => {
        const listadeBoletos = async () => {

            setSolicitacao (true)

            const payload = {
                dataInicial: `${filtrosBoletos.dataInicial}`,
                dataFinal: `${filtrosBoletos.dataFinal}`,
                situacao: `${filtrosBoletos.situacao}`,
                cliente: `${filtrosBoletos.cliente}`
            }
            try {
                const resposta = await listaBoletosInter (payload)
                setBoletos (resposta.data.content)
                setSolicitacao (false)
                setResultadoPesquisa (false)

                if (resposta.data.content.length === 0) {
                    setResultadoPesquisa (true)
                }

            } catch (error) {
                console.log (error)
            }
        }

        listadeBoletos ()

    }, [filtrosBoletos, atualizarDados])

    useEffect (() => {
        setModal (modalCancelarBoleto.exibir)
    }, [modalCancelarBoleto, modal])

    return (
        <div id = 'dados_boletos'>
            {modal && <ModalCancelarBoleto />}
            {solicitacao && <CarregandoCirculo />}
            {processandoBoleto && <CarregandoCirculo />}
            <table id = 'tabela_boletos'>
                <thead>
                    <tr id = 'boleto_Cabecalho'>
                        <td id = 'nome_cliente'>CLIENTE</td>
                        <td id='descricao_boleto'>DESCRIÇÃO</td>
                        <td id = 'valor_boleto'>VALOR</td>
                        <td align='center' id ='vencimento_boleto'>VENCIMENTO</td>
                        <td align='center' id = 'situacao_boleto'>SITUACAO</td>
                        <td align='center' id = 'opcoes_boletos'>OPÇÕES</td>
                    </tr>
                </thead>

                <tbody>
                    {boletos.map ((boleto) => (
                        <tr key={boleto.nossoNumero} id = 'lista_boletos'>
                            <td id = 'nome_cliente'>{boleto.pagador.nome.toUpperCase()}</td>
                            <td id = 'descricao_boleto'>{boleto.mensagem.linha1.toUpperCase()}</td>
                            <td id = 'valor_boleto'>{boleto.valorNominal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            <td align='center'>{`${boleto.dataVencimento.slice(8,10)}/${boleto.dataVencimento.slice(5,7)}/${boleto.dataVencimento.slice(0,4)}`}</td>
                            <td align='center'>
                                <DivSituacao texto= {boleto.situacao}  cor= {boleto.situacao.toLowerCase()}/>
                            </td>
                            <td align='center'>
                                <div id = 'boletos_opcoes'>
                                    <img title='Visualizar Boleto' id = 'visualizar_boleto' className= {boleto.situacao} alt='Visualizar Boleto' src= {visualizarBoletoImg} onClick = {() => visualizarPdf(boleto.nossoNumero)}/>
                                    <img title='Enviar Boleto por E-mail' id = 'enviar_email_boleto' className= {boleto.situacao} alt='Enviar por E-mail' src= {enviarEmailImg} onClick = {() => alert('Função em Desenvolvimento')}/>
                                    <img title='Cancelar Boleto' id = 'cancelar_boleto' className= {boleto.situacao} alt='Cancelar Boleto' src= {cancelarBoletoImg} onClick = {() => exibirModalCancelarBoleto (parseInt(boleto.nossoNumero))}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeBoletos