import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { listaReceitas } from '../../services/hg/receitas'
import { receitasListaResposta } from '../../interfaces/receitas'
import maisOpcoesImg from '../../assets/icons/maisOpcies.png'
import CarregandoCirculo from '../CarregandoCirculo'
import ModalOpcoesReceitas from '../ModalOpcoesReceita'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import ModalNovaReceita from '../ModalNovaVenda'

import './listareceita.css'

function ListaDeReceitas () {

    const modalNovaVendaEstado =  useSelector ((store: RootStore) => store.modalNovaVenda)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const filtrosReceitas = useSelector ((store: RootStore) => store.filtrosReceitas)
    
    const [modalNovaVenda, setModalNovaVenda] = useState (false)
    const [receitas, setReceitas] = useState<receitasListaResposta[]> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [receitaAtrasada, setReceitaAtrasada] = useState (0)
    const [receitaAberta, setReceitaAberta] = useState (0)
    const [receitaPaga, setReceitaPaga] = useState (0)
    const [receitaTotal, setReceitaTotal] = useState (0)
    const [modalReceitas, setModalReceitas] = useState({
        exibir: false,
        index: 0
    })
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)


    function exibirModalOpcoesReceitas (posicao: number) {
        return setModalReceitas ({
            exibir: !modalReceitas.exibir,
            index: posicao
        })
    }


    useEffect (() => {
        setModalNovaVenda (modalNovaVendaEstado.exibir)
    }, [modalNovaVendaEstado, modalNovaVenda])


    useEffect (() => {
        const listadeReceitas = async () => {

            setSolicitacao (true)

            const payload = {
                dataInicial: filtrosReceitas.dataInicial,
                dataFinal: filtrosReceitas.dataFinal,
                situacao: filtrosReceitas.situacao,
                cliente: filtrosReceitas.cliente
            }
            try {
                const resposta = await listaReceitas (payload)
                setReceitas(resposta.data)
                setSolicitacao (false)
                setResultadoPesquisa (false)

                if (resposta.data.length === 0) {
                    setResultadoPesquisa (true)
                }

            } catch (error) {
                console.log (error)
            }
        }

        listadeReceitas ()

    }, [filtrosReceitas, atualizarDados])

    useEffect (() => {
        let receitaAberta = 0
        let receitaPaga = 0
        let receitaAtrasada = 0
        let receitaTotal = 0

        receitas.forEach (receita => 
            receitaAberta += Number (receita.situacao === 'aberta' ? receita.valor_total : 0),
        )

        receitas.forEach (receita => 
            receitaPaga += Number (receita.situacao === 'paga' ? receita.valor_total : 0),
        )

        receitas.forEach (receita => 
            receitaAtrasada += Number (receita.situacao === 'vencida' ? receita.valor_total : 0),
        )

        receitas.forEach (receita => 
            receitaTotal += Number(receita.valor_total)
        )
        
        setReceitaAberta (receitaAberta)
        setReceitaPaga (receitaPaga)
        setReceitaAtrasada (receitaAtrasada)
        setReceitaTotal (receitaTotal)

    }, [receitas])

    return (
        <div id = 'dados_receitas'>
            {solicitacao && <CarregandoCirculo />}
            {modalNovaVenda && <ModalNovaReceita />}
            {<table id = 'tabela_receitas'>
                <thead>
                    <tr id = 'receita_Cabecalho'>
                        <th id = 'nome_cliente'>CLIENTE</th>
                        <th id='descricao_receita'>DESCRIÇÃO</th>
                        <th id = 'valor_receita'>VALOR</th>
                        <th align='center'>VENCIMENTO</th>
                        <th align='center' id = 'pagamento_receita'>PAGAMENTO</th>
                        <th align='center'id='valor_recebido'>VALOR RECEBIDO</th>
                        <th align='center'>FORMA DE PAGAMENTO</th>
                        <th align='center' id = 'situacao_receita'>SITUACAO</th>
                        <th align='center' id = 'opcoes_receitas'>OPÇÕES</th>
                    </tr>
                </thead>

                <tbody>
                    {receitas.map ((receita, index) => (
                        <tr key={receita.id} id = 'lista_receitas'>
                            <td id = 'nome_cliente'>{receita.cliente.nome_fantasia.toUpperCase()}</td>
                            <td id = 'descricao_receita'>{receita.descricao.toUpperCase()}</td>
                            <td id = 'valor_receita'>R$ {receita.valor_total.replace(".", ",")}</td>
                            <td align='center'>{`${receita.vencimento.slice(8,10)}/${receita.vencimento.slice(5,7)}/${receita.vencimento.slice(0,4)}`}</td>
                            <td align='center' id = 'pagamento_receita'>{receita.pagamento === null ? '': `${receita.pagamento.slice(8,10)}/${receita.vencimento.slice(5,7)}/${receita.vencimento.slice(0,4)}`}</td>
                            <td align='center'id='valor_recebido'>{receita.valor_recebido === null ? '': Number(receita.valor_recebido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }</td>
                            <td align='center'>{receita.forma_recebimento === null ? '': receita.forma_recebimento.toUpperCase() }</td>
                            <td align='center'>
                                <DivSituacao texto= {receita.situacao.toUpperCase()} cor = {receita.situacao.toLowerCase()}/>
                            </td>
                            <td align='center'>
                                <div id = 'receitas_opcoes'>
                                    <img id='maisopcoesReceitasImg' src={maisOpcoesImg} alt = '' width={25} onClick={() => exibirModalOpcoesReceitas (index)}/>
                                    {modalReceitas.exibir && modalReceitas.index === index && <ModalOpcoesReceitas situacaoReceita={receita.situacao} idReceita={receita.id}/>}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
            <div id = 'totais_receita'>
                <section>
                    <p>ATRASADO</p>
                    <h1 id = 'total_receita_atrasada'>{receitaAtrasada.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h1>
                </section>

                <section>
                    <p>EM ABERTO</p>
                    <h1 id = 'total_receita_aberta'>{receitaAberta.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h1>
                </section>

                <section>
                    <p>PAGO</p>
                    <h1 id = 'total_receita_paga'>{receitaPaga.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h1>
                </section>

                <section>
                    <p>TOTAL</p>
                    <h1>{receitaTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</h1>
                </section>
            </div>
        </div>
    )
}

export default ListaDeReceitas