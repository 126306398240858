import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovaAssinaturaReduce = createSlice ({
    name: 'modal_nova_assinatura',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovaAssinatura (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovaAssinatura (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovaAssinatura, setOcultarModalNovaAssinatura } = modalNovaAssinaturaReduce.actions

export default modalNovaAssinaturaReduce.reducer