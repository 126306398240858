import { createSlice } from "@reduxjs/toolkit"
import { vendaRequisicaoPaylod } from "../../../interfaces/vendas"

let data = new Date()

let ano = data.getFullYear()
let mesAtual = data.getMonth() + 1
let qtnDiasMes = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()

let mesAtualFormatado = (`0${mesAtual}`).slice (-2)


let dataInicial = `${ano}-${mesAtualFormatado}-01`
let dataFinal = `${ano}-${mesAtualFormatado}-${qtnDiasMes}`

const filtroVendasReduce = createSlice ({
    name: 'filtros_vendas',
    initialState: {
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        cliente: ''
    } as vendaRequisicaoPaylod,
    reducers: {
        setFiltrosVendas (state, action) {
            Object.assign (state, {
                dataInicial: action.payload.dataInicial,
                dataFinal: action.payload.dataFinal,
                cliente: action.payload.cliente
            })
        }
}})

export const { setFiltrosVendas } = filtroVendasReduce.actions

export default filtroVendasReduce.reducer