import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { responsaveisPayload } from '../../interfaces/responsaveis'
import { listaDeResponsaveis } from '../../services/hg/responsaveis'
import CarregandoCirculo from '../CarregandoCirculo'
import DivSituacao from '../DivSituacao'
import './listaderesponsaveis.css'

function ListaDeResponsaveis () {

    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const [responsaveis, setResponsaveis] = useState<responsaveisPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (true)

    const responsaveisDados =  (responsaveis: string, id: number) => {
        alert (`O cliente selecionado foi ${responsaveis} e o id é ${id}`)
    }

    useEffect (() => {

        const responsaveisLista = async () => {

            try {
                const resposta = await listaDeResponsaveis ()
                console.log (resposta.data)
                setSolicitacao (false)
                setResponsaveis(resposta.data)
            } catch (error) {
                alert ('Erro ao listar os responsáveis')
            }
        }

        responsaveisLista ()

    }, [setResponsaveis, atualizarDados])

    return (
        <div id = 'dados_responsaveis'>
            {solicitacao && <CarregandoCirculo />}
            {<table id = 'tabela_responsaveis'>
                <thead>
                    <tr id = 'responsaveisCabecalho'>
                        <td id = 'nome_responsavel'>NOME</td>
                        <td>TELEFONE</td>
                        <td>EMAIL</td>
                        <td>CIDADE</td>
                        <td align = 'center' id = 'estado'>ESTADO</td>
                        <td align = 'center'id = 'situacao_responsavel'>STATUS</td>
                    </tr>
                </thead>

                <tbody>

                    {responsaveis.map ((responsavel) => (
                        <tr key={responsavel.id} id = 'lista_responsaveis' onClick={() => responsaveisDados(responsavel.nome, responsavel.id)}>
                            <td id = 'nome_responsavel'>{responsavel.nome.toUpperCase()}</td>
                            <td>{responsavel.telefone}</td>
                            <td>{responsavel.email.toUpperCase()}</td>
                            <td id = 'cidade'>{responsavel.cidade.toUpperCase()}</td>
                            <td align = 'center' id = 'estado'>{responsavel.estado.toUpperCase()}</td>
                            <td id = 'situacao_responsavel' align = 'center'>
                                <DivSituacao texto= {responsavel.situacao.toUpperCase()} cor = {responsavel.situacao.toLowerCase()} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    )
}

export default ListaDeResponsaveis