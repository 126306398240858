import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { RootStore } from "../../store"
import DivDados from "../../components/DivDados"
import FiltrosProcessosAndamento from "../../components/filtrosProcessosAndamento"
import ListaDeProcessosAndamento from "../../components/ListaDeProcessosAndamento"
import ModalNovoProceso from "../../components/ModalNovoProcesso"
import ModalNovaTarefaProcesso from "../../components/ModalNovaTarefaProcesso"
import VisualizarDadosProcessosAndamento from "../../components/VisualizarDadosProcessosAndamento"
import VisualizarDadosTarefas from "../../components/VisualizarDadosTarefas"

function ProcessosEmAndamento() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovoProcesso)
    const modalProcessosAndamento = useSelector ((store: RootStore) => store.modalVisualizarDadosProcessosAndamento)
    const modalDetalhesTarefa = useSelector ((store: RootStore) => store.modalVisualizarDadosTarefas)
    const modalTarefafaProcesso = useSelector ((store: RootStore) => store.modalNovaTarefaProcesso)

    const [modal, setModal] = useState (false)
    const [modalProcesso, setModalProcesso] = useState (false)
    const [modalTarefa, setModalTarefa] = useState (false)
    const [modalNovaTarefaProceso, setModalTarefaProcesso] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        setModalProcesso (modalProcessosAndamento.exibir)
    }, [modalProcessosAndamento, modalProcesso])

    useEffect (() => {
        setModalTarefa (modalDetalhesTarefa.exibir)
    }, [modalDetalhesTarefa, modalTarefa])

    useEffect (() => {
        setModalTarefaProcesso (modalTarefafaProcesso.exibir)
    }, [modalTarefafaProcesso, modalNovaTarefaProceso])

    useEffect (() => {
        document.title = 'Netclick - Processos em Andamento'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <>
                {modalNovaTarefaProceso && <ModalNovaTarefaProcesso />}
                {modal && <ModalNovoProceso />}
                {modalProcesso && <VisualizarDadosProcessosAndamento />}
                {modalTarefa && <VisualizarDadosTarefas />}
                <FiltrosProcessosAndamento />
                <ListaDeProcessosAndamento />
            </>
        </DivDados>
    )
  }
  
  export default ProcessosEmAndamento