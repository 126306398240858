import baseApiHg from "../api/config"
import { clienteCadastrarPayload, clienteAtualizarPayload } from "../../../interfaces/client"
import filtrosClientesRequisicaoPayload from "../../../interfaces/filtrosClientesRequisicao"

export function cadastrarCliente (payload: clienteCadastrarPayload) {
    return baseApiHg.post ('/clientes/criar', payload)
}

export function listaDeClientes () {
    return baseApiHg.get('/clientes/lista')
}

export function clienteId (id: any) {
    return baseApiHg.get(`/clientes/lista/${id}`)
}

export function atualizarCliente (id: number, payload: clienteAtualizarPayload) {
    return baseApiHg.patch(`/cliente/${id}`, payload)
}

export function listaClientes (payload: filtrosClientesRequisicaoPayload) {
    return baseApiHg.get(`/clientes/lista?termo=${payload.termo}&situacao=${payload.situacao}`)
}

export function clienteTermoPesquisa (termo: string) {
    return baseApiHg.get(`/clientes/lista?termo=${termo}`)
}

export function clienteCadastrado () {
    return baseApiHg.get ('/clientes/lista?situacao= ')
}