import { useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { listarLeads } from '../../services/hg/leads'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuComercial (props: menuOpcoes) {

    const filtrosLeads = useSelector ((store: RootStore) => store.filtrosLeads)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const [subMenu, setSubMenu] = useState(false)
    const [qtnLeads, setQtnLeads] = useState (0)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect (() => {
        
        const listaLeads = async () => {

            const payload = {
                nome: filtrosLeads.nome,
                situacao: filtrosLeads.situacao,
            }

            try {

                const resposta = await listarLeads (payload)
                setQtnLeads (resposta.data.length)

            } catch (error) {

                return 

            }
        }

        listaLeads ()

    }, [filtrosLeads, atualizarDados])

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                <span>
                    {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                    {props.titulo}
                </span>
                <h3>{qtnLeads === 0 ? null : qtnLeads.toString().padStart (2, '0')}</h3>
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '/leads' texto = 'Leads' notificacao = {qtnLeads === 0 ? null : qtnLeads } />}
            </div>
        </div>
    )
}

export default MenuComercial