import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalNovoResponsavel } from '../../store/modules/modalNovoResponsavel'
import ModalNovoResponsavel from '../ModalNovoResponsavel'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './filtrosresponsaveis.css'

function FiltrosResponsaveis () {

    const modalEstado = useSelector ((store: RootStore) => store.modalNovoResponsavel)
    const [modal, setModal] = useState (true)
    const dispatch = useDispatch()

    function exibirModalNovoResponsavel () {
        dispatch (setExibirModalNovoResponsavel ({
            exibir: true,
        }))
    }

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (
        <div id = 'filtro'>
            {modal && <ModalNovoResponsavel />}
            <div id = 'lado_esquerdo'>
                <div id = 'caixa_pesquisa_responsavel'>
                    <input type = 'text' id = 'input_pesquisa_responsavel' placeholder="busque pelo nome do responsável"/>
                    <img id = 'icone_pesquisa_responsavel' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>
                <select className = "situacao">
                    <option id = 'situacao' value="Ativo">ATIVO</option>
                    <option id = 'situacao'>INATIVO</option>
                </select>
            </div>
            <Btn id = 'novo_responsável' titulo='NOVO RESPONSÁVEL' cor='novo' acao={exibirModalNovoResponsavel}></Btn>
        </div>
    )
}

export default FiltrosResponsaveis