import { createSlice } from "@reduxjs/toolkit"
import { tarefaRequisicaoPaylod } from "../../../interfaces/tasks"

let data = new Date()

let ano = data.getFullYear()
let mesAtual = data.getMonth() + 1
let mesAnterior = data.getMonth()
let qtnDias = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()

let mesAtualFormatado = (`0${mesAtual}`).slice (-2)
let mesAnteriorFormatado = (`0${mesAnterior}`).slice (-2)

let dataInicial = `${ano}-${mesAnteriorFormatado}-01`
let dataFinal = `${ano}-${mesAtualFormatado}-${qtnDias}`

const filtroTarefasReduce = createSlice ({
    name: 'filtros_tarefas',
    initialState: {
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        situacao: '',
        responsavel: ''
    } as tarefaRequisicaoPaylod,
    reducers: {
        setFiltroTarefas (state, action) {
            Object.assign (state, {
                dataInicial: action.payload.dataInicial,
                dataFinal: action.payload.dataFinal,
                situacao: action.payload.situacao,
                responsavel: action.payload.responsavel
            })
        }
}})

export const { setFiltroTarefas } = filtroTarefasReduce.actions

export default filtroTarefasReduce.reducer