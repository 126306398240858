import { useDispatch } from 'react-redux'
import { useState, FormEvent, useEffect } from 'react'
import { setOcultarModalNovaAssinatura } from '../../store/modules/modalNovaAssinatura'
import { clientesPayload } from '../../interfaces/client'
import { novaAssinatura } from '../../services/hg/assinaturas'
import { listaDeClientes, clienteId, clienteTermoPesquisa } from '../../services/hg/clients'
import listaDeServicos from '../../services/hg/servicosLista'
import listaDeContasBancarias from '../../services/hg/contasBancarias'
import servicosListaPayload from '../../interfaces/servicosListaPayload'
import contasPayload from '../../interfaces/contasBancarias'
import itensAdicionadosPayload from '../../interfaces/itensAdicionadosPayload'
import itensVendidosPayload from '../../interfaces/itensVendidosPayload'
import contaId from '../../services/hg/contaId'
import servicoId from '../../services/hg/servicoId'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import adicionarImg from '../../assets/img/adicionar.png'
import excluiImg from '../../assets/img/excluir.png'
import Btn from '../Btn'
import './novaassinatura.css'

function ModalNovaAssinatura () {

    const dispatch = useDispatch ()

    const [clientes, setClientes] = useState<clientesPayload[]> ([])
    const [cliente, setCliente] = useState (0)
    const [clienteTermo, setClientetermo] = useState ('')
    const [descricao, setDescricao] = useState('')
    const [conta, setConta] = useState (0)
    const [contas, setContas] = useState<contasPayload[]> ([])
    const [contaTermo, setContaTermo] = useState ('')
    const [valor, setValor] = useState (0)
    const [inicioCobranca, setInicioCobranca] = useState ('')
    const [servicoLista, setServicoLista] = useState<servicosListaPayload []>([])
    const [servicoTermo, setServicoTermo] = useState ('')
    const [itensAdicionados, setItensAdiconados] = useState <itensAdicionadosPayload []> ([])
    const [servicos, setServicos] = useState <itensVendidosPayload []> ([])
    const [valorTotal, setValorTotal] = useState (0)
    const [observacoes, setObservacoes] = useState<null | string> (null)
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    const valorTotalConvertivo = valorTotal.toString().replace(',', '.')
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovaAssinatura ({
            exibir: false
        }))
    }

    const adicionarItem = async () => {

        const itemServicoLocal = servicoTermo
        const itemValor = valor
        const termo = servicoTermo

        try {

            const resposta = await servicoId(termo)

            const itemServico = resposta.data[0].id

            const novoItem = {servico: itemServicoLocal, valor: itemValor}
            const novoItemId = {servico_id: itemServico, valor: itemValor}

            setItensAdiconados (itens => [...itens, novoItem])
            setServicos (itens => [...itens, novoItemId])
            
        } catch (error) {
            return alert ('Falha ao recuperar o id do Serviço')    
        }
    }

    function removerItens (posicao: number) {

        setItensAdiconados(itens => [...itens])

        servicos.splice(posicao, 1)
        return itensAdicionados.splice (posicao, 1)
    }


    const cadastrarAssinatura = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        if (servicos.length === 0) {
            setCarregando (false)
            ocultarModal ()
            return alert ('Nenhum Serviço foi Adicionado a Assinatura. Tente Novamente!')
        }

        const payload = {
            cliente_id: cliente,
            descricao,
            servicos,
            conta_bancaria_id: conta,
            inicio_cobranca: inicioCobranca,
            valor_total: valorTotalConvertivo,
            observacoes
        }

        try {
            const resposta = await novaAssinatura(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()
                }, 3000)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 3000)
        }
    }

    useEffect (() => {

        const listaServicos = async () => {
        
            try {
                const resposta = await listaDeServicos()
                setServicoLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de Serviços')
            }
        }

        listaServicos ()

    }, [])

    useEffect (() => {

        const listadeContas = async () => {
        
            try {
                const resposta = await listaDeContasBancarias()
                setContas (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar as Contas Bancárias')
            }
        }

        listadeContas ()

    }, [])

    
    useEffect (() => {
        
        const clientesLista = async () => {

            try {
                const resposta = await listaDeClientes ()
                setClientes(resposta.data)
            } catch (error) {
                alert ('Erro ao listar os Clientes')
            }
        }
        
        clientesLista ()
        
    }, [setClientes])

    useEffect (() => {
        
        const clienteIdDb = async () => {

            const termo = clienteTermo

            try {

                const resposta = await clienteTermoPesquisa(termo)
                return setCliente(resposta.data[0].id)
            
            } catch (error) {
                return alert ('Falha ao recuperar o id do Cliente')    
            }
        }

        clienteIdDb ()

    }, [clienteTermo])
    
    useEffect (() => {
        
        const contaIdDb = async () => {

            const termo = contaTermo

            try {

                const resposta = await contaId(termo)
                return setConta(resposta.data[0].id)
            
            } catch (error) {
                return alert ('Falha ao recuperar o id da Conta Bancária')    
            }
        }

        contaIdDb ()

    }, [contaTermo])

    useEffect (() => {
        let soma = 0

        for(let i = 0; i < itensAdicionados.length; i++) {
            soma += itensAdicionados[i].valor;
        }

        setValorTotal (soma)

    }, [itensAdicionados])


    return (
        <div id = 'fundo_modal_nova_assinatura'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionar_assinatura">
                <div id="assinatura_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Nova Assinatura</p>
                </div>

                <div id="assinatura_dados">
                    <form id = 'form_nova_assinatura' onSubmit={cadastrarAssinatura}>        
                        <div id="assinatura_dados">
                            <div id = 'campo_nova_assinatura'>
                                <p>Cliente</p>
                                <select id = 'lista_clientes_assinatura' value={clienteTermo} required onChange={(e) => setClientetermo(e.target.value)}>
                                    <option />
                                    {clientes.map((cliente) => (
                                        <option  key={cliente.id}>{cliente.nome_fantasia.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div> 
                        </div>

                        <div id="assinatura_dados">
                            <div id = 'campo_nova_assinatura'>
                                <p>Descrição</p>
                                <input 
                                id = 'descrica_nova_assinatura'
                                type = 'text'
                                required
                                onChange={(e) => setDescricao (e.target.value.toLowerCase())}/>
                            </div>  
                        </div>

                        <div id="assinatura_dados">
                            <div id = 'campo_nova_assinatura'>
                                <p>Início da Cobrança</p>
                                <input 
                                    id = 'vencimento_nova_assinatura' 
                                    type="date" 
                                    required
                                    onChange={(e) => setInicioCobranca (e.target.value)}/>
                            </div>
                            <div id = 'campo_nova_assinatura'>
                                <p id = 'p_conta_nova_assinatura'>Conta</p>
                                <select id = 'conta_nova_assinatura' value={contaTermo} required onChange={(e) => setContaTermo(e.target.value)}>
                                    <option />
                                    {contas.map ((conta) => (
                                        <option key={Math.random()}>{conta.descricao.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div>  
                        </div>

                        <div id="assinatura_dados_item">
                            <div id = 'campo_nova_assinatura_item'>
                                <p>Serviço</p>
                                <select id = 'servico_nova_assinatura' value={servicoTermo} onChange={(e) => setServicoTermo(e.target.value)} required>
                                    <option />
                                    {servicoLista.map ((servico) => (
                                        <option key={Math.random()}>{servico.nome.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div>
                            <div id = 'campo_nova_assinatura_item'>
                                <p id = 'p_valor_nova_assinatura'>Valor</p>
                                <input 
                                id = 'valor_servico_assinatura'
                                type = 'text'
                                required
                                onChange={(e) => setValor (parseFloat(e.target.value))}/>
                            </div>
                            <div>
                                <img id = 'adicionar_item_assinatura' src= {adicionarImg} alt = 'adiconar item venda' onClick={adicionarItem}/>
                            </div>

                        </div>

                        <div id = 'itens_adicionados_assinaturas'>
                            {itensAdicionados.map ((item, index) => (
                                <div key={Math.random()} id = 'itens_acionados_assinatura'>
                                    <p id = 'item_servico_assinatura'>{item.servico}</p>
                                    <p id = 'item_servico_valor'>R$ {item.valor}</p>
                                    <img onClick={() => removerItens(index)} id = 'excluir_item_assinatura' src = {excluiImg} alt = 'icone para excluir um icone adicionado'/>
                                </div>
                            ))}
                        </div>

                        <div id = 'total_assinatura'>
                            <p>VALOR TOTAL</p>
                            <h1>R$ {valorTotal}</h1>
                        </div>

                        <div id="assinatura_dados">
                            <div id ='campo_assinatura'>
                                <p>OBSERVAÇÕES</p>
                                <textarea 
                                    name="descricao" 
                                    id="observacoes_nova_assinatura" rows={1}  
                                    onChange={(e) => setObservacoes (e.target.value)}/>
                            </div>
                        </div>

                        <div id="assinatura_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_assinatura' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_assinatura' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovaAssinatura