import baseApiHg from "../api/configFormData"
import { bugsCadastrarPayload } from "../../../interfaces/bugs"
import { bugsCadastrarComentariosPayload } from "../../../interfaces/bugs"
import { bugsListaRequisicaoPayload } from "../../../interfaces/bugs"


export function bugsCadastrar (payload: bugsCadastrarPayload ) {
    return baseApiHg.post('/bug/criar', payload)
}

export function listaDeBugs (payload: bugsListaRequisicaoPayload) {
    return baseApiHg.get(`/bug/lista?status=${payload.status}&termo=${payload.termo}`)
}

export function bugsCadastrarComentarios (id: number, payload: bugsCadastrarComentariosPayload) {
    return baseApiHg.post(`/bug/comentario/criar/${id}`, payload)
}

export function dadosBugsDetalhes (idBug: number ) {
    return baseApiHg.get(`/bug/lista/${idBug}`)
}

export function bugsCarregarComentarios(id: number ) {
    return baseApiHg.get(`/bug/lista/${id}`)
}