import { v4 as uuid } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useState, FormEvent, useEffect } from 'react'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setOcultarModalNovoModeloProcesso } from '../../store/modules/modalNovoModeloProcesso'
import { tarefasModelosPayload } from '../../interfaces/modelosProcessos'
import { novoModeloProcesso } from '../../services/hg/modelosProcessos'
import { usuariosAtivos } from '../../services/hg/usuarios'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import listaDeServicos from '../../services/hg/servicosLista'
import servicosListaPayload from '../../interfaces/servicosListaPayload'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import excluiImg from '../../assets/img/excluir.png'
import Btn from '../Btn'
import './modalnovoModeloProcesso.css'

function ModalNovoModeloProcesso () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)

    const [tituloProceso, setTituloProcessp] = useState('')
    const [tarefasModelos, setTarefasModelos] = useState<tarefasModelosPayload []>([])
    const [responsaveis, setResponsaveis] = useState <UsuariosAtivos []>([])
    const [responsavel, setResponsavel] = useState (0)
    const [servicoLista, setServicoLista] = useState<servicosListaPayload []>([])
    const [servico, setServico] = useState (0)
    const [tituloTarefa, setTituloTarefa] = useState('')
    const [prioridade, setPrioridade] = useState ('')
    const [descricao, setDescricao] = useState('')
    const [tipo, setTipo] = useState('')
    const [automacao, setAutomacao] = useState(false)
    const [recorrencia, setRecorrencia] = useState<string | null>(null)
    const [recorrenciaDia, setRecorrenciaDia] = useState<number | null>(null)
    const [recorrenciaMes, setRecorrenciaMes] = useState <(number | null)> (null)
    const [prazo, setPrazo] = useState<number | null> (null)
    const [tarefaModelo, setTarefaModelo] = useState (false)
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)

    const vincularTarefaModelo = async (e: FormEvent) => {
        e.preventDefault()

        const tarefaModelo = {
            id: uuid(),
            titulo: tituloTarefa,
            servico_id: servico,
            prioridade,
            descricao,
            responsavel,
            tipo,
            automacao,
            recorrencia,
            recorrencia_dia: recorrenciaDia,
            recorrencia_mes: recorrenciaMes,
            prazo
        }

        ocultarModalTarefaModelo ()
        setTarefasModelos (itens => [...itens, tarefaModelo])

        return alert('Tarefa Vinculada com Sucesso')

    }

    function removerTarefaModelo (posicao: number) {

        setTarefasModelos(itens => [...itens])

        tarefasModelos.splice(posicao, 1)
        return tarefasModelos.splice (posicao, 1)
    }

    function exibirModalTarefaModelo () {
        return setTarefaModelo (true)
    }

    function ocultarModalTarefaModelo () {
        return setTarefaModelo(false)
    }
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovoModeloProcesso ({
            exibir: false
        }))
    }

    const cadastrarNovoModeloProcesso = async (e: FormEvent) => {
        e.preventDefault ()

        if (tarefasModelos.length === 0) {
            return alert ('Cada Processo Precisa Ter Pelo menos uma Tarefa Vinculada')
        }

        setCarregando (true)
        setSalvarDados (false)

        const id = uuid()

        const payload = {
            id,
            tituloProcesso: tituloProceso.toLowerCase(),
            tarefasProcesso: tarefasModelos
        }

        try {
            const resposta = await novoModeloProcesso(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))

                }, 3000)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 3000)
        }
    }

    useEffect (() => {

        const listaServicos = async () => {
        
            try {
                const resposta = await listaDeServicos()
                setServicoLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de Serviços')
            }
        }

        listaServicos ()

    }, [])

    useEffect (() => {

        const usuariosLista = async () => {

            try {
                const resposta = await usuariosAtivos ()
                return setResponsaveis (resposta.data)
                
            } catch (error) {
                return alert ('Erro ao Listar os Responsáveis')
            }
        }

        usuariosLista ()
    }, [setResponsaveis])


    return (
        <div id = 'fundoModalNovoModeloProcesso'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionarNovoProcesso">
                <div id="modeloProcessoSuperior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Novo Modelo</p>
                </div>

                <div id="assinatura_dados">
                    <form id = 'form_nova_assinatura' onSubmit={cadastrarNovoModeloProcesso}> 
                        <div id="assinatura_dados">
                            <div id ='campoTituloProcessosModelos'>
                                <span>    
                                    <p>TÍTULO</p>
                                    <input 
                                        id="inputTituloNovoModeloProcesso"
                                        maxLength={500}
                                        placeholder='digite o título do processo'
                                        required   
                                        onChange={(e) => setTituloProcessp (e.target.value)}
                                    />
                                </span>
                                <b id = 'vincularTarefaModelo' onClick={exibirModalTarefaModelo}>Vincular Tarefa</b>
                            </div>
                        </div>

                        <div id="assinatura_dados">
                            <div id ='campo_assinatura' className='tarefasVinculadasProcessosModelos' >
                                <b>TAREFAS VINCULADAS</b>
                                <section id = 'listaTarefasAdicionadasModelo'>
                                    {tarefasModelos.map ((item, index) => (
                                    <div key={Math.random()} id = 'tarefasModelosAdicionada'>
                                        <p id = 'item_servico_venda'>{item.titulo.toUpperCase()}</p>
                                        <p id = 'item_servico_valor'>{item.prioridade.toUpperCase()}</p>
                                        <img onClick={() => removerTarefaModelo(index)} id = 'excluir_item_venda' src = {excluiImg} alt = 'icone para excluir um icone adicionado'/>
                                    </div>
                                    ))}
                                </section>
                            </div>
                        </div>

                        <div id="assinatura_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_assinatura' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_assinatura' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
            {tarefaModelo && <div id = 'fundoModalNovaTarefaModelo'>
                <div id="adicionar_tarefa">
                    <div id="tarefa_superior">
                        <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                        <p id = 'modal_titulo'>Nova Tarefa Modelo</p>
                    </div>

                    <div id="tarefa_dados">
                        <form id = 'form_nova_tarefa' onSubmit={vincularTarefaModelo}>        
                            <div id="tarefa_dados">
                                <div id = 'campoNovaTarefaClienteModelo'>
                                    <span id = 'campo_nova_tarefa'>
                                        <p>Título</p>
                                        <input 
                                            type = 'text'
                                            id = 'inputTarefaModeloTitulo'
                                            required
                                            onChange={(e) => setTituloTarefa(e.target.value.toLowerCase())}/>
                                    </span>

                                    <span>
                                        <p>Serviço</p>
                                        <select id = 'lista_servicos_tarefa' value={servico} required onChange={(e) => setServico(parseInt(e.target.value))}>
                                            <option />
                                            {servicoLista.map ((servico) => (
                                                <option key={Math.random()} value = {servico.id}>{servico.nome.toUpperCase()}</option>
                                            ))}
                                        </select>
                                    </span>

                                    <span>
                                        <p>Prioridade</p>
                                        <select id = 'lista_prioridades_tarefa' value={prioridade} required onChange={(e) => setPrioridade(e.target.value)}>
                                            <option />
                                            <option value = 'urgente'>Urgente</option>
                                            <option value = 'alta'>Alta</option>
                                            <option value = 'baixa'>Baixa</option>
                                        </select>
                                    </span>
                                </div> 
                            </div>

                            <div id="tarefa_dados">
                                <div id = 'campo_nova_tarefa'>
                                    <p>Descrição</p>
                                    <textarea 
                                        name="descricaoTarefaModelo" 
                                        required
                                        id="descricaoTarefaModelo" rows={5}  
                                        onChange={(e) => setDescricao (e.target.value.toLowerCase())}/>
                                </div>  
                            </div>

                            <div id="tarefa_dados">
                                <div id = 'campoNovaTarefaModeloResponsavelRecorrencia'>
                                    <span>
                                        <p>Responsável</p>
                                        <select id = 'listaResponsaveisTarefaModelo' value={responsavel} required onChange={(e) => setResponsavel(parseInt(e.target.value))}>
                                            <option />
                                            {responsaveis.map((responsavel) => (
                                            <option  key={responsavel.id} value = {responsavel.id}>{responsavel.nome.toUpperCase()}</option>
                                            ))}
                                        </select>
                                    </span>

                                    <span>
                                    <p>Tipo</p>
                                        <select id = 'lista_prioridades_tarefa' value={tipo} required onChange={(e) => setTipo(e.target.value)}>
                                            <option />
                                            <option value = 'atendimento'>Atendimento</option>
                                            <option value = 'tarefa'>Tarefa</option>
                                            <option value = 'flowup'>Flowup</option>
                                            <option value = 'chamado'>Chamado</option>
                                        </select>
                                    </span>

                                    <span>
                                        <p>Prazo</p>
                                        <input 
                                            type='number'
                                            max={30} 
                                            id = 'prazoModeloTarefa'
                                            required
                                            placeholder = 'prazo, em dias'
                                            onChange = {(e) => setPrazo (parseInt(e.target.value))}/>
                                    </span>
                                </div>
                            </div>

                            <div id="tarefa_dados">
                                <span id = 'opcaoRecorrenciaTarefaModelo'>
                                    <div id = 'checkboxtarefaRecorrenteModelo'>
                                        <input 
                                            type='checkbox' 
                                            id = 'tarefaRecorrenteModelo'
                                            onChange={(e) => setAutomacao (e.target.checked)}/>
                                        <label id = 'checkboxtarefaRecorrenteModelo' htmlFor = 'tarefaRecorrenteModelo'>Recorrência</label>
                                    </div>

                                    {automacao && <div id = 'div_opcoes_recorrencia'>
                                        <select id = 'opcoes_recorrencia_tarefa' required onChange={(e) => setRecorrencia(e.target.value.toLowerCase())}>
                                            <option />
                                            <option value = 'diaria'>DIÁRIA</option>
                                            <option value = 'semanal'>SEMANAL</option>
                                            <option value = 'mensal'>MENSAL</option>
                                            <option value = 'trimestral'>TRIMESTRAL</option>
                                            <option value = 'semestral'>SEMESTRAL</option>
                                            <option value = 'anual'>ANUAL</option>
                                        </select>
                                    </div>}
                                </span>
                            </div>

                            <div id="tarefa_botao">
                                <div>
                                    <Btn acao ={ocultarModalTarefaModelo} id = 'cancelar_operacao_tarefa' titulo='CANCELAR' cor='cancelar' />
                                </div>
                                <div>
                                    <Btn id = 'salvar_dados_tarefa' titulo='VINCULAR TAREFA' cor='salvar' />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovoModeloProcesso