import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { RootStore } from "../../store"
import DivDados from "../../components/DivDados"
import FiltrosLeads from "../../components/filtrosLeads"
import ListaLeads from "../../components/ListaLeads"
import ModalNovoLead from "../../components/ModalNovoLead"
import ModalVisualizarDadosLeads from "../../components/VisualizarDadosLeads"

function Leads () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovoLead)
    const modalDadosLeads = useSelector ((store: RootStore) => store.modalVisualizarDadosLeads)
    
    const [modal, setModal] = useState (false)
    const [modalLead, setModalLead] = useState (false)
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        setModalLead (modalDadosLeads.exibir)
    }, [modalDadosLeads, modalLead])

    useEffect (() => {
        document.title = 'Netclick - Leads'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <>
                {modal && <ModalNovoLead />}
                {modalLead && <ModalVisualizarDadosLeads />}
                <FiltrosLeads />
                <ListaLeads />
            </>
        </DivDados>
    )
  }
  
  export default Leads