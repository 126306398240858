import { SpanPontinhosDefault } from '../Styles'
import spamProps from '../../interfaces/propsSpan'
import './pontinhos.css'

function CarregandoPontinhos (props: spamProps) {
    return (
        <div id = 'carregando_pontinhos'>
            < SpanPontinhosDefault cor = {props.cor} />
        </div>
    )
}

export default CarregandoPontinhos