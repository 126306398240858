import { useEffect } from 'react'
import Perfil from "../../middleware/perfil";
import DivDados from "../../components/DivDados";
import ClientesDadosSuperior from "../../components/ClientesDadosSuperior";
import ClientesDadosInferior from "../../components/ClientesDadosInferior";

function ClientesDados() {

    useEffect (() => {
        document.title = 'Netclick - Dados do Cliente'
    }, [])

    return (
        <DivDados id = 'dados_clientes_fundo'>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    <ClientesDadosSuperior />
                    <ClientesDadosInferior />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default ClientesDados;