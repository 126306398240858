import { useState, FormEvent } from 'react'
import { RootStore } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltrosReceitas } from '../../store/modules/filtrosReceitas'
import { setExibirModalNovaVenda } from '../../store/modules/modalNovaVenda'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './receitas.css'

function FiltrosReceitas () {

    const filtros = useSelector ((store: RootStore) => store.filtrosReceitas)

    const [dataInicial, setDataInicial] = useState (`${filtros.dataInicial}`)
    const [dataFinal, setDataFinal] = useState (`${filtros.dataFinal}`)
    const [situacao, setSituacao] = useState ('')
    const [cliente, setCliente] = useState('')
    const dispatch = useDispatch()

    function exibirModal () {
        dispatch (setExibirModalNovaVenda ({
            exibir: true
        }))
    }

    function filtrosReceitas (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosReceitas ({
            dataInicial,
            dataFinal,
            situacao,
            cliente
        }))
    }

    return (
        <div id = 'filtro'>
            <form id = 'lado_esquerdo_receita' onSubmit={filtrosReceitas}>
                <div id = 'input_data'>
                    <label>DATA INICIAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date' 
                        required
                        value={dataInicial}
                        onChange={(e) => setDataInicial (e.target.value)}/>
                </div>

                <div id = 'input_data'>
                    <label>DATA FINAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date'
                        required
                        value={dataFinal} 
                        onChange= {(e) => setDataFinal (e.target.value)}/>
                </div>
                <div id = 'caixa_pesquisa'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_receita' 
                        placeholder="busque pelo nome fantasia do cliente"
                        onChange={(e) => setCliente(e.target.value)}/>
                    <img id = 'icone_pesquisa_receita' src= {pesquisarImg} alt = 'caixa de pesquisa das receitas'></img>
                </div>

                <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value.replace(/ /g, ""))}>
                    <option id = 'situacao' value = ''>TODAS</option>
                    <option id = 'situacao' value = 'aberta'>ABERTAS</option>
                    <option id = 'situacao' value = 'paga'>PAGAS</option>
                    <option id = 'situacao' value = 'vencida'>VENCIDAS</option>
                </select>
                <Btn id = 'pesquisar_receita' titulo='PESQUISAR' cor='novo' />
            </form>
            <div id = 'lado_direiro_assinatura'>
                <Btn acao={exibirModal} id = 'nova_receita' titulo='NOVA RECEITA' cor='novo' />
            </div>
        </div>
    )
}

export default FiltrosReceitas