import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { setExibirModalNovaSugestao } from '../../store/modules/modalNovaSugestao'
import { setFiltrosSugestao } from '../../store/modules/filtrosSugestao'
import Btn from '../Btn'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import './filtrossugestao.css'

function FiltrosSugesao () {

    const dispatch = useDispatch ()
    const [situacao, setSituacao] = useState ('')
    const [termo, setTermo] = useState('')

    function exibirModalSugestao () {
        dispatch (setExibirModalNovaSugestao ({
            exibir: true
        }))
    }

    useEffect (() => {

        function filtrosSugestao () {
            dispatch (setFiltrosSugestao ({
                termo,
                status: situacao
            }))
        }
    
        filtrosSugestao()

    }, [situacao, termo, dispatch])

    return (
        <div id = 'filtro'>
            <div id = 'lado_esquerdo'>
                <div id = 'caixa_pesquisa'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_sugestao' 
                        placeholder="Busque pelo titulo da sugestão"
                        onChange={(e) => setTermo (e.target.value)}/>
                    <img id = 'icone_pesquisa' src= {pesquisarImg} alt = 'caixa de pesquisa das sugestões'></img>
                </div>
                <select className = "situacao" onChange={(e) => setSituacao (e.target.value)}>
                    <option id = 'situacao' value="">TODOS</option>
                    <option id = 'situacao'>ABERTA</option>
                    <option id = 'situacao'>ACEITA</option>
                    <option id = 'situacao'>RECUSADA</option>
                    <option id = 'situacao'>CONCLUÍDA</option>
                </select>
            </div>
            <Btn acao={exibirModalSugestao} id = 'nova_sugestao' titulo='NOVA SUGESTÃO' cor='novo'></Btn>
        </div>
    )
}

export default FiltrosSugesao