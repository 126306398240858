import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovaTarefaReduce = createSlice ({
    name: 'modal_nova_tarefa',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovaTarefa (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovaTarefa (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovaTarefa, setOcultarModalNovaTarefa } = modalNovaTarefaReduce.actions

export default modalNovaTarefaReduce.reducer