export const autorizacao = () => {
    
    const cookie = document.cookie.indexOf('authHg')

    if (cookie < 0 ) {
        return  window.location.href = '/'
    } else {
        const authHg =  document.cookie.split('; ').find(tokenHg => tokenHg.startsWith ('authHg')).split('=')[1]
        return authHg
    }
}

export const logado = () => {

    const cookie = document.cookie.indexOf('authHg')

    if (cookie < 0) {
        return false
    }
    
    return true
}