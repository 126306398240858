import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import FiltrosSugesao from "../../components/FiltrosSugestao";
import ListaDeSugestaoMelhoria from "../../components/ListaDeSugestaoMelhoria";
import ModalNovaSugestaoDeMelhoria from "../../components/ModalNovaSugestaoMelhoria";

function Sugestao() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaSugestao)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Sugestão de Melhoria'
    }, [])

    return (
        <DivDados id = 'dados_sugestao_fundo'>
            <>
                {modal && <ModalNovaSugestaoDeMelhoria />}
                <FiltrosSugesao />
                <ListaDeSugestaoMelhoria />
            </>
        </DivDados>
    )
  }
  
  export default Sugestao;