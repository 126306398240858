import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovoResponsavelReduce = createSlice ({
    name: 'modal_novo_responsavel',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovoResponsavel (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovoResponsavel (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovoResponsavel, setOcultarModalNovoResponsavel } = modalNovoResponsavelReduce.actions

export default modalNovoResponsavelReduce.reducer