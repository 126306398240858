import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { setOcultarModalDadosTarefa } from '../../store/modules/modalVisualizarDadosTarefas'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { tarefaDetalhesResposta } from '../../interfaces/tasks'
import { usuariosAtivos, usuarioId } from '../../services/hg/usuarios'
import { tarefaDetalhes, atualizarTarefa } from '../../services/hg/tasks'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { NovaAvaliacao } from '../../services/hg/email'
import CarregandoEsqueletoCaixa from '../CarregandoEsqueletoCaixa'
import CarregandoPontinhos from '../CarregandoPontinhos'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import Btn from '../Btn'
import './visualizardadostarefa.css'
import Perfil from '../../middleware/perfil'

function VisualizarDadosTarefas () {

    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosTarefas)
    const dispatch = useDispatch ()

    const [usuarios, setUsuarios] = useState <UsuariosAtivos []>([])
    const [usuarioIdDb, setusuarioIdDb] = useState (0)
    const [titulo, setTitulo] = useState ('')
    const [dataInicio, setDataInicio] = useState ('')
    const [dataTermino, setDataTermino] = useState ('')
    const [responsavel, setResponsavel] = useState ('')
    const [situacao, setSituacao] = useState ('')
    const [tipo, setTipo] = useState ('')
    const [prioridade, setPrioridade] = useState ('')
    const [descricao, setDescricao] = useState ('')
    const [resultado, setResultado] = useState ('')
    const [carregando, setCarregando] = useState (true)
    const [pontinhos, setPontinhos] = useState (true)
    const [automacao, setAutomacao] = useState (false)
    const [recorrencia, setRecorrencia] = useState<string | null>(null)
    const [recorrenciaDia, setRecorrenciaDia] = useState<number | null>(null)
    const [recorrenciaMes, setRecorrenciaMes] = useState <(number | null)> (null)
    const [checkBox, setCheckbox] = useState (false)
    const [descricaoAba, setDescricaoAba] = useState (true)
    const [prazo, setPrazo] = useState<number | null> (null)
    const [dados, setDados] = useState (false)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmação] = useState (false)
    const [modal, setModal] = useState (false)

    function ocultarModal () {

        dispatch (setOcultarModalDadosTarefa ({
            exibir: false
        }))
    }

    function abaAutomacao () {

        setAutomacao (true)
        setDescricaoAba (false)
    }

    function abaDescricao () {

        setAutomacao (false)
        setDescricaoAba (true)
    }

    async function updateTarefa (idTarefa: number) {

        setProcessando (true)

        if (situacao === 'concluída' && resultado === '') {
            setProcessando (false)
            return alert ('A tarefa não pode ser concluída sem o resultado')
        }

        const payload = {
            data_inicio: dataInicio,
            data_termino: dataTermino,
            responsavel: usuarioIdDb,
            titulo,
            descricao,
            situacao,
            prazo,
            recorrencia,
            recorrencia_dia: recorrenciaDia,
            recorrencia_mes: recorrenciaMes,
            tipo,
            prioridade,
            resultado
        }

        try {

            const resposta = await atualizarTarefa (idTarefa, payload)
            
            if ( resposta.status === 200) {
                setProcessando (false)
                setConfirmação (true)
                
                setTimeout (() => {
                    setConfirmação (false)
                    ocultarModal()
                    
                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2000)
            }
            
            if (situacao === 'concluída' && tipo === 'atendimento') {
                const resposta = await NovaAvaliacao (idTarefa)
            }

        } catch (error) {
            setProcessando (false)
            return alert ('Falha ao Salvar os Dados')
        }
    }

    useEffect (() => {

        const usuariosLista = async () => {

            try {
                const resposta = await usuariosAtivos ()
                return setUsuarios (resposta.data)
                
            } catch (error) {
                return alert ('Erro ao Listar os Usuários')
            }
        }

        usuariosLista ()
        
    }, [setUsuarios])

    useEffect (() => {
        
        const usuarioIdDb = async () => {

            const termo = responsavel

            try {

                const resposta = await usuarioId (termo)
                return setusuarioIdDb (resposta.data[0].id)
                
            } catch (error) {
                return alert ('Falha ao Recuperar o Id do Responsável')
            }
        }

        usuarioIdDb ()

    }, [responsavel])

    useEffect (() => {

        const idTarefa = modalEstado.id
    
        if (idTarefa === undefined || idTarefa === null) {
            return alert
        }

        const detalhesTarefas = async () => {
            
            try {
                const resposta = await tarefaDetalhes (idTarefa)
                setTitulo (() => resposta.data.titulo != null ? resposta.data.titulo : '')
                setDataInicio (() => resposta.data.data_inicio != null ? resposta.data.data_inicio : '')
                setDataTermino (() => resposta.data.data_termino != null ? resposta.data.data_termino : '')
                setResponsavel (() => resposta.data.usuario.nome != null ? resposta.data.usuario.nome : '')
                setSituacao (() => resposta.data.situacao != null ? resposta.data.situacao : '')
                setPrioridade (() => resposta.data.prioridade != null ? resposta.data.prioridade : '')
                setDescricao (() => resposta.data.descricao != null ? resposta.data.descricao : '')
                setTipo (() => resposta.data.tipo != null ? resposta.data.tipo : '')
                setResultado (() => resposta.data.resultado != null ? resposta.data.resultado : '')
                setCheckbox (() => resposta.data.automacao === true ? true : false)
                setRecorrencia (() => resposta.data.recorrencia === null ? null : resposta.data.recorrencia)
                setPrazo (() => resposta.data.prazo === null ? null : resposta.data.prazo)
                setPontinhos (false)
                setCarregando (false)
                setDados (true)
                setModal (true)

            } catch (error) {
                return alert ('Falha ao Recuperar os Dados da Tarefa')
            }
        }

        detalhesTarefas ()

    }, [setModal, modalEstado])
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {

        if (recorrencia === 'semanal') {

            const dataDia = new Date (dataInicio)

            setRecorrenciaDia (dataDia.getDay() + 1)

        }

        if (recorrencia === 'mensal' || recorrencia === 'trimestral' || recorrencia === 'semestral' || recorrencia === 'anual') {

            const dataDia = new Date (dataInicio)

            setRecorrenciaDia (dataDia.getDate() + 1)
        }

        if (recorrencia === 'anual' || recorrencia === 'trimestral' || recorrencia === 'semestral') {
            
            const dataMes = new Date (dataInicio)

            setRecorrenciaMes (dataMes.getMonth() + 1)
        }

    }, [automacao, recorrencia, dataInicio])

    return (

        <div id = 'fundo_modal_detalhes_tarefa'>
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {processando && <CarregandoCirculo />}
            {modal && <div id="visualizar_tarefa_fundo">
                <div id="tarefa_superior_dados">
                    <div id = 'visualizar_dados_superior_titulo'>
                        <div>
                            <p id = 'visualisar_dados_titulo'>TÍTULO</p>
                            {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                            {dados && <input
                             type='text'
                             id = 'visualizar_dados_titulo_tarefa'
                             value={titulo.toUpperCase()}
                             onChange={(e) => setTitulo (e.target.value.toLowerCase())}/>}
                        </div>
                    </div>
                </div>

                <div id="visualizar_tarefa_descricao">
                    <div id = 'prazo_responsavel_tarefa'>
                        <span>
                            <label>Data de Início</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <input
                            type= 'date' 
                            value={dataInicio}
                            onChange = {(e) => setDataInicio (e.target.value) }/>}
                        </span>
                        <span>
                            <label>Data de Término</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <input 
                            type = 'date'
                            value={dataTermino}
                            onChange = {(e) => setDataTermino (e.target.value)}/>}
                        </span>
                        <span>
                            <label>Responsável</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <select value={responsavel} onChange = {(e) => setResponsavel (e.target.value)}>
                                {usuarios.map ((usuario) => (
                                    <option key={usuario.id}>{usuario.nome}</option>
                                ))}
                            </select>}
                        </span>
                        <span>
                            <label>Prioridade</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <select value={prioridade} onChange = {(e) => setPrioridade(e.target.value)}>
                                <option value = 'urgente'>URGENTE</option>
                                <option value = 'alta'>ALTA</option>
                                <option value = 'baixa' >BAIXA</option>
                            </select>}
                        </span>
                        <span>
                            <label>Tipo</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <select value={tipo} onChange = {(e) => setTipo(e.target.value)}>
                                <option value = 'atendimento'>ATENDIMENTO</option>
                                <option value = 'tarefa'>TAREFA</option>
                                <option value = 'flowup'>FLOWUP</option>
                                <option value = 'chamado' >CHAMADO</option>
                            </select>}
                        </span>
                        <span>
                            <label>Situação</label>
                            {carregando && <CarregandoEsqueletoCaixa />}
                            {dados && <select value={situacao} onChange = {(e) => setSituacao (e.target.value)}>
                                <option value = 'atrasada'>ATRASADA</option>
                                <option value = 'pendente'>PENDENTE</option>
                                <option value = 'concluída' >CONCLUÍDA</option>
                            </select>}
                        </span>
                    </div>
                    <div id = 'visualizar_tarefas_detalhes'>
                        <div id="tarefa_detalhes">
                            <span>
                                <p id = 'descricao_titulo' onClick={() => abaDescricao()}><strong>Descrição</strong></p>
                                <Perfil perfil={['master', 'administrativo']}>
                                    <p id = 'descricao_titulo' onClick={() => abaAutomacao()}><strong>Automação</strong></p>
                                </Perfil>
                            </span>
                            {descricaoAba && <textarea name="descricao_tarefas" id="descricao_tarefa_dados" rows={10} onChange={(e) => setDescricao (e.target.value)} value={descricao}></textarea>}
                        </div>
                    </div>

                    {descricaoAba && <div id = 'visualizar_tarefas_detalhes'>
                        <p id = 'descricao_titulo'><strong>Resultado</strong></p>
                        <textarea 
                            id = 'descricao_tarefa_dados' 
                            rows={5} 
                            required
                            value={resultado === null ? '' : resultado}
                            onChange = {(e) => setResultado (e.target.value)} 
                            placeholder='descreva o resultado da solicitação'
                            minLength={100}/>
                    </div>}
                </div>
                
                {automacao && <div id = 'div_opcoes_recorrencia_tarefa_dados'>
                    <div id = 'checkbox_recorrencia'>
                        <input 
                            type='checkbox' 
                            id = 'tarefa_recorrente'
                            checked = {checkBox}
                            onChange={(e) => setCheckbox (e.target.checked)}/>
                        <label id = 'checkbox_recorrencia' htmlFor = 'opcoes_recorrencia_tarefa'>Recorrência</label>
                    </div>
                    {checkBox && <select id = 'opcoes_recorrencia_tarefa' value={recorrencia === null ? '' : recorrencia } required onChange={(e) => setRecorrencia(e.target.value.toLowerCase())}>
                        <option />
                        <option value = 'diaria'>DIÁRIA</option>
                        <option value = 'semanal'>SEMANAL</option>
                        <option value = 'mensal'>MENSAL</option>
                        <option value = 'trimestral'>TRIMESTRAL</option>
                        <option value = 'semestral'>SEMESTRAL</option>
                        <option value = 'anual'>ANUAL</option>
                    </select>}
                    {checkBox && <input 
                        type='number'
                        max={30} 
                        id = 'tarefa_prazo'
                        value={prazo === null ? '' : prazo}
                        required
                        placeholder = 'prazo em dias'
                        onChange = {(e) => setPrazo (parseInt(e.target.value))}/>}
                </div>}

                <div id="tarefa_botao">
                    <div>
                        <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='CANCELAR' cor='cancelar' />
                    </div>
                    <div>
                        <Btn id = 'salvar_dados_tarefa' acao={() => updateTarefa(modalEstado.id)} titulo='SALVAR DADOS' cor='salvar' />
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default VisualizarDadosTarefas