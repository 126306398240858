import { useDispatch } from 'react-redux'
import { useState, FormEvent } from 'react'
import { setOcultarModalRecuperarSenha } from '../../store/modules/modalRecuperarSenha'
import { recuperarSenha, novaSenha } from '../../services/hg/auth'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import Btn from '../Btn'
import './recuperarsenha.css'

function ModalRecuperarSenha () {

    const dispatch = useDispatch ()

    const [email, setEmail] = useState ('')
    const [token, setToken] = useState ('')
    const [senha, setSenha] = useState ('')
    const [confirmarSenha, setConfirmarSenha] = useState ('')
    const [carregando, setCarregando] = useState (false)
    const [formulario, setFormulario] = useState (true)
    const [formToken, setFormToken] = useState (false)
    const [formSenha, setFormSenha] = useState (false)
    const [usuarioNaoEncontrato, setUsuarioNaoEntrato] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalRecuperarSenha ({
            exibir: false
        }))
    }

    const proximoPasso = async (e: FormEvent) => {
        e.preventDefault ()

        setFormulario (false)
        setCarregando (true)

        try {

            const payload = {
                email
            }

            const resposta = await recuperarSenha (payload)

            if (resposta.status === 200) {
                setCarregando (false)
                return setFormToken (true)
            }
            
        } catch (error: any) {

            if (error.response.status === 400) {
                setCarregando (false)
                setUsuarioNaoEntrato (true)

                return setTimeout (() => {
                    setUsuarioNaoEntrato (false)
                    dispatch (setOcultarModalRecuperarSenha ({
                        exibir: false
                    }))
                }, 2500)
            }
           
            setCarregando(false)
            return alert (error)
            
        }
    }

    const digitarSenha = async (e: FormEvent) => {
        e.preventDefault ()

        setFormToken (false)
        return setFormSenha (true)
    }

    const cadastrarNovaSenha = async (e: FormEvent) => {
        e.preventDefault()

        setFormSenha (false)
        setCarregando (true)

        if (senha !== confirmarSenha) {
            return alert ('A senha não confere. Digite Novamente')
        }

        try {

            const payload = {
                email,
                senha,
                token
            }

            const resposta = await novaSenha (payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                }, 2500)
            }
            
        } catch (error: any) {

            if (error.response.status === 400 || 401 || 402) {
                setCarregando (false)
                setErroOperacao (true)

                return setTimeout (() => {
                    setErroOperacao (false)
                    dispatch (setOcultarModalRecuperarSenha ({
                        exibir: false
                    }))
                }, 3500)
            }
           
            setCarregando(false)
            return alert (error)
            
        }
    }


    return (
        <>
            {confirmacao && <ConfirmacaoOperacao texto='Senha Atualizada com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Dados não Confere. Tente Novamente' />}
            {carregando && <CarregandoCirculo />}  
            {usuarioNaoEncontrato && <ErrorOperacao texto='Usuário não Encontrato' />}      
            {formulario && <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={(e) => proximoPasso (e)}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'email'
                            required
                            onChange={(e) => setEmail (e.target.value.toLowerCase())}/>
                            <span>Digite seu e-mail</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_recuperar_senha' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'proximo_recuperar_senha' titulo='PRÓXIMO' cor='salvar'/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {formToken && <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={(e) => digitarSenha (e)}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'number'
                            required
                            onChange={(e) => setToken (e.target.value)}/>
                            <span>Digite o token recebido por e-mail</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_recuperar_senha' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'proximo_recuperar_senha' titulo='PRÓXIMO' cor='salvar'/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}

            {formSenha && <div id = 'fundo_modal_recuperar_senha'>
                <div id="fundo_recuperar_senha">
                    <form id = 'form_nova_venda' onSubmit={(e) => cadastrarNovaSenha (e)}>
                        <div id="div_recuperar_senha">
                            <input 
                            type = 'password'
                            minLength= {8} 
                            maxLength= {8} 
                            required
                            onChange={(e) => setSenha (e.target.value)}/>
                            <span>Digite a nova senha</span>
                        </div>

                        <div id="div_recuperar_senha">
                            <input 
                            type = 'password'
                            minLength= {8} 
                            maxLength= {8}
                            required
                            onChange={(e) => setConfirmarSenha (e.target.value)}/>
                            <span>Confirmar senha</span>
                        </div>

                        <div id="recuperar_senha_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_recuperar_senha' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'proximo_recuperar_senha' titulo='FINALIZAR' cor='salvar'/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </>
    )
}

export default ModalRecuperarSenha