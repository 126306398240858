import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { listaTarefas } from '../../services/hg/tasks'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuAtendimento (props: menuOpcoes) {
    
    const filtrosTarefas = useSelector ((store: RootStore) => store.filtrosTarefas)
    const perfil = localStorage.getItem('perfilHg')
    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [subMenu, setSubMenu] = useState(false)
    const [notificacoesAtendimento, setNotificacoesAtendimento] = useState (0)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect (() => {

        const tarefas = async () => {

            const paramsAtendimento = {
                tipo: 'atendimento',
                perfil: perfil
            }

            const payload = {
                dataInicial: filtrosTarefas.dataInicial,
                dataFinal: filtrosTarefas.dataFinal,
                situacao: filtrosTarefas.situacao,
                responsavel: filtrosTarefas.responsavel,
                perfil: perfil,
            }

            if (perfil !== 'master') {
                Object.assign (payload, {
                        dataInicial: filtrosTarefas.dataInicial,
                        dataFinal: filtrosTarefas.dataFinal,
                        responsavel: idUsuario,
                        perfil: perfil,
                    }
                )
            }

            try {
                const respostaAtendimento = await listaTarefas (paramsAtendimento, payload)

                if (respostaAtendimento.status === 200) {
        
                    return setNotificacoesAtendimento (respostaAtendimento.data.length)

                }

            } catch (error) {

            }
        }

        tarefas ()

    }, [filtrosTarefas, idUsuario, perfil])

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                <span>
                    {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                    {props.titulo}
                </span>
                <h3>{notificacoesAtendimento === 0 ? null : notificacoesAtendimento.toString().padStart(2,'0')}</h3>
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = {`/atendimento/${perfil}`} texto = 'Atendimento' notificacao={notificacoesAtendimento === 0 ? null : notificacoesAtendimento} />}
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Caixa' />}   
                    </>
                </Perfil>
            </div>
        </div>
    )
}

export default MenuAtendimento