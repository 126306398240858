import { Link } from 'react-router-dom'
import SubmenuOpcoes from '../../interfaces/propsSubMenuOpcoes'
import './submenuopcoes.css'

function SubMenuOpcoes (props: SubmenuOpcoes) {
    return (
        <div id = 'submenu_opcoes'>
            <span>
                <Link id='submenu_link' to = {props.redirect}>
                    {props.texto}
                </Link>
                <h5>{props.notificacao}</h5>
            </span>
        </div>
    )
}

export default SubMenuOpcoes