import { useEffect } from 'react'
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import FiltrosReceitas from "../../components/FiltrosReceitas";
import ListaDeReceitas from "../../components/ListaDeReceitas";

function Receitas() {

    useEffect (() => {
        document.title = 'Netclick - Receitas'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <>
                    <FiltrosReceitas />
                    <ListaDeReceitas />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Receitas