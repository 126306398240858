import baseApiHg from "../api/config"
import { graficoFaturamentoMesRequisicaoPayload } from "../../../interfaces/graficos"

const data = new Date ()
const dataBase = new Date (data.setMonth(data.getMonth() - 10))

const ano = dataBase.getFullYear()
const mes = dataBase.getMonth()
const mesAtual = data.getMonth() - 1
const qtnDiasMesAtual = new Date (data.getFullYear(), data.getMonth() - 1, 0).getDate()
const anoAtual = data.getFullYear() + 1

const dataInicial = `${ano}-${mes.toString().padStart(2, '0')}-01`
const dataFinal = `${anoAtual}-${mesAtual.toString().padStart(2, '0')}-${qtnDiasMesAtual}`

export function clientesAtivosMes () {
    return baseApiHg.get(`dashboard/grafico/clientesAtivos?dataInicial=${dataInicial}&dataFinal=${dataFinal}`)
}

export function faturamentoMes (payload: graficoFaturamentoMesRequisicaoPayload) {
    return baseApiHg.get(`/dashboard/grafico/faturamentoMes?dataInicial=${payload.ano}-01-01&dataFinal=${payload.ano}-12-31`)
}

export function ClientesCidades () {
    return baseApiHg.get('/dasboard/graficos/clientesCidade')
}

export function AtendimentosRealizados (idUsuario: number) {
    return baseApiHg.get (`/atendimentosrealizados?usuario=${idUsuario}`)
}

export function avaliacaoAtendimento (idUsuario: number) {
    return baseApiHg.get (`/nps?responsavel=${idUsuario}`)
}