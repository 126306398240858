import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalRecebimentoPagamento } from '../../store/modules/modalInformarRecebimentoPagamento'
import { receitasOpcoesModal } from '../../interfaces/receitas'
import { novoBoletoInter } from '../../services/inter/boletos'
import { NovaFatura } from '../../services/hg/email'
import { detalhesReceita, receitaBoletoPdf, cobrancaWhatsapp, linkPagamento } from '../../services/hg/receitas'
import { reciboPagamentoPdf } from '../../services/hg/reports'
import ModalInformarPagamentoRecebimento from '../ModalInformarPagamentoRecebimento'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import reciboImg from '../../assets/img/recibo.png'
import visualizarBoletoImg from '../../assets/img/visualizar_boleto.png'
import enviarEmailImg from '../../assets/img/enviar_email.png'
import enviarWhatsAppImg from '../../assets/img/whatsapp.png'
import cartaoCreditoImg from '../../assets/img/cartaoCredito.png'
import recebimentoImd from '../../assets/img/recebimento.png'
import cancelarBoletoImg from   '../../assets/img/cancelar_boleto.png'
import './modalopcoesreceitas.css'

function ModalOpcoesReceitas (props: receitasOpcoesModal) {

    const dispatch = useDispatch()

    const modalPagamentoRecebimentoEstado = useSelector((store: RootStore) => store.modalInformarRecebimentoPagamento)

    const [modalPagamentoRecebimento, setModalPagamentoRecebimento] = useState (false)
    const [solicitacao] = useState (false)
    const [boletoPesquisa, setBoletoPesquisa] = useState (false)
    const [processando, setProcessando] = useState (false)
    const [confirmacaoEmail, setConfirmacaoEmail ] = useState (false)
    const [confirmacaoWhatsApp, setConfirmacaoWhatsApp ] = useState (false)
    const [confirmacaoLink, setConfirmacaoLink ] = useState (false)
    const [confirmacaoReciboEmail] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)

    function exibirModalRecebimentoPagamento (idReceita: number) {
        dispatch (setExibirModalRecebimentoPagamento ({
            exibir: true,
            id: idReceita
        }))
    }

    function receitaNovoBoleto (idReceita: number) {
        
        const novoBoleto = async () => {

            setProcessando (true)

            try {
                
                await novoBoletoInter (idReceita)
                setProcessando (false)

                return visualizarBoletoVendaPdf (idReceita)

            } catch (error) {
                setProcessando(false)

                setTimeout (() => {
                    return setBoletoPesquisa (true)
                }, 2500)
            }
        }

        novoBoleto ()
    }

    function visualizarBoletoVendaPdf (idReceita: number) {

        const visualizarPdf = async () => {

            setProcessando (true)

            try {
                const resposta = await receitaBoletoPdf (idReceita)
                const pdf = resposta.data.boleto

                if (pdf !== null) {
                    setProcessando (false)
                    return window.open(pdf, '_blank')
                }

                return receitaNovoBoleto (idReceita)

            } catch (error) {
                setProcessando (true)
                
                setTimeout (() => {
                    return setProcessando (false)
                }, 2500)
            }
        }

        visualizarPdf ()

    }

    function whatsAppFatura (idReceita: number) {

        const enviarCobranca = async () => {
            
            setProcessando (true)

            try {

                const boleto = await detalhesReceita (idReceita)

                if (boleto.data.boleto === null) {

                    setErroOperacao (true)

                    return setTimeout (() => {
                        setProcessando (false)
                        setErroOperacao (false)
                    }, 2500)
                }

                await cobrancaWhatsapp (idReceita)

                setProcessando (false)
                setConfirmacaoWhatsApp(true)

                setTimeout (() => {
                    return setConfirmacaoWhatsApp (false)
                }, 3000)

            } catch (error) {
                console.log (error)
                return alert ('Falha ao Enviar a Mensagem. Tente Novamente!')
            }
        }

        enviarCobranca ()
    }

    
    function link (idReceita: number) {

        const gerarLink = async () => {
            
            setProcessando (true)

            try {

                const resposta = await linkPagamento (idReceita)

                if (resposta.status === 200) {

                    setProcessando (false)
                    setConfirmacaoLink(true)
                    
                    window.open(resposta.data, '_blank')

                    setTimeout (() => {

                        return setConfirmacaoLink (false)

                    }, 3000)
                }


            } catch (error) {

                setProcessando(false)

                return alert ('Falha ao Gerar o Link de Pagamento. Tente Novamente!')
            }
        }

        gerarLink ()
    }

    function emailFatura (idReceita: number) {

        
        const enviarEmail = async () => {
            
            setProcessando (true)

            try {

                const boleto = await detalhesReceita (idReceita)

                if (boleto.data.boleto === null) {

                    setErroOperacao (true)

                    return setTimeout (() => {
                        setProcessando (false)
                        setErroOperacao (false)
                    }, 2500)
                }

                await NovaFatura (idReceita)

                setProcessando (false)
                setConfirmacaoEmail(true)

                setTimeout (() => {
                    return setConfirmacaoEmail (false)
                }, 3000)

            } catch (error) {

                return alert ('Falha ao Enviar o Email. Tente Novamente!')
            }
        }

        enviarEmail ()
    }

    async function reciboPagamento (idReceita: number) {

        setProcessando (true)

        try {

            const resposta = await reciboPagamentoPdf (idReceita)

            if (resposta.status === 200) {
                
                const pdfBlob = new Blob ([resposta.data], { type:'application/pdf' })

                const url = URL.createObjectURL (pdfBlob)

                window.open (url, '_blank')

                setProcessando (false)
            }

        } catch (error) {
            setProcessando (false)
            return alert ('Falha ao Gerar o Recibo')
        }
    }

    useEffect (() => {
        setModalPagamentoRecebimento (modalPagamentoRecebimentoEstado.exibir)
    }, [modalPagamentoRecebimento, modalPagamentoRecebimentoEstado])


    return (
        <div id = 'modalOpcoesReceitas'>
            {modalPagamentoRecebimento && <ModalInformarPagamentoRecebimento />}
            {solicitacao && <CarregandoCirculo />}
            {processando && <CarregandoCirculo />}
            {boletoPesquisa && <ErrorOperacao texto='Ocorreu um erro. Tente Novamenete'/>}
            {confirmacaoWhatsApp && <ConfirmacaoOperacao texto='Menssagem Enviada com Sucesso' />}
            {confirmacaoEmail && <ConfirmacaoOperacao texto='E-mail Enviado com Sucesso' />}
            {confirmacaoReciboEmail && <ConfirmacaoOperacao texto='Recibo Enviado para o E-mail do Cliente' />}
            {erroOperacao && <ErrorOperacao texto='Não Existe Boleto para essa Receita' />}
            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => visualizarBoletoVendaPdf(props.idReceita)}>
                <img id = 'visualizar_receita' alt='Visualizar receita' src= {visualizarBoletoImg}/>
                <p>Visualizar Boleto</p>
            </span>

            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => whatsAppFatura(props.idReceita)}>
                <img title='Enviar Boleto por WhatsApp' id = 'enviar_email_boleto' alt='Enviar pelo WhatsApp' src= {enviarWhatsAppImg}/>
                <p>Enviar Boleto pelo WhatsApp</p>
            </span>
            
            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => link(props.idReceita)}>
                <img title='Pagar com o Cartão' id = 'enviar_email_boleto' alt='Pagar Pelo Cartão' src= {cartaoCreditoImg}/>
                <p>Link de Pagamento</p>
            </span>

            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => emailFatura(props.idReceita)}>
                <img title='Enviar Boleto por E-mail' id = 'enviar_email_boleto' alt='Enviar por E-mail' src= {enviarEmailImg}/>
                <p>Enviar Boleto por E-mail</p>
            </span>


            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => exibirModalRecebimentoPagamento(props.idReceita)}>
                <img title='Informar Recebimento' id = 'recebimento_receita' alt='Informar Recebimento' src= {recebimentoImd}/>
                <p>Informar Recebimento</p>
            </span>

            <span id='receitasModalOpcoes' className={props.situacaoReceita.toLowerCase()} onClick = {() => reciboPagamento (props.idReceita)}>
                <img title='Comprovante de Pagamento' id = 'recibo_pagamento' alt='Enviar Recibo por E-mail' src= {reciboImg}/>
                <p>Comprovante de Pagamento</p>
            </span>

            <span id='receitasModalOpcoes' className={props.situacaoReceita.toUpperCase()} onClick = {() => alert('Função em Desenvolvimento')}>
                <img title='Excluir Receita' id = 'excluir_receita' alt='Excluir Receita' src= {cancelarBoletoImg}/>
                <p>Excluir Receita</p>
            </span>
        </div>
    )
}

export default ModalOpcoesReceitas

