import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovaTarefaProcessoReduce = createSlice ({
    name: 'modalNovaTarefaProcesso',
    initialState: {
        exibir: false,
        id: 0
    } as ModalEstado,
    reducers: {
        setExibirModalNovaTarefaProcesso (state, action) {
            Object.assign (state, {
                exibir: true,
                id: action.payload.id
            })
        },

        setOcultarModalNovaTarefaProcesso (state, action) {
            Object.assign (state, {
                exibir: false,
                id: 0
            })
        }
    }
})

export const { setExibirModalNovaTarefaProcesso, setOcultarModalNovaTarefaProcesso } = modalNovaTarefaProcessoReduce.actions

export default modalNovaTarefaProcessoReduce.reducer