import { createSlice } from "@reduxjs/toolkit"
import { filtrosLeadsPayload } from "../../../interfaces/leads"

const filtroLeadsReduce = createSlice ({
    name: 'filtrosLeads',
    initialState: {
        nome: '',
        situacao: 'em negociação',
    } as filtrosLeadsPayload,
    reducers: {
        setFiltrosLeads (state, action) {
            Object.assign (state, {
                nome: action.payload.nome,
                situacao: action.payload.situacao,
            })
        }
}})

export const { setFiltrosLeads } = filtroLeadsReduce.actions

export default filtroLeadsReduce.reducer