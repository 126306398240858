import baseApiHg from "../api/config"
import { leadCadastrarPayload, filtrosLeadsPayload, atualizarLeadPayload } from "../../../interfaces/leads"

export function novoLead (payload: leadCadastrarPayload ) {
    return baseApiHg.post ('/leads', payload)
}

export function listarLeads (payload: filtrosLeadsPayload) {
    return baseApiHg.get (`/leads?nome=${payload.nome}&situacao=${payload.situacao}`)
}

export function detalhesLead (id: string) {
    return baseApiHg.get (`/leads/${id}`)
}

export function atualizarLead (id: string, payload: atualizarLeadPayload) {
    return baseApiHg.patch (`/leads/${id}`, payload)
}