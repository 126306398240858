import { useEffect } from 'react'
import Footer from "../../components/Footer"
import FundoLogin from "../../components/FundoLogin"
import "../../styles/global.css"

function Home() {

  useEffect (() => {
    document.title = 'Netclick - Login'
  }, [])

  return (
    <>
      <FundoLogin />
      <Footer />
    </>
  )
}

export default Home;
