import { createSlice } from "@reduxjs/toolkit"
import { tarefaAutoRequisicaoPaylod } from "../../../interfaces/tasks"

const filtroAutoTarefasReduce = createSlice ({
    name: 'filtros_auto_tarefas',
    initialState: {
        cliente: '',
        servico: '',
        responsavel: '',
    } as tarefaAutoRequisicaoPaylod,
    reducers: {
        setFiltroAutoTarefas (state, action) {
            Object.assign (state, {
                servico: action.payload.servico,
                responsavel: action.payload.responsavel,
                cliente: action.payload.cliente
            })
        }
}})

export const { setFiltroAutoTarefas } = filtroAutoTarefasReduce.actions

export default filtroAutoTarefasReduce.reducer