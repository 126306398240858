import { useState, FormEvent, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { RootStore } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { usuariosAtivos } from '../../services/hg/usuarios'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { setFiltroTarefas } from '../../store/modules/filtrosTarefas'
import { setExibirModalNovaTarefa } from '../../store/modules/modalNovaTarefa'
import usuarioLogado from '../../services/hg/usuarios'
import Perfil from '../../middleware/perfil'
import Btn from '../Btn'
import './tarefas.css'

function FiltrosTarefas () {

    const filtros = useSelector ((store: RootStore) => store.filtrosTarefas)

    const [dataInicial, setDataInicial] = useState (`${filtros.dataInicial}`)
    const [dataFinal, setDataFinal] = useState (`${filtros.dataFinal}`)
    const [situacao, setSituacao] = useState ('')
    const [usuarios, setUsuario] = useState<UsuariosAtivos []> ([])
    const [responsavel, setResponsavel] = useState ('')
    const dispatch = useDispatch()
    const { tipo } = useParams ()

    function exibirModal () {
        dispatch (setExibirModalNovaTarefa ({
            exibir: true
        }))
    }

    function filtrosTarefas (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltroTarefas ({
            dataInicial,
            dataFinal,
            situacao,
            responsavel
        }))
    }

    useEffect (() => {

        const responsavelAtivos = async () => {
            
            try {
                const resposta = await usuariosAtivos ()

                setUsuario (resposta.data)

            } catch (error) {
                
            }
        }

        responsavelAtivos ()

    }, [])

    
    useEffect (() => {
        
        const usuarioSessao = async () => {
            
            try {
                
                const resposta = await usuarioLogado ()
                
                if (resposta.data.perfil !== 'master') {
                    
                    dispatch (setFiltroTarefas ({
                        dataInicial,
                        dataFinal,
                        situacao,
                        responsavel: resposta.data.id
                    }))
                }
                
            } catch (error) {
                alert ('Falha ao Obter as Suas Credenciais')
                return window.location.href = '/'
            }
        }
        
        usuarioSessao ()
        
    }, [dataInicial, dataFinal, situacao, responsavel, dispatch])
    
    useEffect (() => {

        const usuarioSessao = async () => {

            try {
                
                const resposta = await usuarioLogado ()

                if (resposta.data.perfil !== 'master') {

                    dispatch (setFiltroTarefas ({
                        dataInicial,
                        dataFinal,
                        situacao,
                        responsavel: resposta.data.id
                    }))
                }

            } catch (error) {
                alert ('Falha ao Obter as Suas Credenciais')
                return window.location.href = '/'
            }
        }

        usuarioSessao ()

    }, [])

    return (
        <div id = 'filtro_tarefa'>
            <form id = 'lado_esquerdo_tarefas' onSubmit={filtrosTarefas}>
                <div id = 'input_data_tarefa'>
                    <label>DATA INICIAL</label>
                    <input 
                        id = 'filtro_data_tarefas' 
                        type = 'date' 
                        required
                        value={dataInicial}
                        onChange={(e) => setDataInicial (e.target.value)}/>
                </div>

                <div id = 'input_data_tarefa'>
                    <label>DATA FINAL</label>
                    <input 
                        id = 'filtro_data_tarefas' 
                        type = 'date'
                        required
                        value={dataFinal} 
                        onChange= {(e) => setDataFinal (e.target.value)}/>
                </div>

                <div id = 'input_situacao_tarefa'>
                    <label>SITUAÇÃO</label>
                    <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                        <option id = 'situacao' value = ''>TODAS</option>
                        <option id = 'situacao' value = 'pendente'>PENDENTE</option>
                        <option id = 'situacao' value = 'atrasada'>ATRASADA</option>
                        <Perfil perfil={['master', 'administrativo']}>
                            <option id = 'situacao' value = 'concluída'>CONCLUÍDA</option>
                        </Perfil>
                    </select>
                </div>
                <Perfil perfil={['master']}>
                    <div id = 'input_responsavel_tarefa'>
                        <label>RESPONSÁVEL</label>
                        <select className = "select_responsavel_tarefa" value={responsavel} onChange={(e) => setResponsavel(e.target.value)}>
                            <option id = 'situacao' value = ''>TODOS</option>
                            {usuarios.map ((usuario) => (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                </Perfil>

                <Perfil perfil={['master']}>
                    <Btn id = 'pesquisar_tarefa' titulo='PESQUISAR' cor='novo' />
                </Perfil>
            </form>
            <div id = 'lado_direiro_tarefas'>
                <Btn acao={exibirModal} id = 'nova_tarefa' titulo= {'ADICIONAR ' + tipo?.toUpperCase()} cor='novo' />
            </div>
        </div>
    )
}

export default FiltrosTarefas