import baseApiHg from "../api/config"

export function NovaFatura (idReceita: number) {
    return baseApiHg.post(`/email/novafatura?idReceita=${idReceita}`)
}

export function NovoReciboPagamento (idReceita: number) {
    return baseApiHg.post(`/email/recibopagamento?idReceita=${idReceita}`)
}

export function NovaAvaliacao (idTarefa: number) {
    return baseApiHg.post(`/email/avaliacao?idTarefa=${idTarefa}`)
}