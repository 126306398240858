import { useState, useEffect } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootStore } from '../../store'
import { assinaturasRespostaPayload } from '../../interfaces/assinaturas'
import { listaAssinaturas, atualizarAssinatura } from '../../services/hg/assinaturas'
import { setAtualizarDados } from "../../store/modules/atualizarDados"
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from "../ConfirmacaoOperacao"
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ErrorOperacao from '../ErroOperacao'
import DivSituacao from '../DivSituacao'
import './listaassinaturas.css'

function ListaDeAssinaturas () {

    const filtrosAssinaturas = useSelector((store: RootStore) => store.filtrosAssinaturas)
    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaAssinatura)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const navegate = useNavigate ()
    const dispatch = useDispatch ()

    const [assinaturas, setAssinaturas] = useState<assinaturasRespostaPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [erroOperacao, setErroOperacao] = useState(false)

    const AssinaturaDados =  (idAssinatura: number) => {
        return navegate (`/assinatura/${idAssinatura}`)
    }

    const atualizarAssinaturaDados = async (id: number, situacao: string) => {

        setSolicitacao (true)

        const payload = {
            id,
            situacao: situacao === 'ativa' ? 'cancelada' : 'ativa'
        }

        try {
            const resposta = await atualizarAssinatura (payload)

            if (resposta.status === 200) {
                setSolicitacao (false)
                setConfirmacao (true)

                dispatch (setAtualizarDados ({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))

                return setTimeout (() => {
                    setConfirmacao (false)
                }, 2500)
            }

        } catch (error) {
            setErroOperacao (true)

            return setTimeout (() => {
                setErroOperacao (false)
            }, 2500)

        }
    }


    useEffect (() => {
        const listadeAssinaturas = async () => {

            setSolicitacao (true)

            const payload = {
                situacao: filtrosAssinaturas.situacao,
                cliente: filtrosAssinaturas.cliente
            }
            try {
                const resposta = await listaAssinaturas (payload)
                setResultadoPesquisa (false)
                setAssinaturas (resposta.data)
                setSolicitacao (false)

                if (resposta.data.length === 0) {
                    return setResultadoPesquisa (true)
                }

            } catch (error) {
                setErroOperacao (true)

                return setTimeout (() => {
                    setErroOperacao (false)
                }, 2500)

            }
        }

        listadeAssinaturas ()

    }, [filtrosAssinaturas, modalEstado, atualizarDados])

    return (
        <div id = 'dados_assinaturas'>
            {confirmacao && <ConfirmacaoOperacao  texto="Assinatura Atualizada com Sucesso"/>}
            {solicitacao && <CarregandoCirculo />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Recuperar os Dados' />}
            {<table id = 'tabela_assinaturas'>
                <thead>
                    <tr id = 'assinatura_cabecalho'>
                        <th id = 'id_assinatura'>ID</th>
                        <th id = 'nome_cliente_assinatura'>CLIENTE</th>
                        <th id='descricao_boleto'>DESCRIÇÃO</th>
                        <th id = 'valor_boleto'>VALOR</th>
                        <th align='center' id ='vencimento_boleto'>DIA DO VENCIMENTO</th>
                        <th align='center' id = 'situacao_boleto'>SITUACAO</th>
                        <th align='center' id = 'situacao_boleto'>AÇÃO</th>
                    </tr>
                </thead>

                <tbody>
                    {assinaturas.map ((assinatura) => (
                        <tr key={assinatura.id} id = 'lista_assinaturas' className= {assinatura.situacao}>
                            <td id = 'id_assinatura' onClick = {() => AssinaturaDados(assinatura.id)}>{(`00000${assinatura.id}`).slice(-5)}</td>
                            <td id = 'nome_cliente_assinatura' onClick = {() => AssinaturaDados(assinatura.id)}>{assinatura.cliente.nome_fantasia.toUpperCase()}</td>
                            <td id = 'descricao_assinatura' onClick = {() => AssinaturaDados(assinatura.id)}>{assinatura.descricao.toUpperCase()}</td>
                            <td id = 'valor_boleto' onClick = {() => AssinaturaDados(assinatura.id)}>{Number(assinatura.valor_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            <td align='center' onClick = {() => AssinaturaDados(assinatura.id)}>{(`0${assinatura?.dia_cobranca}`).slice(-2) + ' DE CADA MÊS'}</td>
                            <td align='center' onClick = {() => AssinaturaDados(assinatura.id)}>
                                <DivSituacao texto= {assinatura.situacao.toUpperCase()} cor = {assinatura.situacao} />
                            </td>
                            <td align='center'>
                                <input
                                    title={assinatura.situacao === 'ativa' ? 'Desativar Assinatura' : 'Ativar Assinatura'}
                                    type='checkbox'
                                    onClick={() => atualizarAssinaturaDados(assinatura.id, assinatura.situacao)}
                                    checked = {assinatura.situacao === 'ativa' ? true : false}    
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeAssinaturas