import erroImg from '../../assets/img/erro_operacao.gif'
import erroOperacaoProps from '../../interfaces/propsErroOperacao'
import './erroroperacao.css'

function ErrorOperacao (props: erroOperacaoProps) {
    return (
        <div id = 'erro_operacao'>
            <div id = 'erro_dados_operacao'>
                <img id = 'erro_operacao_img' src= {erroImg} alt = 'imagem de erro da operação'></img>
                <p>{props.texto}</p>
            </div>
        </div>
    )
}

export default ErrorOperacao