import baseApiHg from "../../hg/api/config"
import { boletosRequisicaoPaylod, boletosCancelarRequisicaoPayload } from "../../../interfaces/boletos"

export function novoBoletoInter (idReceita: number) {
    return baseApiHg.post(`/inter/boletos?idReceita=${idReceita}`)
}

export function listaBoletosInter (payload: boletosRequisicaoPaylod) {
    return baseApiHg.get(`/inter/boletos?dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}&situacao=${payload.situacao}&nome=${payload.cliente}`)
}

export function visualizarBoletoPdf (nossoNumero: string) {
    return baseApiHg.get(`/inter/boletos/pdf?numeroBoleto=${nossoNumero}`)
}

export function cancelarBoleto (nossoNumero: string, payload: boletosCancelarRequisicaoPayload) {
    return baseApiHg.post (`/inter/boletos/cancelar/${nossoNumero}`, payload)
}
