import styled from "styled-components"
import btnDefaultProps from "../../interfaces/btnDefaultProps"
import divSituacaoDefaultProps from "../../interfaces/divSituacaoDefaultProps"
import coresBotaoDefault from "../../interfaces/coresBotaoDefault"
import coresDivSituacaoDefault from "../../interfaces/coresDivSituacaoDefault"

const coresBotao: coresBotaoDefault = {
    novo: '#00A6A6',
    salvar: '#00A6A6',
    cancelar: '#A41623'
}

const coresDiv: coresDivSituacaoDefault = {
    emaberto: '#0000FF',
    aberta: '#0000FF',
    alta: '#0000FF',
    aberto: '#A1A1A1',
    baixa: '#A1A1A1',
    emanálise: '#F7B558',
    emnegociação: '#0000FF',
    aguardandoretornodosuporte: '#018ABE',
    aguardandoretornodocliente: '#00A6A6',
    perdido: '#A41623',
    conquistado: '#008000',
    concluído: '#008000', 
    paga: '#008000',
    pago: '#008000',
    ativo: '#008000',
    ativa: '#008000',
    concluída: '#008000',
    aceita: '#F7B558',
    semanálise: '#F7B558',
    expirado: '#F7B558',
    pendente: '#F7B558',
    cancelado: '#aa7326',
    expirada: '#A41623',
    urgente: '#A41623',
    vencida: '#A41623',
    vencido: '#A41623',
    inativo: '#A41623',
    recusada: '#A41623',
    cancelada: '#A41623',
    atrasada: '#A41623',
    pontinhosFundoBranco: '#261132',
    pontinhosFundoOutro: '#FFF'
}

export const BtnDefault = styled.button <btnDefaultProps> `
    background-color: ${(props) => coresBotao[props.cor]};
    width: max-content;
    height: 40px;
    padding: 0px 25px;
    border-radius: 5px;
    cursor: pointer;
    color: #FFF;
    border: none;

    &:hover {
        background-color: #261132;
        transition-duration: 0.5s;
    }
`

export const SelectDefault = styled.select `
    background-color: #261132;
    box-shadow: 5px 3px 7px #a1a1a1;
    outline: none;
    border: none;
    padding: 5px;
    margin-left: 20px;
    color: #FFF;
    width: max-content;
    height: 38px;
    border-radius: 5px;
    font-size: 1em;
    font-family: 'Quicksand', sans-serif;

    option {
        color: #FFF;
        background: #261132;
    }

    &:hover {
        border: none;
        outline: none;
        cursor: pointer;
    }
`

export const DivDadosDefault = styled.div `
    width: 90%;
    max-width: calc(100% - 180px);
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 10px;

    @media (max-width: 1000px) {
        #dados {
            flex-wrap: wrap;
        }
    }
`

export const DivSituacaoDefault = styled.div <divSituacaoDefaultProps> `
    background-color: ${(props) => coresDiv[props.cor]};
    width: 50%;
    min-width: 120px;
    color: #FFF;
    border-radius: 5px;
    height: max-content;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SpanPontinhosDefault = styled.span<divSituacaoDefaultProps> `
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:10% auto;
    position: relative;
    color: ${(props) => coresDiv[props.cor]};
    box-sizing: border-box;
    animation: animacaoPontinhos 2s linear infinite;

    @keyframes animacaoPontinhos {
        0% {
            box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
        }
        25% {
            box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
        }
        50% {
            box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
        }
        75% {
            box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
        }
        100% {
            box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
        }
    }
`