import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovaSugestaoReduce = createSlice ({
    name: 'modal_nova_sugestao',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovaSugestao (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovaSugestao (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovaSugestao, setOcultarModalNovaSugestao } = modalNovaSugestaoReduce.actions

export default modalNovaSugestaoReduce.reducer