import loginHg from "../api/authConfig"
import { loginPayload, recuperarSenhaPayload, novaSenhaCadastrarPayload } from "../../../interfaces/auth"

export function recuperarSenha (payload: recuperarSenhaPayload) {
    return loginHg.post('/recuperarsenha', payload)
}

export function novaSenha (payload: novaSenhaCadastrarPayload) {
    return loginHg.post ('/novasenha', payload)
}

export default function auth (payload: loginPayload) {
    return loginHg.post('/login', payload)
}