import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setExibirModalDadosSugestao } from '../../store/modules/modalVisualizarDadosSugestao'
import { sugestaoPayload } from '../../interfaces/sugestoes'
import { RootStore } from '../../store'
import VisualizarDadosSugestao from '../VisualizarDadosSugestao'
import CarregandoCirculo from '../CarregandoCirculo'
import ErrorOperacao from '../ErroOperacao'
import listaDeSugestao from '../../services/hg/sugestaoMelhoriaLista'
import DivSituacao from '../DivSituacao'
import './sugestaomelhoria.css'

function ListaDeSugestaoMelhoria () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosSugestao)
    const filtrosSugestoes = useSelector ((store: RootStore) => store.filtrosSugestao)
    const dispatch = useDispatch ()

    const [sugestoes, setSugestoes] = useState<sugestaoPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (true)
    const [resultadoPesquisa, setResultadoPesquisa] = useState (false)
    const [modal, setModal] = useState (false)

    function exibirModal (id: number) {
        dispatch (setExibirModalDadosSugestao ({
            exibir: true,
            id: id
        }))
    }

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {

        const payload = {
            termo: filtrosSugestoes.termo,
            status: filtrosSugestoes.status
        }

        const sugestaoLista = async () => {

            try {
                const resposta = await listaDeSugestao (payload)
                setSolicitacao (false)
                setSugestoes(resposta.data)

                if (resposta.data.length === 0 && filtrosSugestoes.status !== "" && filtrosSugestoes.termo !== "") {
                    setSolicitacao (false)
                    setResultadoPesquisa (true)

                    setTimeout (() => {
                        setResultadoPesquisa (false)
                    }, 2500)
                }

            } catch (error) {
                alert ('Erro ao listar as sugestões')
            }
        }

        sugestaoLista ()

    }, [setSugestoes, filtrosSugestoes])

    return (
        <div id = 'dados_sugestao_lista'>
            {modal && <VisualizarDadosSugestao />}
            {solicitacao && <CarregandoCirculo />}
            {resultadoPesquisa && <ErrorOperacao texto='Nenhum Resultado Encontrado' />}
            {<table id = 'tabela_sugestao'>
                <thead>
                    <tr id = 'sugestaoCabecalho'>
                        <td id = 'id_sugestao'>ID</td>
                        <td>TITULO</td>
                        <td>REPORTADO POR</td>
                        <td align = 'center' id = 'status_sugestao'>STATUS</td>
                    </tr>
                </thead>

                <tbody>

                    {sugestoes.map ((sugestao) => (
                        <tr key={sugestao.id} id = 'lista_sugestao' onClick={() => exibirModal(sugestao.id)}>
                            <td id = 'id_sugestao'>{(`0000${sugestao.id}`).slice (-5)}</td>
                            <td>{sugestao.titulo.toUpperCase()}</td>
                            <td>{sugestao.usuario.nome.toUpperCase()}</td>
                            <td align = 'center'>
                                <DivSituacao texto= {sugestao.status.toUpperCase()} cor= {sugestao.status.replace(/ /g, "")} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    )
}

export default ListaDeSugestaoMelhoria