import { createSlice } from "@reduxjs/toolkit"
import filtrosClientesRequisicaoPayload from "../../../interfaces/filtrosClientesRequisicao"

const filtroClientesReduce = createSlice ({
    name: 'filtros_clientes',
    initialState: {
        termo: '',
        situacao: 'ativo',
    } as filtrosClientesRequisicaoPayload,
    reducers: {
        setFiltrosClientes (state, action) {
            Object.assign (state, {
                termo: action.payload.termo,
                situacao: action.payload.situacao,
            })
        }
}})

export const { setFiltrosClientes } = filtroClientesReduce.actions

export default filtroClientesReduce.reducer