import { useState, useEffect } from 'react'
import { listaProcessosEmAndamento } from '../../services/hg/processos'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuProcessos (props: menuOpcoes) {

    const perfil = localStorage.getItem('perfilHg')
    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [subMenu, setSubMenu] = useState(false)
    const [qtnNotificacao, setQtnNotificacao] = useState(0)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    useEffect (() => {

        const listaProcessosAndamento = async () => {

            const payload = {
                titulo: '',
                situacao: 'ativo',
                responsavel: '',
                perfil: perfil,
            }

            if (perfil !== 'master') {
                Object.assign (payload, {
                        titulo: '',
                        situacao: 'ativo',
                        responsavel: idUsuario,
                        perfil: perfil,
                    }
                )
            }

            if ( payload.perfil === undefined ) {
                return
            }

            try {

                const resposta = await listaProcessosEmAndamento (payload)
                setQtnNotificacao (resposta.data.length)

            } catch (error) {
        
            }
        }

        listaProcessosAndamento ()
        
    }, [idUsuario, perfil])

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                <span>
                    {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                    {props.titulo}
                </span>
                <h3>{qtnNotificacao === 0 ? null : qtnNotificacao.toString().padStart(2,'0')}</h3>
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = {`/processosEmAndamento/${perfil}`} texto = 'Processos em Andamento' notificacao={qtnNotificacao === 0 ? null : qtnNotificacao} />}  
                <Perfil perfil={['master', 'administrativo']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '/modelosProcessos' texto = 'Modelos de Processos'/>}      
                    </>
                </Perfil>
            </div>
        </div>
    )
}

export default MenuProcessos