import { useDispatch, useSelector } from 'react-redux'
import { FormEvent, useState, useEffect } from 'react'
import { setOcultarModalDadosProblema } from '../../store/modules/modalVisualizarDadosProblema'
import { RootStore } from '../../store'
import { bugsDetalhesResposta } from '../../interfaces/bugs'
import { dadosBugsDetalhes, bugsCadastrarComentarios, bugsCarregarComentarios } from '../../services/hg/bugs'
import CarregandoCirculo from '../CarregandoCirculo'
import CarregandoBarra from '../CarregandoBarra'
import bugIcone from '../../assets/img/bug.png'
import fecharIcone from '../../assets/img/fechar.png'
import Btn from '../Btn'
import './visualizardadosproblema.css'

function VisualizarDadosProblema () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosProblema)
    const dispatch = useDispatch ()

    const [bugsDetalhes, setBugsDetalhes ] = useState<bugsDetalhesResposta []>([])
    const [comentario, setComentario] = useState<string> ('')
    const [comentariosLista, setComentariosLista] = useState<bugsDetalhesResposta []> ([])
    const [carregandoBarra, setCarregandoBarra] = useState (true)
    const [carregandoComentarios, setCarregandoComentairos] = useState (false)
    const [processando, setProcessando] = useState (false)
    const [processandoSolicitacao, setProcessandoSolicitacao] = useState (false)
    const [modalDetalhes, setModalDetalhes] = useState (false)
    const [btnComentar, setBtnComentar] = useState (true)
    const [modal, setModal] = useState (false)

    const comentarBug = async (e: FormEvent) => {
        e.preventDefault ()

        const idBug = modalEstado.id

        const payload = {
            comentario: comentario
        }

        setProcessando (true)
        setBtnComentar (false)

        try {

            const resposta = await bugsCadastrarComentarios (idBug, payload)

            if (resposta.status === 200) {
                setComentario ('')
                setProcessando (false)
                setBtnComentar (true)
                return alert ('Comentário postado com sucesso')
            }
            
        } catch (error) {
            setProcessando (false)
            setBtnComentar (true)
            return alert ('Falha ao salvar o seu comentário. Tente Novamente')
        }
    }

    function ocultarModal () {

        dispatch (setOcultarModalDadosProblema ({
            exibir: false
        }))
    }

    useEffect (() => {

        setProcessandoSolicitacao (true)

        const payload = modalEstado.id
    
        if (payload === undefined || payload === null) {
            return alert
        }

        const bugDetalhes = async () => {
            
            try {
                const resposta = await dadosBugsDetalhes (payload)
                setBugsDetalhes (resposta.data)
                
                setProcessandoSolicitacao (false)
                setModalDetalhes (true)

            } catch (error) {
                setProcessandoSolicitacao (false)
                return alert ('Falha ao Recuperar os Dados')
            }
        }

        bugDetalhes ()

    }, [setModal, modalEstado])

    useEffect (() => {
        
        const idBug = modalEstado.id

        const comentarios = async () => {

            try {
                const resposta = await bugsCarregarComentarios(idBug)
                setComentariosLista (resposta.data)
                setCarregandoBarra(false)
                setCarregandoComentairos (true)

            } catch (error) {
                alert ('Falha ao recuperar os comentários')
            }
        }

        comentarios ()

    }, [setComentariosLista, modalEstado])
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (

        <div id = 'fundo_modal_detalhes_problema'>
            {processandoSolicitacao && <CarregandoCirculo />}
            {modalDetalhes && <div id="visualizar_problema_fundo">
                <div id="problema_superior_dados">
                    <div id = 'visualizar_dados_superior_titulo'>
                        <div>
                            <img id = 'bug_icone' src= {bugIcone} alt = 'icone de bug do hg'></img>
                        </div>
                        <div>
                            <p id = 'visualisar_dados_titulo'>TÍTULO</p>
                            <p id = 'visualizar_dados_titulo'><strong>{bugsDetalhes.map ((bug) => (bug.titulo.toUpperCase()))}</strong></p>
                        </div>
                    </div>
                    <div id = 'visualizar_dados_superior_setor'>
                        <p id = 'visualisar_dados_titulo'>SETOR</p>
                        {bugsDetalhes.map((bug) => (
                            <p key={Math.random()} id = 'visualizar_dados_titulo'><strong>{bug.setore.nome.toUpperCase()}</strong></p>
                        ))}
                    </div>
                    <div id = 'visualizar_dados_superior_usuario'>
                        {bugsDetalhes.map((bug) => (
                            <img key={Math.random()} id = 'imagem_usuario_problema_postagem' src= {bug.usuario.avatar} alt = ''></img>
                        ))}
                        <div>
                            <p id = 'visualisar_dados_titulo'>REPORTADO POR</p>
                            {bugsDetalhes.map((bug) => (
                                <p key={Math.random()} id = 'visualizar_dados_titulo'><strong>{bug.usuario.nome.toUpperCase()}</strong></p>
                            ))}
                        </div>
                    </div>
                    <img onClick={() => ocultarModal()} id = 'btn_fechar_visualizar_dados' src= {fecharIcone} alt = 'botão de fechar do hg'></img>
                </div>

                <div id="visualizar_problema_descricao">
                    <div id = 'visualizar_problemas_detalhes'>
                        <div id="problema_detalhes">
                            <p id = 'descricao_titulo'><strong>Descrição</strong></p>
                            <textarea name="descricao_problemas" id="descricao_problema_dados" rows={5} disabled value={bugsDetalhes.map ((id) => (id.descricao[0].toUpperCase() + id.descricao.substring(1)))}></textarea>
                        </div>
                    </div>      

                    <p id = 'comentario_titulo'><strong>Comentários</strong></p>
                    {carregandoBarra && <CarregandoBarra />}
                    {carregandoComentarios && <div id = 'lista_comentários_problema'>
                        {comentariosLista.map((bug) => bug.bug_comentarios.map ((comentario) => (
                            <div key={Math.random()} id = 'comentario_problema'>
                                <div>
                                    <img id = 'usuário_comentario_realizado' src={comentario.usuario.avatar} alt="" />
                                </div>
                                <div id = 'usuario_comentario'>
                                    <p id = 'comentario_usuario'><strong>{comentario.usuario.nome}</strong></p>
                                    <p id = 'comentario_realizado'>{comentario.comentario}</p>
                                </div>
                            </div>   
                        )))}
                    </div>}
                            
                    <form id = 'comentar_problema' onSubmit={comentarBug}>
                        <input
                            id = 'input_comentar_problema' 
                            type="text" 
                            required
                            value={comentario}
                            onChange= {(e) => setComentario(e.target.value)}
                        />
                        {processando && <CarregandoBarra />}
                        {btnComentar && <Btn id = 'btn_comentar_problema' titulo='Comentar' cor='novo' />}
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default VisualizarDadosProblema