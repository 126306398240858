import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovoLead = createSlice ({
    name: 'modalNovoLead',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovoLead (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovoLead (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovoLead, setOcultarModalNovoLead } = modalNovoLead.actions

export default modalNovoLead.reducer