import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import FiltrosAssinaturas from "../../components/FiltrosAssinaturas";
import ListaDeAssinaturas from "../../components/ListaDeAssinaturas";
import ModalNovaAssinatura from "../../components/ModalNovaAssinatura";

function Assinaturas() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaAssinatura)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Assinaturas'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <>
                    {modal && <ModalNovaAssinatura />}
                    <FiltrosAssinaturas />
                    <ListaDeAssinaturas />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Assinaturas;