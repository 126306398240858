import Login from '../Auth'
import './fundo.css'

function FundoLogin () {
    return (
        <>
            <div id = 'fundo_container'>
                <div id = 'login'>
                    <Login />
                </div>
                <div id = 'background'>
                    
                </div>
            </div>
        </>
    )
}

export default FundoLogin