import { useState, useEffect, FormEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setExibirModalDadosTarefa } from '../../store/modules/modalVisualizarDadosTarefas'
import { setNotificacoesTotal, setNotificacoesTarefas, setNotificacoesFlowup, setNotificacoesChamado, setNotificacoesAtendimento } from '../../store/modules/notificacoes'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { tarefasListaResposta } from '../../interfaces/tasks'
import { listaTarefas, deletarTarefa, atualizarLoteTarefa } from '../../services/hg/tasks'
import { usuariosAtivos } from '../../services/hg/usuarios'
import usuarioLogado from '../../services/hg/usuarios'
import Perfil from '../../middleware/perfil'
import Btn from '../Btn'
import CarregandoEsqueletoCaixa from '../CarregandoEsqueletoCaixa'
import VisualizarDadosTarefas from '../VisualizarDadosTarefas'
import CarregandoCirculo from '../CarregandoCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import DivSituacao from '../DivSituacao'
import excluiTarefaImg from '../../assets/img/excluir_preto.png'
import './listatarefas.css'

function ListaDeTarefas () {

    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const modalTarefasDadosEstado = useSelector ((store: RootStore) => store.modalVisualizarDadosTarefas)
    const filtrosTarefas = useSelector ((store: RootStore) => store.filtrosTarefas)
    const { tipo, perfil } = useParams ()
    const dispatch = useDispatch ()
    
    const [tarefas, setTarefas] = useState<tarefasListaResposta []> ([])
    const [loteTarefa, setLoteTarefa] = useState (false)
    const [usuarios, setUsuarios] = useState <UsuariosAtivos []>([])
    const [resultado, setResultado] = useState<string | null> (null)
    const [novoResponsavel, setNovoResponsavel] = useState (0)
    const [situacao, setSituacao] = useState ('pendente')
    const [tipoTarefa, setTipo] = useState('tarefa')
    const [carregando, setCarregando] = useState (true)
    const [dados, setDados] = useState (false)
    const [solicitacao, setSolicitacao] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [confirmacaoLote, setConfirmacaoLote] = useState (false)
    const [responsavel, setResponsavel] = useState ('')
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [modal, setModal] = useState (true)
    const [idsTarefas, setIdsTarefas] = useState<number []> ([])

    function exibirModalDadosTarefas (id: number) {

        dispatch (setExibirModalDadosTarefa ({
            exibir: true,
            id: id
        }))
    }

    function idTarefas (e: any) {

        const { checked, value } = e.target

        if (checked === true) {
            setIdsTarefas (itens => [...itens, Number(value)])
            return setLoteTarefa (true)
        }

        if (checked === false) {
            const posicao = idsTarefas.findIndex(item => item === value)
            idsTarefas.splice(posicao, 1)
        }

        if (!idsTarefas.length) {
            return setLoteTarefa (false)
        }

        return 
    }

    const apagarTarefa =  async (idTarefa: number) => {

        try {

            setSolicitacao (true)

            const resposta = await deletarTarefa (idTarefa)

            if (resposta.status === 200) {

                setSolicitacao (false)
                setConfirmacao (true)

                setTimeout (() => {

                    setConfirmacao (false)

                    return dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 3000)
            }
            
        } catch (error) {
            setSolicitacao (false)
            return alert ('Falha ao Deletar a Tarefa')
        }
    }

    const AtualizarTarefasLote = async (e: FormEvent) => {
        e.preventDefault()

        setSolicitacao (true)

        if (situacao === 'concluída' && resultado === null) {
            setSolicitacao (false)
            return alert ('A tarefa não pode ser concluída sem o resultado')
        }

        const payload = {
            idsTarefas,
            resultado,
            responsavel: novoResponsavel,
            tipo: tipoTarefa,
            situacao
        }

        try {
            
            const resposta = await atualizarLoteTarefa (payload)

            if (resposta.status === 200) {
                setSolicitacao(false)
                setLoteTarefa (false)
                setConfirmacaoLote(true)

                dispatch (setAtualizarDados ({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))

                return setTimeout (() => {
                    setConfirmacaoLote (false)
                }, 2500)

            }

        } catch (error) {
            setSolicitacao(false)
            setLoteTarefa(false)
            return alert (error)
        }
    }

    useEffect (() => {
        setModal (modalTarefasDadosEstado.exibir)
    }, [modalTarefasDadosEstado, modal])


    useEffect (() => {
        const listadeTarefas = async () => {

            setSolicitacao (true)

            const params = {
                tipo,
                perfil,
            }
            
            const payload = {
                dataInicial: filtrosTarefas.dataInicial,
                dataFinal: filtrosTarefas.dataFinal,
                situacao: filtrosTarefas.situacao,
                responsavel: filtrosTarefas.responsavel
            }

            try {
                const resposta = await listaTarefas (params, payload)
                setTarefas(resposta.data)
                setSolicitacao (false)
                setResultadoPesquisa (false)

                if (resposta.data.length === 0) {
                    setResultadoPesquisa (true)
                }
                
            } catch (error) {
                return alert ('Falha ao Recuperar os Dados')
            }
        }

        listadeTarefas ()

    }, [filtrosTarefas, perfil, tipo, atualizarDados])

    useEffect (() => {

        const usuarioSessao = async () => {

            try {
                
                const resposta = await usuarioLogado ()

                if (resposta.status === 200) {
                    setResponsavel (resposta.data.id)
                }

            } catch (error) {
                alert ('Falha ao Obter as Suas Credenciais')
                return window.location.href = '/'
            }
        }

        usuarioSessao ()

    }, [])


    useEffect (() => {

        const tarefas = async () => {

            const paramsTarefas = {
                tipo: 'tarefa',
                perfil: perfil
            }

            const paramsFlowup = {
                tipo: 'flowup',
                perfil: perfil
            }

            const paramsChamado = {
                tipo: 'chamado',
                perfil: perfil
            }

            const paramsAtendimento = {
                tipo: 'atendimento',
                perfil: perfil
            }

            const payload = {
                dataInicial: filtrosTarefas.dataInicial,
                dataFinal: filtrosTarefas.dataFinal,
                situacao: filtrosTarefas.situacao,
                responsavel
            }

            try {
                const respostaTarefas = await listaTarefas (paramsTarefas, payload)
                const respostaFlowup = await listaTarefas (paramsFlowup, payload)
                const respostaChamado = await listaTarefas (paramsChamado, payload)
                const respostaAtendimento = await listaTarefas (paramsAtendimento, payload)


                dispatch ( setNotificacoesTotal ({
                    quantidadeTotal: respostaTarefas.data.length + respostaFlowup.data.length + respostaChamado.data.length
                }))

                dispatch ( setNotificacoesTarefas ({
                    quantidadeTarefa: respostaTarefas.data.length
                }))

                dispatch ( setNotificacoesFlowup ({
                    quantidadeFlowup: respostaFlowup.data.length
                }))

                dispatch ( setNotificacoesChamado ({
                    quantidadeChamado: respostaChamado.data.length
                }))

                dispatch ( setNotificacoesAtendimento ({
                    quantidadeAtendimento: respostaAtendimento.data.length
                }))

            } catch (error) {

            }
        }

        tarefas ()

    }, [atualizarDados, filtrosTarefas, dispatch, perfil, responsavel])

    useEffect (() => {

        const usuariosLista = async () => {

            try {
                const resposta = await usuariosAtivos ()
                setDados (true)
                setNovoResponsavel(resposta.data[0].id)
                return setUsuarios (resposta.data)
                
            } catch (error) {
                return alert ('Erro ao Listar os Usuários')
            }
        }

        usuariosLista ()
        
    }, [setUsuarios])
    

    return (
        <div id = 'dados_tarefas'>
            {modal && <VisualizarDadosTarefas />}
            {solicitacao && <CarregandoCirculo />}
            {confirmacaoLote && <ConfirmacaoOperacao texto='Tarefas Atualizadas com Sucesso' />}
            {confirmacao && <ConfirmacaoOperacao texto='Tarefa Excluída com Sucesso' />}
            {loteTarefa && <form id = 'alteracaoLoteTarefa' onSubmit={AtualizarTarefasLote}>
                <span>
                    <label>Resultado</label>
                    <input type="text" placeholder="RESULTADO DA TAREFA" id = 'inputResultadoAlteracaoLoteTarefa' onChange={(e) => setResultado(e.target.value)}></input>
                </span>

                <span>
                    <label>Responsável</label>
                    {carregando && <CarregandoEsqueletoCaixa />}
                    {dados && <select value={novoResponsavel} onChange = {(e) => setNovoResponsavel (parseInt(e.target.value))}>
                        {usuarios.map ((usuario) => (
                            <option key={usuario.id} value = {usuario.id}>{usuario.nome}</option>
                        ))}
                    </select>}
                </span>

                <span>
                    <label>Tipo</label>
                    {carregando && <CarregandoEsqueletoCaixa />}
                    {dados && <select value={tipoTarefa} onChange = {(e) => setTipo(e.target.value)}>
                        <option value = 'atendimento'>ATENDIMENTO</option>
                        <option value = 'tarefa'>TAREFA</option>
                        <option value = 'flowup'>FLOWUP</option>
                        <option value = 'chamado' >CHAMADO</option>
                    </select>}
                </span>

                <span>
                    <label>Situação</label>
                    <select value={situacao} onChange = {(e) => setSituacao (e.target.value)}>
                        <option value = 'atrasada'>ATRASADA</option>
                        <option value = 'pendente'>PENDENTE</option>
                        <option value = 'concluída' >CONCLUÍDA</option>
                    </select>
                </span>

                <Btn titulo="SALVAR" cor="salvar"/>
            </form>}

            {<table id = 'tabela_tarefas'>
                <thead>
                    <tr id = 'tarefa_Cabecalho'>
                        <th align='center'></th>
                        <th id = 'nome_cliente_tarefa'>CLIENTE</th>
                        <th id='titulo_tarefa'>TÍTULO</th>
                        <th>RESPONSÁVEL</th>
                        <th align='center'>PRAZO</th>
                        <th align='center'>PRIORIDADE</th>
                        <th align='center'>SITUAÇÃO</th>
                        <Perfil perfil={['master', 'administrativo']}>
                            <th align = 'center'>AÇÃO</th>
                        </Perfil>
                    </tr>
                </thead>

                <tbody>
                    {tarefas.map ((tarefa) => (
                        <tr key={tarefa.id} id = 'lista_tarefas'>
                            <td align='center'>
                                <input type="checkbox" value={tarefa.id} id="alteracaoLoteTarefas" onChange={(e) => idTarefas (e)}/>
                            </td>
                            <td id = 'nome_cliente_tarefa' onClick={() => exibirModalDadosTarefas(tarefa.id)}>{tarefa.cliente.nome_fantasia.toUpperCase()}</td>
                            <td id = 'titulo_tarefa' onClick={() => exibirModalDadosTarefas(tarefa.id)}>{tarefa.titulo.toUpperCase()}</td>
                            <td onClick={() => exibirModalDadosTarefas(tarefa.id)}>
                                <span id = 'responsavel_tarefa'>
                                    <img id = 'usuario_tarefa' src = {tarefa.usuario.avatar} alt = ''/>
                                    {tarefa.usuario.nome.toUpperCase()} 
                                </span>
                            </td>
                            <td align='center' onClick={() => exibirModalDadosTarefas(tarefa.id)}>{(`${tarefa.data_termino}`).slice(8,10) + '/' + (`${tarefa.data_termino}`).slice(5,7) + '/' + (`${tarefa.data_termino}`).slice(0,4)}</td>
                            <td align='center' onClick={() => exibirModalDadosTarefas(tarefa.id)}>
                                <DivSituacao texto = {tarefa.prioridade.toUpperCase()} cor = {tarefa.prioridade.toLocaleLowerCase()}/>
                            </td>
                            <td align='center' onClick={() => exibirModalDadosTarefas(tarefa.id)}>
                                <DivSituacao texto= {tarefa.situacao.toUpperCase()} cor = {tarefa.situacao.toLowerCase()}/>
                            </td>
                            <Perfil perfil={['master', 'administrativo']}>
                                <th align = 'center'>
                                    <img id = 'excluir_tarefa_img' title='Excluir Tarefa' src = {excluiTarefaImg} alt = '' onClick={() => apagarTarefa (tarefa.id)}/>
                                </th>
                            </Perfil>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeTarefas