import { createSlice } from "@reduxjs/toolkit"
import { receitaRequisicaoPaylod } from "../../../interfaces/receitas"

let data = new Date()

let ano = data.getFullYear()
let mesAtual = data.getMonth() + 1
let qtnDiasDoMes = new Date (data.getFullYear(), data.getMonth() + 1, 0).getDate()

let mesAtualFormatado = (`0${mesAtual}`).slice (-2)

let dataInicial = `${ano}-${mesAtualFormatado}-01`
let dataFinal = `${ano}-${mesAtualFormatado}-${qtnDiasDoMes}`

const filtrosReceitasReduce = createSlice ({
    name: 'filtros_receitas',
    initialState: {
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        situacao: '',
        cliente: ''
    } as receitaRequisicaoPaylod,
    reducers: {
        setFiltrosReceitas (state, action) {
            Object.assign (state, {
                dataInicial: action.payload.dataInicial,
                dataFinal: action.payload.dataFinal,
                situacao: action.payload.situacao,
                cliente: action.payload.cliente
            })
        }
}})

export const { setFiltrosReceitas } = filtrosReceitasReduce.actions

export default filtrosReceitasReduce.reducer