import { createSlice } from "@reduxjs/toolkit"
import { UsuarioEstado } from "../../../interfaces/usuarios"

const usuarioReduce = createSlice ({
    name: 'usuario',
    initialState: {
        logado: false
    } as UsuarioEstado,
    reducers: {
        setUsuario (state, action) {
            Object.assign (state, {
                logado: true,
            })
        },

        removerUsuario (state, action) {
            Object.assign (state, {
                logado: false
            })
        }
    }
})

export const { setUsuario, removerUsuario } = usuarioReduce.actions

export default usuarioReduce.reducer