import { useState, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setOcultarModalNovoResponsavel } from '../../store/modules/modalNovoResponsavel'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { cadastrarResponsavel, listaDeResponsaveis } from '../../services/hg/responsaveis'
import { validarCpfCnpj, cpfCnpjMascara, telefoneMascara, cepMascara } from '../../helpers'
import Alerta from '../Alerta'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './novoresponsavel.css'

function ModalNovoResponsavel () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const [alertaCpfCadastrado, setAlertaCpfCnpjCadastrado] = useState (false)
    const [alertaCpfInvalido, setAlertaCpfCnpjInvalido] = useState (false)

    const [cpf, setCpf] = useState ('')
    const [nomeCompleto, setNomeCompleto] = useState ('')
    const [telefone, setTelefone] = useState ('')
    const [email, setEmail] = useState ('')
    const [dataNascimento, setDataNascimento] = useState<Date> ()
    const [rua, setRua] = useState ('')
    const [numero, setNumero] = useState ('')
    const [bairro, setBairro] = useState ('')
    const [complemento, setComplemento] = useState<null | string> (null)
    const [cep, setCep] = useState ('')
    const [cidade, setCidade] = useState ('')
    const [estado, setEstado] = useState ('alagoas')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovoResponsavel ({
            exibir: false
        }))
    }

    const cpfChange = (e: any) => {
        
        const { value } = e.target

        setCpf (cpfCnpjMascara(value))
    }

    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    const cepChange = (e: any) => {
        
        const { value } = e.target

        setCep (cepMascara(value))
    }

    const cpfBanco = async () => {

        try {

            const validarCnpjCpf = validarCpfCnpj (cpf)

            if (validarCnpjCpf === false) {
                setAlertaCpfCnpjInvalido (true)
                setCpf ('')

                return setTimeout (() => {
                    setAlertaCpfCnpjInvalido (false)
                }, 5000)
            }
                
            const resposta = await listaDeResponsaveis ()

            for (let index = 0; index < resposta.data.length; index++) {
                if (cpf === resposta.data[index].cpf) {
                    setAlertaCpfCnpjCadastrado (true)
                    setCpf ('')

                    return setTimeout (() => {
                        setAlertaCpfCnpjCadastrado (false)
                    }, 5000)
                }
            }
    
        } catch (error) {
            return alert (error)
        }
    }


    const novoResponsavel = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        if (dataNascimento === undefined) {
            return
        }

        const payload = {
            cpf,
            nome: nomeCompleto,
            email,
            telefone,
            data_nascimento: dataNascimento,
            rua,
            numero,
            bairro,
            complemento,
            cep,
            cidade,
            estado
        }

        try {
            const resposta = await cadastrarResponsavel(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2500)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 2000)
        }
    }


    return (
        <div id = 'fundo_modal_novo_responsavel'>
            {alertaCpfCadastrado && <Alerta texto='CPF já cadastrado'/>}
            {alertaCpfInvalido && <Alerta texto='CPF Inválido'/>}
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionar_tarefa">
                <div id="tarefa_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Novo Responsável</p>
                </div>

                <div id="tarefa_dados">
                    <form id = 'form_novo_cliente' onSubmit={novoResponsavel}>        
                        <div id="tarefa_dados">
                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>CPF</p>
                                    <input 
                                        type = 'text'
                                        id = 'input_cpf_responsavel'
                                        maxLength={16}
                                        minLength ={16}
                                        value = {cpf}
                                        required
                                        onChange={cpfChange}
                                        onBlur={cpfBanco}/>
                                </span>

                                <span>
                                    <p>Nome Completo</p>
                                    <input
                                        id = 'input_nome_responsavel' 
                                        type="text"
                                        onChange={(e) => setNomeCompleto(e.target.value.toLowerCase())}
                                        required/>
                                </span>
                            </div> 
                        </div>

                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Telefone</p>
                                    <input
                                        id = 'input_telefone_novo_responsavel' 
                                        type="text"
                                        minLength={18}
                                        maxLength={18}
                                        value={telefone}
                                        required
                                        onChange={telefoneChange}/>
                                </span>

                                <span>
                                    <p>E-mail</p>
                                    <input
                                        id = 'input_email_novo_responsavel' 
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Data Nascimento</p>
                                    <input
                                        id = 'input_data_nascimento_novo_responsavel' 
                                        type="date"
                                        onChange={(e) => setDataNascimento(new Date (e.target.value))}
                                        required/>
                                </span>
                            </div>
                        </div>
                        
                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Rua</p>
                                    <input
                                        id = 'input_rua_novo_responsavel' 
                                        type="text"
                                        onChange={(e) => setRua(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Número</p>
                                    <input
                                        id = 'input_numero_novo_responsavel' 
                                        type="text"
                                        onChange={(e) => setNumero(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Bairro</p>
                                    <input
                                        id = 'input_bairro_novo_responsavel' 
                                        type="text"
                                        onChange={(e) => setBairro(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Complemento</p>
                                    <input
                                        id = 'input_complemento_novo_responsavel' 
                                        type="text"
                                        onChange={(e) => setComplemento(e.target.value.toLowerCase())}/>
                                </span>
                            </div>
                        </div>

                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>CEP</p>
                                    <input
                                        id = 'input_cep_novo_responsavel' 
                                        type="text"
                                        minLength={11}
                                        maxLength={11}
                                        value={cep}
                                        onChange = {cepChange}
                                        required/>
                                </span>

                                <span>
                                    <p>Cidade</p>
                                    <input
                                        id = 'input_cidade_novo_responsavel' 
                                        type="text"
                                        onChange={(e) => setCidade(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Estado</p>
                                    <select id="input_estado_novo_responsavel"  value = {estado} onChange = {(e) => setEstado(e.target.value)} required>
                                        <option value = 'acre'>ACRE</option>
                                        <option value= 'alagoas'>ALAGOAS</option>
                                        <option value = 'amapá'>AMAPÁ</option>
                                        <option value = 'amazonas'>AMAZONAS</option>
                                        <option value = 'bahia'>BAHIA</option>
                                        <option value = 'ceará'>CEARÁ</option>
                                        <option value = 'espírito santo'>ESPÍRITO SANTOS</option>
                                        <option value = 'goiás'>GOIÁS</option>
                                        <option value = 'maranhão'>MARANHÃO</option>
                                        <option value = 'mato grosso'>MATO GROSSO</option>
                                        <option value = 'mato grosso do sul'>MATO GROSSO DO SUL</option>
                                        <option value = 'minas gerais'>MINAS GERAIS</option>
                                        <option value = 'pará'>PARÁ</option>
                                        <option value = 'paraíba'>PARAÍBA</option>
                                        <option value = 'paraná'>PARANÁ</option>
                                        <option value = 'pernanbuco'>PERNAMBUCO</option>
                                        <option value = 'piauí'>PIAUÍ</option>
                                        <option value = 'rio de janeiro'>RIO DE JANEIRO</option>
                                        <option value = 'rio grande do norte'>RIO GRANDE DO NORTE</option>
                                        <option value = 'rio grande do sul'>RIO GRANDE DO SUL</option>
                                        <option value = 'rondônica'>RONDÔNIA</option>
                                        <option value = 'roraima'>RORAIMA</option>
                                        <option value = 'santa catarina'>SANTA CATARINA</option>
                                        <option value = 'são paulo'>SÃO PAULO</option>
                                        <option value = 'sergipe'>SERGIPE</option>
                                        <option value = 'tocantins'>TOCANTINS</option>
                                    </select>
                                </span>
                            </div>
                        </div>

                        <div id="novo_cliente_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovoResponsavel