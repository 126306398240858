import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalVisualizarDadosVendasReduce = createSlice ({
    name: 'modal_visualizar_dados_venda',
    initialState: {
        exibir: false,
        id: 0
    } as ModalEstado,
    reducers: {
        setExibirModalDadosVenda (state, action) {
            Object.assign (state, {
                exibir: true,
                id: action.payload.id
            })
        },

        setOcultarModalDadosVenda (state, action) {
            Object.assign (state, {
                exibir: false,
                id: 0
            })
        }
    }
})

export const { setExibirModalDadosVenda, setOcultarModalDadosVenda } = modalVisualizarDadosVendasReduce.actions

export default modalVisualizarDadosVendasReduce.reducer