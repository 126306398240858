import { createSlice } from "@reduxjs/toolkit"
import { bugsListaRequisicaoPayload } from "../../../interfaces/bugs"

const filtroBugsReduce = createSlice ({
    name: 'filtros_bugs',
    initialState: {
        termo: '',
        status:''
    } as bugsListaRequisicaoPayload,
    reducers: {
        setFiltrosBugs (state, action) {
            Object.assign (state, {
                termo: action.payload.termo,
                status: action.payload.status
            })
        }
}})

export const { setFiltrosBugs } = filtroBugsReduce.actions

export default filtroBugsReduce.reducer