import { FormEvent, useState, useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { setFiltrosProcessosAndamento } from '../../store/modules/filtrosProcessosAndamento'
import { setExibirModalNovoProcesso } from '../../store/modules/modalNovoProcesso'
import usuarioLogado from '../../services/hg/usuarios'
import { usuariosAtivos } from '../../services/hg/usuarios'
import Perfil from '../../middleware/perfil'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './filtrosprocessosandamento.css'

function FiltrosProcessosAndamento () {

    const [situacao, setSituacao] = useState ('ativo')
    const [titulo, setTitulo] = useState('')
    const [usuarios, setUsuario] = useState<UsuariosAtivos []> ([])
    const [responsavel, setResponsavel] = useState ('')
    const dispatch = useDispatch()
    const navigate = useNavigate ()

    function exibirModal () {
        dispatch (setExibirModalNovoProcesso ({
            exibir: true
        }))
    }

    function filtrosModelosProcessos (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosProcessosAndamento ({
            titulo,
            situacao,
            responsavel
        }))
    }

    useEffect (() => {

        const responsavelAtivos = async () => {
            
            try {
                const resposta = await usuariosAtivos ()

                setUsuario (resposta.data)

            } catch (error) {
                
            }
        }

        responsavelAtivos ()

    }, [])

    useEffect (() => {
        
        const usuarioSessao = async () => {
            
            try {
                
                const resposta = await usuarioLogado ()
                
                if (resposta.data.perfil !== 'master') {
                    
                    dispatch (setFiltrosProcessosAndamento ({
                        titulo: titulo,
                        situacao: situacao,
                        responsavel: resposta.data.id
                    }))
                }
                
            } catch (error) {
                alert ('Falha ao Obter as Suas Credenciais')
                return navigate ('/')
            }
        }
        
        usuarioSessao ()
        
    }, [titulo, situacao, responsavel, dispatch, navigate])

    useEffect (() => {

        const usuarioSessao = async () => {

            try {
                
                const resposta = await usuarioLogado ()

                if (resposta.data.perfil !== 'master') {

                    dispatch (setFiltrosProcessosAndamento ({
                        titulo: '',
                        situacao: 'ativo',
                        responsavel: resposta.data.id
                    }))
                }

            } catch (error) {
                alert ('Falha ao Obter as Suas Credenciais')
                return navigate ('/') 
            }
        }

        usuarioSessao ()

    }, [])

    return (
        <div id = 'filtro'>
            <form id = 'lado_esquerdo_assinatura' onSubmit={filtrosModelosProcessos}>
                <div id = 'caixa_pesquisa_assinatura'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_assinatura' 
                        placeholder="busque pelo nome fantasia do cliente"
                        onChange={(e) => setTitulo(e.target.value)}/>
                    <img id = 'icone_pesquisa_assinatura' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>

                <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value.replace(/ /g, ""))}>
                    <option id = 'situacao' value = ''>TODOS</option>
                    <option id = 'situacao' value = 'ativo'>ATIVOS</option>
                    <option id = 'situacao' value = 'concluído'>CONCLÚIDOS</option>
                    <option id = 'situacao' value = 'cancelado'>CANCELADOS</option>
                </select>

                <Perfil perfil={['master']}>
                    <div id = 'input_responsavel_tarefa'>
                        <label>RESPONSÁVEL</label>
                        <select className = "select_responsavel_tarefa" value={responsavel} onChange={(e) => setResponsavel(e.target.value)}>
                            <option id = 'situacao' value = ''>TODOS</option>
                            {usuarios.map ((usuario) => (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                </Perfil>

                <Btn id = 'pesquisar_boleto' titulo='PESQUISAR' cor='novo'/>
            </form>
            <div id = 'lado_direiro_assinatura'>
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <Btn id = 'nova_assinatura' titulo='NOVO PROCESSO' cor='novo' acao={exibirModal} />
                </Perfil>
            </div>
        </div>
    )
}

export default FiltrosProcessosAndamento