import { useDispatch } from 'react-redux'
import { useState, FormEvent, useEffect } from 'react'
import { setOcultarModalNovaSugestao } from '../../store/modules/modalNovaSugestao'
import setoresListaPayload from '../../interfaces/setoresListaPayload'
import sugestoesCadastrar from '../../services/hg/sugestoesCadastrar'
import listaDeSetores from '../../services/hg/setorLista'
import setorId from '../../services/hg/setorId'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './sugestaomelhoria.css'

function ModalNovaSugestaoDeMelhoria () {

    const dispatch = useDispatch ()
    const [titulo, setTitulo] = useState('')
    const [setor, setSetor] = useState(0)
    const [descricao, setDescricao] = useState('')
    const [setorLista, setSetorLista] = useState<setoresListaPayload []>([])
    const [setorTermo, setSetorTermo] = useState ('')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)

    function ocultarModal () {
        
        dispatch (setOcultarModalNovaSugestao ({
            exibir: false
        }))
    }



    const novaSugestao = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            titulo,
            setor_id: setor,
            descricao,
        }

        try {
            const resposta = await sugestoesCadastrar(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                console.log (resposta)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()
                }, 3000)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (true)
            return alert ('Erro ao salvar os dados. Tente novamente')
        }
    }

    useEffect (() => {

        const listaSetores = async () => {
        
            try {
                const resposta = await listaDeSetores()
                setSetorLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de setores')
            }
        }

        listaSetores ()

    }, [])

    useEffect (() => {
        
        const setorIdDb = async () => {

            const termo = setorTermo

            try {

                const resposta = await setorId(termo)
                return setSetor(resposta.data[0].id)
            
            } catch (error) {
                return alert ('Falha ao recuperar o id do setor')    
            }
        }

        setorIdDb ()

    }, [setorTermo])

    return (
        <div id = 'fundo_modal_nova_sugestao'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {salvarDados && <div id="adicionar_sugestao">
                <div id="sugestao_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Nova Sugestão</p>
                </div>

                <div id="sugestao_dados">
                    <form id = 'form_nova_sugestao' onSubmit={novaSugestao}>        
                        <div id="sugestao_dados">
                            <div id = 'campo_sugestao'>
                                <p>Título</p>
                                <input 
                                    id = 'sugestao_titulo_novo' 
                                    type="nome" maxLength={100} 
                                    required
                                    onChange={(e) => setTitulo (e.target.value)}/>
                            </div>
                            <div id = 'campo_sugestao'>
                                <p>Setor</p>
                                <select id = 'sugestao_setor' value={setorTermo} onChange={(e) => setSetorTermo (e.target.value)}>
                                    <option>SELECIONE O SETOR</option>
                                    {setorLista.map ((setor) => (
                                        <option key={Math.random()}>{setor.nome.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div>  
                        </div>

                        <div id="sugestao_dados">
                            <div id ='campo_sugestao'>
                                <p>Descrição</p>
                                <textarea 
                                    name="descricao" 
                                    id="descricao_sugestao" rows={10} 
                                    placeholder="Descreva com riqueza de detalhes a sua sugestão de melhoria" 
                                    onChange={(e) => setDescricao (e.target.value)}/>
                            </div>
                        </div>

                        <div id="sugestao_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_sugestao' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_sugestao' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovaSugestaoDeMelhoria