import { Navigate } from "react-router-dom"
import propsComponente from "../../interfaces/propsComponente"
import { logado } from "../Token"


const ComponenesPrivados = ( props: propsComponente)=> {

    const usuario = logado ()

    if (!usuario) {
        return <Navigate to = '/'></Navigate>
    }
    
    return props.children

}

export default ComponenesPrivados