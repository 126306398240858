import divSituacaoProps from '../../interfaces/divSituacaoProps'
import { DivSituacaoDefault } from '../Styles'

function DivSituacao (props: divSituacaoProps) {
    return (
        <DivSituacaoDefault cor = {props.cor}>
            {props.texto}
        </DivSituacaoDefault>
    )
}

export default DivSituacao