import { createSlice } from "@reduxjs/toolkit"
import { listaProcessosFiltrosPayload } from "../../../interfaces/processos"

const filtroProcessosAndamentoReduce = createSlice ({
    name: 'filtrosProcessosAndamento',
    initialState: {
        titulo: '',
        perfil: '',
        situacao: 'ativo',
        responsavel: ''
    } as listaProcessosFiltrosPayload,
    reducers: {
        setFiltrosProcessosAndamento (state, action) {
            Object.assign (state, {
                titulo: action.payload.titulo,
                perfil: action.payload.perfil,
                situacao: action.payload.situacao,
                responsavel: action.payload.responsavel
            })
        }
}})

export const { setFiltrosProcessosAndamento } = filtroProcessosAndamentoReduce.actions

export default filtroProcessosAndamentoReduce.reducer