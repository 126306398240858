import baseApiHg from "../api/config"
import { modelosProcessosCadastrarPayload, modelosProcessosAtualizarPayload, modelosProcessosFiltrosPayload } from "../../../interfaces/modelosProcessos"

export function novoModeloProcesso (payload: modelosProcessosCadastrarPayload) {
    return baseApiHg.post('/modelosProcessos', payload)
}

export function listaModelosProcessos (payload: modelosProcessosFiltrosPayload) {
    return baseApiHg.get(`/modelosProcessos?situacao=${payload.situacao}&titulo=${payload.titulo}`)
}

export function listaModelosProcessosAtivos () {
    return baseApiHg.get('/modelosProcessosAtivos')
}

export function atualizarModeloProcesso (id: string, payload: modelosProcessosAtualizarPayload) {
    return baseApiHg.patch(`/modelosProcessos?id=/${id}`, payload)
}