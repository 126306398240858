import { createSlice } from "@reduxjs/toolkit"
import { modelosProcessosFiltrosPayload } from "../../../interfaces/modelosProcessos"

const filtroModelosProcessosReduce = createSlice ({
    name: 'filtrosModelosProcessos',
    initialState: {
        titulo: '',
        situacao: '',
    } as modelosProcessosFiltrosPayload,
    reducers: {
        setFiltrosModelosProcessos (state, action) {
            Object.assign (state, {
                titulo: action.payload.titulo,
                situacao: action.payload.situacao,
            })
        }
}})

export const { setFiltrosModelosProcessos } = filtroModelosProcessosReduce.actions

export default filtroModelosProcessosReduce.reducer