import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { listaTarefas } from '../../services/hg/tasks'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuTarefas (props: menuOpcoes) {

    const filtrosTarefas = useSelector ((store: RootStore) => store.filtrosTarefas)

    const perfil = localStorage.getItem('perfilHg')
    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [subMenu, setSubMenu] = useState(false)
    const [notificacoesTotais, setNotificacoesTotais] = useState (0)
    const [notificacoesTarefas, setNotificacoesTarefas] = useState(0)
    const [notificacoesFlowup, setNotificacoesFlowup] = useState (0)
    const [notificacoesChamado, setNotificacaoChamado] = useState(0)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }


    useEffect (() => {

        const tarefas = async () => {

            const paramsTarefas = {
                tipo: 'tarefa',
                perfil: perfil
            }

            const paramsFlowup = {
                tipo: 'flowup',
                perfil: perfil
            }

            const paramsChamado = {
                tipo: 'chamado',
                perfil: perfil
            }

            const payload = {
                dataInicial: filtrosTarefas.dataInicial,
                dataFinal: filtrosTarefas.dataFinal,
                situacao: filtrosTarefas.situacao,
                responsavel: filtrosTarefas.responsavel,
                perfil: perfil,
            }

            if (perfil !== 'master') {
                Object.assign (payload, {
                        dataInicial: filtrosTarefas.dataInicial,
                        dataFinal: filtrosTarefas.dataFinal,
                        situacao: filtrosTarefas.situacao,
                        responsavel: idUsuario,
                        perfil: perfil,
                    }
                )
            }


            try {
                const respostaTarefas = await listaTarefas (paramsTarefas, payload)
                const respostaFlowup = await listaTarefas (paramsFlowup, payload)
                const respostaChamado = await listaTarefas (paramsChamado, payload)

                if (respostaTarefas.status === 200) {
        
                    setNotificacoesTotais (respostaTarefas.data.length + respostaFlowup.data.length + respostaChamado.data.length)

                    setNotificacoesTarefas (respostaTarefas.data.length)

                    setNotificacoesFlowup (respostaFlowup.data.length)

                    setNotificacaoChamado (respostaChamado.data.length)

                    return

                }

            } catch (error) {

            }
        }

        tarefas ()

    }, [filtrosTarefas, idUsuario, perfil])

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                <span>
                    {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                    {props.titulo}
                </span>
                <h3>{notificacoesTotais === 0 ? null : notificacoesTotais.toString().padStart(2,'0')}</h3>
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = {`/tarefa/${perfil}`} texto = 'Tarefas' notificacao={notificacoesTarefas === 0 ? null : notificacoesTarefas} />}
                {subMenu && <SubMenuOpcoes redirect = {`/flowup/${perfil}`} texto = 'FlowUps' notificacao={notificacoesFlowup === 0 ? null : notificacoesFlowup}/>}   
                {subMenu && <SubMenuOpcoes redirect = {`/chamado/${perfil}`} texto = 'Chamados' notificacao={notificacoesChamado === 0 ? null : notificacoesChamado}/>}
            </div>
        </div>
    )
}

export default MenuTarefas