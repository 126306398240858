import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import ModalNovoProblema from "../../components/ModalNovoProblema";
import FiltrosBug from "../../components/FiltrosBug";
import ListaDeBugs from "../../components/ListaDeBugs";


function Bug() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovoProblema)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Reportar Problema'
    }, [])

    return (
        <DivDados id = 'dados_bug_fundo'>
            <>
                { modal && <ModalNovoProblema />}
                <FiltrosBug />
                <ListaDeBugs />
            </>
        </DivDados>
    )
  }
  
export default Bug;