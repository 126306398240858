import baseApiHg from "../api/config"
import { receitaRequisicaoPaylod, receitasAtualizarPayload } from "../../../interfaces/receitas"

export function listaReceitas (payload: receitaRequisicaoPaylod) {
    return baseApiHg.get(`/receitas?dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}&situacao=${payload.situacao}&cliente=${payload.cliente}`)
}

export function detalhesReceita (idReceita: number) {
    return baseApiHg.get(`/receitas/${idReceita}`)
}

export function receitasCliente (idCliente: any ) {
    return baseApiHg.get(`/receitas/cliente/${idCliente}`)
}

export function receitaBoletoPdf (idReceita: number) {
    return baseApiHg.get(`/receitas/${idReceita}`)
}

export function receitaAtualizar (idReceita: number, payload: receitasAtualizarPayload) {
    return baseApiHg.patch(`/receitas/${idReceita}`, payload)
}

export function cobrancaWhatsapp (idReceita: number) {
    return baseApiHg.post(`/receitas/whatsapp/${idReceita}`)
}

export function linkPagamento (idReceita: number) {
    return baseApiHg.post(`/receitas/link/${idReceita}`)
}