import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import { clientesAtivosMes } from '../../services/hg/graficos'
import Perfil from '../../middleware/perfil'
import CarregandoPontinhos from '../CarregandoPontinhos'
import './graficoclientesativos.css'

function GraficoClientesAtivosoMes () {

    const filtrosFinanceiro = useSelector ((store: RootStore) => store.filtrosFinanceiro)
    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState ([])

    useEffect (() => {

        const graficoClienteAtivoMes = async () => {

            try {
                const resposta = await clientesAtivosMes ()
                setDados (resposta.data)
                setCarregando (false)
                setGrafico (true)

            } catch (error) {
                return alert (error)
            }
        }

        graficoClienteAtivoMes ()

    }, [filtrosFinanceiro])


    return (

        <Perfil perfil={['master']}>
            <div id = 'grafico_clientes_ativos'>
                <p id = 'p_clientes_ativos'>CLIENTES ATIVOS</p>
                {carregando && <CarregandoPontinhos cor ='pontinhosFundoBranco' />}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="clientes_ativos_mes" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#00A6A6" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#00A6A6" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="período" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="6 6" />
                    <Tooltip />
                    <Area type="monotone" dataKey="clientes ativos" strokeWidth={2} stroke="#008000"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#clientes_ativos_mes)" />
                    </AreaChart>
                </ResponsiveContainer>}
            </div>
        </Perfil>
        
    )
}

export default GraficoClientesAtivosoMes