import { FormEvent, useState, useEffect } from 'react'
import { setExibirModalRecuperarSenha } from '../../store/modules/modalRecuperarSenha'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import login from '../../services/hg/auth'
import ModalRecuperarSenha from '../ModalRecuperarSenha'
import logoHgImage from  '../../assets/img/logo_preto.png'
import emailIcone from '../../assets/img/email.png'
import cadeadoIcone from '../../assets/img/cadeado.png'
import BtnEntrar from '../btnEntrar'
import CarregandoCirculoBtn from '../CarregandoCirculoBtn'
import DadosInvalidosLogin from '../DadosInvalidosLogin'
import './login.css'


function Login () {

    const modalEstado = useSelector ((store: RootStore) => store.modalRecuperarSenha)
    const dispatch = useDispatch ()
    const [email, setEmail] = useState <string> ('')
    const [senha, setSenha] = useState <string> ('')
    const [carregando, setCarregando] = useState(false)
    const [entrar, setEntrar] = useState (true)
    const [dadosInvalidos, setDadosInvalidos] = useState(false)
    const [modal, setModal] = useState(false)

    function exibirModal () {
        dispatch (setExibirModalRecuperarSenha ({
            exibir: true
        }))
    }

    const auth = async (e: FormEvent) => {
        e.preventDefault()
    
        const payload = {
            email,
            senha
        }

        setCarregando (true)
        setEntrar (false)

        try {
            const resposta = await login (payload)

            if (resposta.status === 200 && resposta.data.perfil === 'master') {
                const token = resposta.data.token
                document.cookie = `authHg=${token}`

                localStorage.setItem("perfilHg", `${resposta.data.perfil}`)
                localStorage.setItem("idUsuarioLogadoHg", `${resposta.data.id}`)
                
                return window.location.href = '/dashboard'
            } 

            if (resposta.status === 200 && resposta.data.perfil !== 'master') {
                const token = resposta.data.token
                document.cookie = `authHg=${token}`

                localStorage.setItem("perfilHg", `${resposta.data.perfil}`)
                localStorage.setItem("idUsuarioLogadoHg", `${resposta.data.id}`)
                
                return window.location.href = '/meupainel'
            }

            setCarregando (false)
            setEntrar (true)

        } catch (error: any) {
            setCarregando (false)
            setEntrar (true)

            if (error.response.status === 0) {
                return alert ('Servidor Indisponível')
            }

            if (error.response.status === 400 || 401) {
                return setDadosInvalidos (true)
            }
            return alert ('Erro ao processar a sua solicitação')
        }
    }

    useEffect (() => {

        setModal (modalEstado.exibir)

    }, [modalEstado])

   
    return (
        <div id ="fundo_login">
            <img id = 'logoHg' src= {logoHgImage} alt = 'Logo do Hg Consultoria'></img>
            {dadosInvalidos && <DadosInvalidosLogin />}
            {modal && <ModalRecuperarSenha />}
            <div className = 'formulario'>
                <div id = 'formulario'>
                    <form onSubmit= {auth}>
                        <div id = 'usuario'>
                            <input 
                                type = 'email' 
                                id = 'campo' 
                                className = 'email' 
                                placeholder="e-mail" 
                                required
                                value = {email}
                                onChange = {(e) => setEmail (e.target.value)}
                                onFocus = {(e) => setDadosInvalidos(false)}>
                            </input>
                            <img id = 'email' src= {emailIcone} alt = 'icone de e-mail'></img>
                        </div>
                        
                        <div id = 'senha'>
                            <input 
                                type = 'password' 
                                id = 'campo' 
                                className = 'senha' 
                                placeholder="insira sua senha" 
                                minLength= {8} 
                                maxLength= {8} 
                                required
                                value = {senha}
                                onChange = {(e) => setSenha (e.target.value)}
                                onFocus = {(e) => setDadosInvalidos(false)}>
                            </input>
                            <img id = 'cadeado' src= {cadeadoIcone} alt = 'icone da senha protegida'></img>
                        </div>
                        
                        
                        <div className = 'entrar'>
                            <p id = 'recuperar_senha' onClick={() => exibirModal()}>Esqueci minha senha</p>
                            <span>
                                {carregando && <CarregandoCirculoBtn />}
                                {entrar && <BtnEntrar />}
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login