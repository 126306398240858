import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuConfiguracoes (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                {props.titulo}
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Dados da Empresa' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Usuários' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Modelos de Contrato' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Plano de Contas' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Contas Bancárias' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Formas de Pagamento' />}    
            </div>        
        </div>
    )
}

export default MenuConfiguracoes