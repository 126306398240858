import { FormEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setFiltrosLeads } from '../../store/modules/filtrosLeads'
import { setExibirModalNovoLead } from '../../store/modules/modalNovoLead'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './filtrosleads.css'

function FiltrosLeads () {

    const [situacao, setSituacao] = useState ('em negociação')
    const [nome, setNome] = useState('')
    const dispatch = useDispatch()

    function exibirModal () {
        dispatch (setExibirModalNovoLead ({
            exibir: true
        }))
    }

    function filtrosLeads (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosLeads ({
            nome,
            situacao,
        }))
    }

    return (
        <div id = 'filtro'>
            <form id = 'lado_esquerdo_assinatura' onSubmit={filtrosLeads}>
                <div id = 'caixa_pesquisa_assinatura'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_assinatura' 
                        placeholder="busque pelo nome do lead ou da empresa"
                        onChange={(e) => setNome(e.target.value)}/>
                    <img id = 'icone_pesquisa_assinatura' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>

                <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                    <option id = 'situacao' value = ''>TODOS</option>
                    <option id = 'situacao' value = 'em negociação'>EM NEGOCIAÇÃO</option>
                    <option id = 'situacao' value = 'conquistado'>CONQUISTADO</option>
                    <option id = 'situacao' value = 'perdido'>PERDIDO</option>
                </select>

                <Btn id = 'pesquisar_boleto' titulo='PESQUISAR' cor='novo'/>
            </form>
            <div id = 'lado_direiro_assinatura'>
                <Btn id = 'nova_assinatura' titulo='NOVO LEAD' cor='novo' acao={exibirModal} />
            </div>
        </div>
    )
}

export default FiltrosLeads