import { useState } from 'react'
import alertaProps from '../../interfaces/propsAlerta'
import './alerta.css'

function Alerta (props: alertaProps) {

    const [alerta, setAlerta] = useState (true)

    function ocultarAlerta () {
        setAlerta (false)
    }

    return (
        <>
            {alerta && <span id = 'alerta'>
                <span>
                    <p>{props.texto}</p>
                    <p id = 'p_fechar_alerta' onClick={() => ocultarAlerta()}>X</p>
                </span>
            </span>}
        </>
    )
}

export default Alerta