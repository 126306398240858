import axios  from 'axios'


const consultaDadosCnpj = axios.create ({
    baseURL: process.env.REACT_APP_API_CNPJ,
    headers: {
        'Content-Type': 'application/json'
    }

})

export default  consultaDadosCnpj