import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { assinaturasRespostaPayload, ocorrenciaAssinaturaResposta } from "../../interfaces/assinaturas"
import { assinaturaId, removerItemAssinatura, atualizarItemAssinatura } from "../../services/hg/assinaturas"
import { setAtualizarDados } from "../../store/modules/atualizarDados"
import { ocorrenciaAssinatura } from "../../services/hg/assinaturas"
import { RootStore } from "../../store"
import excluiImg from '../../assets/img/excluir.png'
import editarImg from '../../assets/img/editar.png'
import CarregandoCirculo from "../CarregandoCirculo"
import ConfirmacaoOperacao from "../ConfirmacaoOperacao"
import ErrorOperacao from "../ErroOperacao"
import DivSituacao from "../DivSituacao"
import Btn from "../Btn"
import "./assinaturadados.css"

function AssinaturaDados () {

    const params = useParams()
    const navegate = useNavigate()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()

    const [assinatura, setAssinatura] = useState<assinaturasRespostaPayload> ()
    const [ocorrenciasAssinatura, setOcorrenciaAssinatura] = useState <ocorrenciaAssinaturaResposta []> ([])
    const [carregando, setCarregando] = useState(false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erro, setErro] = useState (false)

    const voltar = () => {
        return navegate ('/assinaturas')
    }

    async function removerItem (idAssinatura: number, idItem: number) {

        setCarregando (true)

        try {

            const resposta =  await removerItemAssinatura (idAssinatura, idItem)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                setTimeout (() => {
                    setConfirmacao (false)
                }, 3000)

                dispatch (setAtualizarDados ({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))

            }
        } catch (error) {
            setCarregando (false)
            setErro (true)

            return setTimeout (() => {
                setErro (false)
            }, 3000)
        }
    }

    async function atualizarItem (idAssinatura: number, idItem: number) {
        const novoValor = window.prompt ('Qual o novo valor?')

        if (novoValor === null || novoValor === undefined) {
            return
        }
        
        setCarregando (true)

        try {

            const payload = {
                idItem,
                idAssinatura,
                novoValorItem: novoValor.replace(',', '.')
            }

            const resposta =  await atualizarItemAssinatura (payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                setTimeout (() => {
                    setConfirmacao (false)
                }, 3000)

                dispatch (setAtualizarDados ({
                    atualizar: (atualizarDados.atualizar === true ? false : true)
                }))

            }

        } catch (error) {
            setCarregando (false)
            setErro (true)

            return setTimeout (() => {
                setErro (false)
            }, 3000)
        }

    }
    
    useEffect (() => {

        const { idAssinatura } =  params

        const clienteDados = async () => {

            
            try {

                if (idAssinatura === null || idAssinatura === undefined) {
                    return
                }
    
                const resposta = await assinaturaId (idAssinatura)
                setAssinatura (resposta.data)
                setCarregando (false)
                
            } catch (error) {
    
                return alert ('Falha ao Recuperar os Dados da Assinatura')
            }
        }

        clienteDados ()

    }, [params, atualizarDados])

    useEffect (() => {

        const { idAssinatura } =  params

        const assinaturaOcorrencia = async () => {

            if (idAssinatura === null || idAssinatura === undefined) {
                return
            }

            try {
                
                const resposta = await ocorrenciaAssinatura (parseInt(idAssinatura))

                if (resposta.status === 200) {
                    setOcorrenciaAssinatura (resposta.data)
                }

            } catch (error) {

                return alert (error)
            }
        }

        assinaturaOcorrencia ()

    }, [params])

  return (
    <div id="assinatura_cliente_dados">
        <div id="informacoes_assinatura">
            <p>Histório da Assinatura</p>
            <Btn id="Voltar_assinaturas" titulo="VOLTAR" acao={voltar} cor="cancelar"/>
        </div>
        <div id="assinatura_cliente_dados">
            {confirmacao && <ConfirmacaoOperacao  texto="Assinatura Atualizada com Sucesso"/>}
            {erro && <ErrorOperacao texto="Falha ao Atualizar os Dados" />}
            {carregando && <CarregandoCirculo />}
            <div id = 'assinatura_dados_cadastrais'>
                <div>
                    <span>
                        <label>ID</label>
                        <input type="text" value={(`00000${assinatura?.id}`).slice(-5)} readOnly disabled/>
                    </span>
                    <span>
                        <label>Cliente</label>
                        <input type="text" defaultValue={assinatura?.cliente.nome_fantasia.toUpperCase()} />
                    </span>
                    <span>
                        <label>Dia da Cobrança</label>
                        <input type="text" value={(`0${assinatura?.dia_cobranca}`).slice(-2) + ` DE CADA MÊS`} />
                    </span>
                    <span>
                        <label>Situação</label>
                        <input type="text" defaultValue={assinatura?.situacao.toUpperCase()} />
                    </span>
                </div>

                <div id = 'itens_assinatura'>
                    <p id = 'p_itens_assinatura'>Itens da Assiantura</p>
                    <section id = 'itens_assinaturas'>
                        <table>
                            <thead>
                                <tr>
                                    <td id = 'item_assinatura_titulo'>SERVIÇOS</td>
                                    <td align="center">VALOR</td>
                                    <td align="center">AÇÃO</td>
                                </tr>
                            </thead>

                            <tbody>
                                {assinatura?.assinaturas_itens.map((item) => (
                                    <tr key={Math.random()}>
                                        <td id = 'item_assinatura_titulo'>{item.servico.nome.toUpperCase()}</td>
                                        <td align="center">R$ {item.valor === undefined ? '' : item.valor.replace('.',',')}</td>
                                        <td align="center">
                                            <img title="Atualizar Valor do Item" id = 'itemAtualizarValor' src = {editarImg} alt = '' onClick={() => atualizarItem(assinatura.id, item.id)}/>
                                            <img title="Excluir Item da Assinatura" id = 'iten_excluir_assinatura'  src = {excluiImg} alt = '' onClick={() => removerItem (assinatura.id, item.id)}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                    <p id = 'p_itens_assinatura'>{`Valor Total: R$ ` + assinatura?.valor_total.toString().replace('.',',')}</p>
                </div>
            </div>
            <div id = 'ocorrencias_assinaturas'>
                <table id = 'tb_ocorrencias_assinaturas'>
                    <thead >
                        <tr id = 'cabecalho_ocorrencia_assinatura'>
                            <th align="left" id = 'id_assinatura_ocorrencia'>VENDA ID</th>
                            <th align="left">DESCRIÇÃO</th>
                            <th align="center">VALOR</th>
                            <th align="center">VENCIMENTO</th>
                            <th align="center">SITUAÇÃO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ocorrenciasAssinatura.map ((ocorrencia) => (    
                            <tr key={Math.random()}>
                                <td id = 'id_assinatura_ocorrencia'>{ocorrencia.id.toString().padStart(5,'0')}</td>
                                <td>{ocorrencia.descricao.toUpperCase()}</td>
                                <td align="center">R$ {ocorrencia.receitas.map((valor) => (valor.valor_total.replace('.',',')))}</td>
                                <td align="center">{`${ocorrencia.receitas.map((vencimento) => vencimento.vencimento.slice(8,10))}/${ocorrencia.receitas.map((vencimento) => vencimento.vencimento.slice(5,7))}/${ocorrencia.receitas.map((vencimento) => vencimento.vencimento.slice(0,4))}`}</td>
                                <td align="center">
                                    <DivSituacao texto = {ocorrencia.receitas.map((situacao) => situacao.situacao.toUpperCase()).toString()} cor = {ocorrencia.receitas.map((situacao) => situacao.situacao.toLowerCase()).toString()}/>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}

export default AssinaturaDados
