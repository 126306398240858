import { createSlice } from "@reduxjs/toolkit"
import notificacoes from "../../../interfaces/notificacoes"

const notificacoesReduce = createSlice ({
    name: 'notificacoes',
    initialState: {
        quantidadeTotal: 0,
        quantidadeTarefa: 0,
        quantidadeFlowup: 0,
        quantidadeChamado: 0,
        quantidadeAtendimento: 0,
        quantidadeAndamentoAndamento: 0
    } as notificacoes,
    reducers: {
        setNotificacoesTotal (state, action) {
            Object.assign (state, {
                quantidadeTotal: action.payload.quantidadeTotal
            })
        },
        setNotificacoesTarefas (state, action) {
            Object.assign (state, {
                quantidadeTarefa: action.payload.quantidadeTarefa
            })
        },
        setNotificacoesFlowup (state, action) {
            Object.assign (state, {
                quantidadeFlowup: action.payload.quantidadeFlowup
            })
        },
        setNotificacoesChamado (state, action) {
            Object.assign (state, {
                quantidadeChamado: action.payload.quantidadeChamado
            })
        },
        setNotificacoesAtendimento (state, action) {
            Object.assign (state, {
                quantidadeAtendimento: action.payload.quantidadeAtendimento
            })
        },
        setNotificacoesProcessosAndamento (state, action) {
            Object.assign (state, {
                quantidadeAndamentoAndamento: action.payload.quantidadeAtendimento
            })
        },

    }
})

export const { setNotificacoesTotal, setNotificacoesTarefas, setNotificacoesFlowup, setNotificacoesChamado, setNotificacoesAtendimento, setNotificacoesProcessosAndamento } = notificacoesReduce.actions

export default notificacoesReduce.reducer