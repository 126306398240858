import { useState, useEffect } from 'react'
import ExtratoBancario from '../../services/hg/extratoBancario'
import extratoBancarioPayload from '../../interfaces/extratoBancario'
import contasBancariasPayload from '../../interfaces/contasBancarias'
import contasBancarias from '../../services/hg/contasBancarias'
import CarregandoPontinhos from '../CarregandoPontinhos'
import './contasbancarias.css'

function ContasBancarias () {
    
    const [contas, setContas] = useState<contasBancariasPayload []> ([])
    const [saldoTotal, setSaldoTotal] = useState (0)
    const [carregando, setCarregando] = useState (true)
    const [caixaEmpresa, setCaixaEmpresa] = useState (0)
    const [cofreEmpresa, setCofreEmpresa] = useState (0)
    const [bancoBrasil, setBancoBrasil] = useState (0)
    const [bancoInter, setBancoInter] = useState (0)
    const [tbContas, setTbContas] = useState (false)
    const [extratoBancario, setExtratobancario] = useState<extratoBancarioPayload []> ([])
    const [carregandoExtrato, setCarregandoExtrato] = useState (true)
    const [tbExtrato, setTbExtrato] = useState (false)

    useEffect (() =>{
        const contasBancariasLista = async () => {

            try {
                
                const resposta = await contasBancarias ()

                if (resposta.status === 200) {
                    setContas (resposta.data)
                    setCarregando (false)
                    setTbContas (true)
                }

            } catch (error) {
                return alert (error)
            }
        }

        contasBancariasLista ()

    }, [])

    useEffect (() => {

        const extrato = async () => {

            try {

                const resposta = await ExtratoBancario ()

                if (resposta.status === 200) {
                    setCarregandoExtrato (false)
                    setTbExtrato(true)
                    setExtratobancario (resposta.data)
                }
                
            } catch (error) {
                return alert (error)
            }
        }

        extrato ()

    }, [])


    useEffect (() => {
        let caixaEmpresa = 0
        let cofreEmpresa = 0
        let bancoBrasil = 0
        let bancoInter = 0
        let saldoTotal = 0

        extratoBancario.forEach (extrato => 
            caixaEmpresa += Number (extrato.conta === 'caixa da empresa' ? extrato.valor : 0),
        )

        extratoBancario.forEach (extrato => 
            cofreEmpresa += Number (extrato.conta === 'cofre da empresa' ? extrato.valor : 0),
        )

        extratoBancario.forEach (extrato => 
            bancoBrasil += Number (extrato.conta === 'banco do brasil' ? extrato.valor : 0),
        )

        extratoBancario.forEach (extrato => 
            bancoInter += Number(extrato.conta === 'banco inter' ? extrato.valor : 0)
        )

        extratoBancario.forEach (extrato => 
            saldoTotal += Number (extrato.valor)
        )
        
        setCaixaEmpresa (caixaEmpresa)
        setCofreEmpresa (cofreEmpresa)
        setBancoBrasil (bancoBrasil)
        setBancoInter (bancoInter)
        setSaldoTotal (saldoTotal)

    }, [extratoBancario])



    return (
        <div id = 'dados_contas_bancarias'>
            <div id = 'contas_totais'>
                <span>
                    <h1>CONTAS BANCÁRIAS</h1>
                    <table id = 'tb_contas_bancarias'>
                        <thead>
                            <tr>
                                <th align='left' id = 'conta_descricao'>DESCRIÇAO</th>
                                <th align='center'>SALDO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align='left' id = 'conta_descricao'>BANCO INTER</td>
                                <td align='center'>{bancoInter.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            </tr>
                            <tr>
                                <td align='left' id = 'conta_descricao'>BANCO DO BRASIL</td>
                                <td align='center'>{bancoBrasil.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            </tr>
                            <tr>
                                <td align='left' id = 'conta_descricao'>CAIXA DA EMPRESA</td>
                                <td align='center'>{caixaEmpresa.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            </tr>
                            <tr>
                                <td align='left' id = 'conta_descricao'>COFRE DA EMPRESA</td>
                                <td align='center'>{cofreEmpresa.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                            </tr>
                            <tr id = 'saldo_bancario_total'>
                                <td><h1 id = 'h1_saldo_total'>SALDO TOTAL</h1></td>
                                <td align='center'><h1>{saldoTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h1></td>
                            </tr>
                        </tbody>
                    </table>
                </span>
            </div>
            <h1 id = 'titulo_extrato'>EXTRATO BANCÁRIO</h1>
            <div id = 'extrato_bancario'>
                <table id = 'tb_extrato_bancario'>
                    <thead>
                        <tr>
                            <th align='center'>DATA</th>
                            <th align='center'>TIPO</th>
                            <th align='left'>CLIENTE</th>
                            <th align='left'>DESCRIÇÃO</th>
                            <th align='center'>VALOR</th>
                            <th align='left'>CONTA</th>
                        </tr>
                    </thead>
                    {tbExtrato && <tbody>
                        {extratoBancario.map ((extrato) => (
                            <tr key={Math.random()} id = {'extrato' + extrato.tipo}>
                                <td align='center'>{`${extrato.data.slice(8,10)}/${extrato.data.slice(5,7)}/${extrato.data.slice(0,4)}`}</td>
                                <td align='center'>{extrato.tipo.toUpperCase()}</td>
                                <td align='left'>{extrato.cliente.toUpperCase()}</td>
                                <td align='left'>{extrato.descricao.toUpperCase()}</td>
                                <td align='center'>R$ {extrato.valor.toString().replace('.',',')}</td>
                                <td align='left'>{extrato.conta.toUpperCase()}</td>
                            </tr>
                        ))}
                    </tbody>}
                </table>
                {carregandoExtrato && <CarregandoPontinhos cor='pontinhosFundoBranco' />}
            </div>
        </div>
    )
}

export default ContasBancarias