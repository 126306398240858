import './carregandoesqueletocaixa.css'

function CarregandoEsqueletoCaixa () {
    return (
        <div id = 'div_carregando_esqueleto_caixa'>
            <span id = 'carregando_esqueleto_caixa'></span>
        </div>
    )
}

export default CarregandoEsqueletoCaixa