import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { RootStore } from '../../store'
import { listaProcessosEmAndamento } from '../../services/hg/processos'
import { listaProcessosRespostaPayload } from '../../interfaces/processos'
import { setExibirModalDadosProcessosAndamento } from '../../store/modules/modalVisualizarDadosProcessosAndamento'
import CarregandoCirculo from '../CarregandoCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ErrorOperacao from '../ErroOperacao'
import './listaprocessosandamento.css'

function ListaDeProcessosAndamento () {

    const filtrosProcessosAndamento = useSelector((store: RootStore) => store.filtrosProcessosAndamento)
    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaAssinatura)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const navegate = useNavigate ()
    const dispatch = useDispatch ()
    const { perfil } = useParams()

    const [listaProcessos, setListaProcessos] = useState<listaProcessosRespostaPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [erroOperacao, setErroOperacao] = useState(false)

    function detalhesProcesso (id: string) {

        dispatch (setExibirModalDadosProcessosAndamento ({
            exibir: true,
            id: id
        }))
    }

    useEffect (() => {
        
        const listaProcessosAndamento = async () => {

            setSolicitacao (true)

            const payload = {
                titulo: filtrosProcessosAndamento.titulo,
                situacao: filtrosProcessosAndamento.situacao,
                responsavel: filtrosProcessosAndamento.responsavel,
                perfil: perfil,
            }

            if ( perfil === undefined ) {
                return navegate ('/')
            }

            try {

                const resposta = await listaProcessosEmAndamento (payload)
                setResultadoPesquisa (false)
                setListaProcessos (resposta.data)
                setSolicitacao (false)

                if (resposta.data.length === 0) {
                    return setResultadoPesquisa (true)
                }

            } catch (error) {
                setErroOperacao (true)

                return setTimeout (() => {
                    setErroOperacao (false)
                }, 2500)

            }
        }

        listaProcessosAndamento ()

    }, [filtrosProcessosAndamento, perfil, navegate, modalEstado, atualizarDados])


    return (
        <div id = 'fundoProcessosEmAndamento'>
            {solicitacao && <CarregandoCirculo />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Recuperar os Dados' />}
            <div id = 'processosEmAndamento'>
                {listaProcessos.map( processo => (
                    <section key={processo.id} id = 'cardProcessosAndamento' onClick={() =>detalhesProcesso(processo.id)}>
                        <span id = 'processoCliente'>
                            <span>
                                <img id = 'avatarResponsavelProcesso' src={processo.usuario.avatar} alt='' />
                            </span>
                            <span>                
                            </span>
                            <span>
                                <b id ='nomeClienteProcesso'>{processo.cliente.nome_fantasia.toUpperCase()}</b>
                                <p>{processo.modelos_processo.titulo.toUpperCase()}</p>
                            </span>
                        </span>
                    </section>
                ))}
            </div>
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeProcessosAndamento