import { useEffect, useState } from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { AtendimentosRealizados } from '../../services/hg/graficos'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import CarregandoPontinhos from '../CarregandoPontinhos'
import './graficoatendimento.css'

function GraficoAtendimento () {

    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [dados, setDados] = useState ([])
    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const graficoAtendimentoRealizado = async () => {

            if (idUsuario === null || idUsuario === undefined) {
                return
            }

            try {
                const resposta = await AtendimentosRealizados (parseInt(idUsuario))
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error: any) {
                setCarregando (false)
                
                if (error.status === 401) {
                    return window.location.href = '/'
                }
                return alert (error)
            }
        }

        graficoAtendimentoRealizado ()

    }, [idUsuario])


    return (
        <div id = 'grafico_atendimento'>
            <p id = 'p_atendimento'>ATENDIMENTOS REALIZADOS</p>
            {carregando && <CarregandoPontinhos cor = 'pontinhosFundoBranco' />}
            {grafico && <ResponsiveContainer>
                <BarChart data={dados} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <XAxis dataKey="período" />
                    <YAxis />
                    <CartesianGrid strokeDasharray="3 3" />
                    <Tooltip/>
                    <Legend />
                    <Bar dataKey="atendimentos realizados" strokeWidth={1} stroke="#261132" fill= "#00A6A6"/>
                </BarChart>
            </ResponsiveContainer>}
            {semDados && <NenhumDadoGrafico texto='Você ainda não' subtexto='realizou atendimentos' />}
        </div>
    )
}

export default GraficoAtendimento