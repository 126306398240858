import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { RootStore } from "../../store"
import DivDados from "../../components/DivDados"
import Perfil from "../../middleware/perfil"
import FiltrosModelosProcessos from "../../components/filtrosModelosProcessos"
import ListaDeProcessos from "../../components/ListaDeProcessosModelos"
import ModalNovoModeloProcesso from "../../components/ModalNovoModeloProcesso"

function ModelosProcessos() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovoModeloProcesso)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Modelos Processos'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <>
                    {modal && <ModalNovoModeloProcesso />}
                    <FiltrosModelosProcessos />
                    <ListaDeProcessos />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default ModelosProcessos;