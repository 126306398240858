import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, FormEvent } from 'react'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setOcultarModalDadosLead } from '../../store/modules/modalVisualizarDadosLeads'
import { detalhesLead, atualizarLead } from '../../services/hg/leads'
import { telefoneMascara } from '../../helpers'
import CarregandoPontinhos from '../CarregandoPontinhos'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import Btn from '../Btn'
import './visualizardadosleads.css'

function VisualizarDadosLeads () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosLeads)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()

    const [lead, setLead] = useState('')
    const [empresa, setEmpresa] = useState ('')
    const [situacao, setSituacao] = useState ('')
    const [telefone, setTelefone] = useState ('')
    const [email, setEmail] = useState ('')
    const [formaConhecimento, setFormaConhecimento] = useState ('')
    const [mensagem, setMensagem] = useState ('')
    const [resultado, setResultado] = useState<string | null>(null)
    const [pontinhos, setPontinhos] = useState (true)
    const [dados, setDados] = useState (false)
    const [modal, setModal] = useState (false)
    const [processando, setProcessando] = useState (false)
    const [confirmacao, setConfirmação] = useState (false)

    function ocultarModal () {

        dispatch (setOcultarModalDadosLead ({
            exibir: false
        }))
    }

    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    const atualizarDadosLead = async (e: FormEvent) => {

        setProcessando (true)

        e.preventDefault ()
        const id = modalEstado.id.toString()

        const payload = {
            nome: lead,
            empresa,
            situacao,
            telefone,
            email,
            formaConhecimento,
            resultado
        }

        if ((situacao === 'conquistado' && resultado === null) || (situacao === 'perdido' && resultado === null)) {

            setProcessando (false)
            return alert ('O campo consideração final não pode ficar vazio') 
        }

        try {

            const resposta = await atualizarLead (id, payload)

            if (resposta.status === 200) {
                setProcessando (false)
                setConfirmação (true)

                setTimeout (() => {
                    setConfirmação (false)
                    ocultarModal()
                    
                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2000)
            }
            
        } catch (error) {
            setProcessando (false)

            return alert (error)
        }
    }

    useEffect (() => {

        const idLead = modalEstado.id.toString()
    
        if (idLead === undefined || idLead === null) {
            return alert ('Processo Não Identificado. Tente Novamente')
        }

        const detalhesProcessos = async () => {
            
            try {
                const resposta = await detalhesLead (idLead)

                if (resposta.status === 200) {
                    setLead (resposta.data.nome)
                    setEmpresa (resposta.data.empresa)
                    setSituacao (resposta.data.situacao)
                    setTelefone (resposta.data.telefone)
                    setEmail (resposta.data.email)
                    setFormaConhecimento (resposta.data.forma_conhecimento)
                    setMensagem (resposta.data.mensagem)
                    setResultado (resposta.data.resultado)
                    setPontinhos (false)
                    setDados (true)
                }

            } catch (error) {
                return
            }
        }

        detalhesProcessos ()

    }, [modalEstado])
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (

        <div id = 'fundoModalDadosLead'>
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {processando && <CarregandoCirculo />}
            {modal && <div id="visualizarLeadDetalhesFundo">
                <form onSubmit={atualizarDadosLead}>

                    <div id="tarefa_superior_dados">
                        <div id = 'visualizar_dados_superior_titulo'>
                            <div>
                                <p id = 'visualisar_dados_titulo'>LEAD</p>
                                {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                                {dados && <input
                                type='text'
                                id = 'visualizar_dados_titulo_tarefa'
                                value={lead.toUpperCase()}
                                onChange = {(e) => setLead (e.target.value.toLocaleLowerCase())}/>}
                            </div>

                            <div>
                                <p id = 'visualisar_dados_titulo'>EMPRESA</p>
                                {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                                {dados && <input
                                type='text'
                                id = 'visualizar_dados_titulo_tarefa'
                                value={empresa === null ? '' : empresa.toUpperCase()}
                                onChange = {(e) => setEmpresa(e.target.value.toLocaleLowerCase())}/>}
                            </div>

                            <div>
                                <p id = 'visualisar_dados_titulo'>SITUAÇÃO</p>
                                {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                                {dados && <select id = 'situacaoLeadeDados' value={situacao} onChange = {(e) => setSituacao(e.target.value)}>
                                    <option value="em negociação">EM NEGOCIAÇÃO</option>
                                    <option value="conquistado">CONQUISTADO</option>
                                    <option value="perdido">PERDIDO</option>
                                </select>}
                            </div>
                        </div>
                    </div>

                    <div>
                        <span id = 'detalhesDadosLead'>
                            <span>
                                <b>Telefone/WhatsApp</b>
                                <input type="text" value={telefone} onChange={telefoneChange}/>
                            </span>

                            <span>
                                <b>E-Mail</b>
                                <input type='email' value={email}onChange = {(e) => setEmail(e.target.value)}/>
                            </span>

                            <span>
                                <b>Forma de Conhecimento</b>
                                <select value={formaConhecimento} onChange = {(e) => setFormaConhecimento(e.target.value)}>
                                    <option value="google">Google</option>
                                    <option value="facebook">Facebook</option>
                                    <option value="instagram">Instagram</option>
                                    <option value="indicação de amigos">Indicação de Amigos</option>
                                    <option value="participação em eventos">Participação em Eventos</option>
                                    <option value="outros">Outros</option>
                                </select>
                            </span>

                        </span>
                        <span id = 'detalhesLeadMensagem'>
                            <span>
                                <b>Mensagem Enviada Pelo Cliente</b>
                                <p>{mensagem.toUpperCase()}</p>
                            </span>
                        </span>

                        <span id = 'detalhesLeadMensagem'>
                            <span id = 'comentarioDetalheLead'>
                                <b>Consideração Final</b>
                                <textarea
                                rows={5}
                                value={resultado === null ? '' : resultado}
                                onChange={(e) => setResultado (e.target.value)}
                                maxLength={500}/>
                            </span>
                        </span>
                    </div>

                    <div id="tarefa_botao">
                        <div>
                            <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='SAIR' cor='cancelar' />
                            <Btn id = 'salvarDadosLead' titulo='SALVAR DADOS' cor='novo' />
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    )
}

export default VisualizarDadosLeads