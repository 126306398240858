import { useEffect, useState } from 'react'
import { avaliacaoAtendimento } from '../../services/hg/graficos'
import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import CarregandoPontinhos from '../CarregandoPontinhos'
import './graficoavaliacaoatendimento.css'

function GraficoAvaliacaoAtendimento () {

    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [dados, setDados] = useState ([])
    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const mediaAtendimento = async () => {

            if (idUsuario === null || idUsuario === undefined ) {
                return
            }


            try {
                const resposta = await avaliacaoAtendimento (parseInt(idUsuario))
                setCarregando (false)
                setDados (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setGrafico (true)

            } catch (error: any) {

                if (error.status === 401) {
                    return window.location.href = '/'
                }

                return alert (error)
            }
        }

        mediaAtendimento ()

    }, [idUsuario])


    return (
        <>
            <div id = 'grafico_avaliacao_atendimento'>
                <p id = 'p_avaliacao_atendimento'>AVALIAÇÃO DO SEU ATENDIMENTO</p>
                {carregando && <CarregandoPontinhos cor = 'pontinhosFundoBranco' />}
                {grafico && <ResponsiveContainer>
                    <AreaChart data={dados} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                    <defs>
                        <linearGradient id="media_atendimento" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#00A6A6" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#00A6A6" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="período" />
                    <YAxis />
                    <Tooltip />
                    <CartesianGrid strokeDasharray="6 6" />
                    <Area type="monotone" dataKey="média" strokeWidth={2} stroke="#008000"  activeDot = {{r: 8}} fillOpacity={1} fill="url(#media_atendimento)" />
                    </AreaChart>
                </ResponsiveContainer>}
                {semDados && <NenhumDadoGrafico texto='Nenhum atendimento seu' subtexto='foi avaliado'/>}
            </div>
        </>
    )
}

export default GraficoAvaliacaoAtendimento