import { useEffect, useState, FormEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootStore } from "../../store"
import { useParams } from "react-router-dom"
import { novoBoletoInter } from "../../services/inter/boletos"
import { atualizarCliente } from "../../services/hg/clients"
import { clienteId } from "../../services/hg/clients"
import { clientesPayload } from "../../interfaces/client"
import { NovaFatura, NovoReciboPagamento } from "../../services/hg/email"
import { receitasCliente, detalhesReceita, receitaBoletoPdf } from "../../services/hg/receitas"
import { receitasClientesResposta } from "../../interfaces/receitas"
import { telefoneMascara } from "../../helpers"
import { setAtualizarDados } from "../../store/modules/atualizarDados"
import reciboImg from '../../assets/img/recibo.png'
import CarregandoCirculo from "../CarregandoCirculo"
import visualizarBoletoImg from '../../assets/img/visualizar_boleto.png'
import enviarEmailImg from '../../assets/img/enviar_email.png'
import DivSituacao from "../DivSituacao"
import ConfirmacaoOperacao from "../ConfirmacaoOperacao"
import ErrorOperacao from "../ErroOperacao"
import "./clientesDadosInferior.css"
import Btn from "../Btn"

function ClientesDadosInferior() {
    
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()
    const params = useParams()

    const [cliente, setCliente] = useState<clientesPayload> ()
    const [tipo, setTipo] = useState('')
    const [situacao, setSituacao] = useState ('')
    const [nomeFantasia, setNomeFantasia] = useState ('')
    const [razaoSocial, setRazaoSocial] = useState ('')
    const [telefone, setTelefone] = useState ('')
    const [email, setEmail] = useState ('')
    const [rua, setRua] = useState ('')
    const [numero, setNumero] = useState ('')
    const [bairro, setBairro] = useState ('')
    const [complemento, setComplemento] = useState<null | string> (null)
    const [cidade, setCidade] = useState ('')
    const [estado, setEstado] = useState ('')
    const [alteracao, setAlteracao] = useState ('')
    const [linkCobranca, setLinkCobranca] = useState('')
    const [receitas, setReceitas] = useState<receitasClientesResposta []> ([])
    const [dadosCliente, setDadosCliente] = useState (true)
    const [financeiro, setFinanceio] = useState (false)
    const [carregando, setCarregando] = useState(false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [processandoSolicitacao, setProcessandoSolicitacao] = useState (false)
    const [confirmacaoEmail, setConfirmacaoEmail] = useState (false)
    const [confirmacaoReciboEmail, setConfirmacaoReciboEmail] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    const [erroSalvarDadosCliente, setErroSalvarDadosCliente] = useState (false)    


    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    function exibirDadosCadastrais () {
        setDadosCliente (true)
        setFinanceio (false)
    }

    function exibirDadosFinanceiro () {
        setFinanceio (true)
        setDadosCliente (false)
    }

    function receitaNovoBoleto (idReceita: number) {
        
        const novoBoleto = async () => {

            setProcessandoSolicitacao (true)

            try {
                
                const resposta = await novoBoletoInter (idReceita)
                setProcessandoSolicitacao (false)

                return visualizarBoletoVendaPdf (idReceita)

            } catch (error) {
                setProcessandoSolicitacao(false)
                return alert ('Ocorreu um Erro ao gerar o Boleto. Tente Novamente')
            }
        }

        novoBoleto ()
    }

    function visualizarBoletoVendaPdf (idReceita: number) {

        const visualizarPdf = async () => {

            setProcessandoSolicitacao (true)

            try {
                const resposta = await receitaBoletoPdf (idReceita)
                const pdf = resposta.data.boleto

                if (pdf !== null) {
                    setProcessandoSolicitacao (false)
                    return window.open(pdf, '_blank')
                }

                return receitaNovoBoleto (idReceita)

            } catch (error) {
                setProcessandoSolicitacao (true)
                
                setTimeout (() => {
                    setProcessandoSolicitacao (false)
                    return alert ('Erro ao Gerar o Pdf. Tente Novamente')
                }, 2500)
            }
        }

        visualizarPdf ()

    }

    function emailFatura (idReceita: number) {

        
        const enviarEmail = async () => {
            
            setProcessandoSolicitacao (true)

            try {

                const boleto = await detalhesReceita (idReceita)

                if (boleto.data.boleto === null) {

                    setProcessandoSolicitacao (false)
                    setErroOperacao (true)

                    return setTimeout (() => {
                        setErroOperacao (false)
                    }, 2500)
                }
                
                const resposta = await NovaFatura (idReceita)

                setProcessandoSolicitacao (false)
                setConfirmacaoEmail(true)

                setTimeout (() => {
                    return setConfirmacaoEmail (false)
                }, 3000)

            } catch (error) {
                console.log (error)
                setProcessandoSolicitacao (false)
                return alert ('Falha ao Enviar o Email. Tente Novamente!')
            }
        }

        enviarEmail ()
    }

    async function reciboPagamento (idReceita: number) {

        setProcessandoSolicitacao (true)

        try {
            
            const resposta = await NovoReciboPagamento (idReceita)
    
            if (resposta.status === 200) {
    
                setConfirmacaoReciboEmail (true)
                setProcessandoSolicitacao (false)
    
                setTimeout (() => {
                   return setConfirmacaoReciboEmail (false)
                }, 3000)
    
            }

        } catch (error) {
            setProcessandoSolicitacao (false)
            return alert ('falha ao Enviar o Recibo por E-mail')
        }
    }

    const atualizarCadastroCliente = async (e: FormEvent) => {

        e.preventDefault()

        setCarregando (true)

        const { idCliente } =  params

        const payload = {
            tipo,
            situacao,
            nome_fantasia: nomeFantasia,
            razao_social: razaoSocial,
            telefone,
            email,
            rua,
            numero,
            bairro,
            complemento,
            cidade,
            estado
        }

        if (idCliente === undefined || idCliente === null) {
            return
        }

        try {

            const resposta = await atualizarCliente (Number(idCliente), payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 3500)
            }
            
        } catch (error) {
            setCarregando (false)
            setErroSalvarDadosCliente (true)

            return setTimeout (() => {
                setErroSalvarDadosCliente (false)
            }, 3500)
        }
    }

    function OnScroll () {

        const p = document.querySelector('#cliente_cadastrais_dados');

        if (p === null) {
            return
        }

        const posicoes = p.getBoundingClientRect();
        console.log(posicoes);
        console.log (window.scrollY)
    }

    
    useEffect (() => {

        const { idCliente } =  params

        const clienteDados = async () => {

            try {
    
                const resposta = await clienteId (idCliente)
                setCliente (resposta.data)
                setTipo (resposta.data.tipo)
                setSituacao (resposta.data.situacao)
                setNomeFantasia (resposta.data.nome_fantasia)
                setRazaoSocial (resposta.data.razao_social)
                setTelefone (resposta.data.telefone)
                setEmail (resposta.data.email)
                setRua (resposta.data.rua)
                setNumero (resposta.data.numero)
                setBairro (resposta.data.bairro)
                setComplemento (resposta.data.complemento)
                setCidade (resposta.data.cidade)
                setEstado (resposta.data.estado)
                setAlteracao (resposta.data.alterado)
                setLinkCobranca(resposta.data.linkCobranca)
                setCarregando (false)
                
            } catch (error) {
    
                return alert ('Falha ao Recuperar os Dados do Cliente')
            }
        }

        clienteDados ()

    }, [params, atualizarDados])


    useEffect (() => {
        
        const {idCliente} = params

        const receitaCliente = async () => {

            setCarregando (true)

            try {

                const resposta = await receitasCliente (idCliente)
                setReceitas (resposta.data)
                setCarregando (false)
                
            } catch (error) {
                setCarregando (false)
                return alert ('Falha ao Listar as Receitas do Cliente')
            }
        }

        receitaCliente ()
        
  }, [params])

  return (
    <div id="cliente_dados_inferior">
        <div id="informacoes_cliente">
            <p id="opcoes_informacoes_cliente" onClick={() => exibirDadosCadastrais()}>DADOS CADASTRAIS</p>
            <p id="opcoes_informacoes_cliente" onClick={() => exibirDadosFinanceiro()}>FINANCEIRO</p>
        </div>
        <div id="cliente_cadastrais_dados" onScroll={OnScroll}>
            {carregando && <CarregandoCirculo />}
            {processandoSolicitacao && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {confirmacaoEmail && <ConfirmacaoOperacao texto='E-mail Enviado com Sucesso' />}
            {confirmacaoReciboEmail && <ConfirmacaoOperacao texto='Recibo Enviado para o E-mail do Cliente' />}
            {erroOperacao && <ErrorOperacao texto='Não Existe Boleto para essa Receita' />}
            {erroSalvarDadosCliente && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {dadosCliente && <form id = 'cliente_dados_cadastrais' onSubmit={atualizarCadastroCliente}>
                    <div>
                        <span>
                            <label>ID</label>
                            <input type="text" value={(`00000${cliente?.id}`).slice(-5)} readOnly/>
                        </span>
                        <span>
                            <label>Tipo</label>
                            <select value={tipo} onChange={(e) => setTipo(e.target.value)}>
                                <option value = 'pessoa física'>PESSOA FÍSICA</option>
                                <option value = 'pessoa jurídica' >PESSOA JURÍDICA</option>
                            </select>
                        </span>
                        <span>
                            <label>CNPJ/CPF</label>
                            <input type="text" value={cliente?.cnpj_cpf} readOnly/>
                        </span>
                        <span>
                            <label>Situação</label>
                            <select value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                <option value = 'ativo'>ATIVO</option>
                                <option value = 'inativo'>INATIVO</option>
                            </select>
                        </span>
                    </div>
                    <div>
                        <span>
                            <label>Nome Fantasia</label>
                            <input type="text" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Razão Social</label>
                            <input type="text" value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value.toLowerCase())}/>
                        </span>
                    </div>

                    <div>
                        <span>
                            <label>Telefone</label>
                            <input type="text" value={telefone} onChange={telefoneChange} minLength={18} maxLength={18}/>
                        </span>
                        <span>
                            <label>E-mail</label>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())}/>
                        </span>
                    </div>

                    <div>
                        <span>
                            <label>Rua</label>
                            <input type="text" value={rua} onChange={(e) => setRua(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Número</label>
                            <input type="text" value={numero} onChange={(e) => setNumero(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Bairro</label>
                            <input type="text" value={bairro} onChange={(e) => setBairro(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Complemento</label>
                            <input type="text" value={complemento === null ? '' : complemento} onChange={(e) => setComplemento(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Cidade</label>
                            <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value.toLowerCase())}/>
                        </span>
                        <span>
                            <label>Estado</label>
                            <select  value = {estado} onChange={(e) => setEstado(e.target.value)} >
                                <option value = 'acre'>ACRE</option>
                                <option value= 'alagoas'>ALAGOAS</option>
                                <option value = 'amapá'>AMAPÁ</option>
                                <option value = 'amazonas'>AMAZONAS</option>
                                <option value = 'bahia'>BAHIA</option>
                                <option value = 'ceará'>CEARÁ</option>
                                <option value = 'espírito santo'>ESPÍRITO SANTOS</option>
                                <option value = 'goiás'>GOIÁS</option>
                                <option value = 'maranhão'>MARANHÃO</option>
                                <option value = 'mato grosso'>MATO GROSSO</option>
                                <option value = 'mato grosso do sul'>MATO GROSSO DO SUL</option>
                                <option value = 'minas gerais'>MINAS GERAIS</option>
                                <option value = 'pará'>PARÁ</option>
                                <option value = 'paraíba'>PARAÍBA</option>
                                <option value = 'paraná'>PARANÁ</option>
                                <option value = 'pernanbuco'>PERNAMBUCO</option>
                                <option value = 'piauí'>PIAUÍ</option>
                                <option value = 'rio de janeiro'>RIO DE JANEIRO</option>
                                <option value = 'rio grande do norte'>RIO GRANDE DO NORTE</option>
                                <option value = 'rio grande do sul'>RIO GRANDE DO SUL</option>
                                <option value = 'rondônica'>RONDÔNIA</option>
                                <option value = 'roraima'>RORAIMA</option>
                                <option value = 'santa catarina'>SANTA CATARINA</option>
                                <option value = 'são paulo'>SÃO PAULO</option>
                                <option value = 'sergipe'>SERGIPE</option>
                                <option value = 'tocantins'>TOCANTINS</option>
                            </select>
                        </span>
                    </div>

                    <div>
                        <span>
                            <label>Cliente Desde</label>
                            <input type="text" value={(`${cliente?.criado}`).slice(5,7) + '/' + (`${cliente?.criado}`).slice(0,4)} readOnly disabled/>
                        </span>
                        <span>
                            <label>Última Atualização</label>
                            <input type="text" value={alteracao} readOnly/>
                        </span>
                    </div>

                    <div>
                        <span>
                            <label>Link de Cobrança</label>
                            <input type="text" value={linkCobranca.toString().toLowerCase()} readOnly/>
                        </span>
                    </div>

                    <div id = 'cliente_dados_opcoes_atualizar'>
                        <Btn id="btn_atualizar_dados_cliente" titulo="ATUALIZAR DADOS" cor="novo"/>
                    </div>
                </form>}
            {financeiro && (
                <table id="financeiro_cliente">
                    <thead>
                        <tr>
                            <th id = 'id_cliente_financeiro' align="center">ID</th>
                            <th align="center">VENDA ID</th>
                            <th align="left">DESCRIÇÃO</th>
                            <th align="left">COMPETÊNCIA</th>
                            <th align="left">VENCIMENTO</th>
                            <th align="left">VALOR TOTAL</th>
                            <th align="center">SITUAÇÃO</th>
                            <th align="center">OPÇÕES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {receitas.map((receita) => (
                            <tr key={Math.random()}>
                                <td id = 'id_cliente_financeiro' align="center">{(`00000${receita.id}`).slice(-5)}</td>
                                <td align="center">{(`00000${receita.venda.id}`).slice(-5)}</td>
                                <td>{receita.descricao.toUpperCase()}</td>
                                <td align="left">{`${receita.criado.slice(5,7)}/${receita.criado.slice(0,4)}`}</td>
                                <td align="left">{`${receita.vencimento.slice(8,10)}/${receita.vencimento.slice(5,7)}/${receita.vencimento.slice(0, 4)}`}</td>
                                <td align="left">{`R$ ${receita.valor_total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}`}</td>
                                <td align="center">
                                    <DivSituacao texto={receita.situacao.toUpperCase()} cor={receita.situacao.toLowerCase()}/>
                                </td>
                                <td align="center">
                                    <div id="opcoes_vendas_financeiro">
                                        <img title='Visualizar Boleto' id = 'visualizar_receita' className={receita.situacao.toUpperCase()} alt='Visualizar receita' src= {visualizarBoletoImg} onClick = {() => visualizarBoletoVendaPdf(receita.id)}/>
                                        <img title='Enviar Boleto por E-mail' id = 'enviar_email_boleto' className={receita.situacao.toUpperCase()} alt='Enviar por E-mail' src= {enviarEmailImg} onClick = {() => emailFatura(receita.id)}/>
                                        <img title='Comprovante de Pagamento' id = 'recibo_pagamento' className={receita.situacao.toLowerCase()} alt='Recibo de Pagamento' src= {reciboImg} onClick = {() => reciboPagamento (receita.id)}/>
                                    </div>
                                </td>
                            </tr>))}
                    </tbody>
                </table>)}
        </div>
    </div>
  )
}

export default ClientesDadosInferior;
