import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import FiltrosTarefasAuto from "../../components/FiltrosTarefasAuto";
import ListaDeTarefasAuto from "../../components/ListaDeTarefasAuto";
import ModalNovatarefa from "../../components/ModalNovaTarefa";

function TarefasAuto () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaTarefa)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Tarefas Automáticas'
    }, [])

    return (
        <DivDados id = 'dados_tarefas_auto_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <>  
                    {modal && <ModalNovatarefa />}
                    <FiltrosTarefasAuto />
                    <ListaDeTarefasAuto />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default TarefasAuto