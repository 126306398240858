import baseApiHg from "../api/config"
import { assinaturaCadastrarPayload, assinaturaRequisicaoPaylod, atualizarAssinaturaPayload, atualizarItemAssinaturaPayload } from "../../../interfaces/assinaturas"

export function novaAssinatura (payload: assinaturaCadastrarPayload) {
    return baseApiHg.post('/assinaturas', payload)
}

export function listaAssinaturas (payload: assinaturaRequisicaoPaylod) {
    return baseApiHg.get(`/assinaturas?situacao=${payload.situacao}&cliente=${payload.cliente}`)
}

export function ocorrenciaAssinatura (idAssinatura: number) {
    return baseApiHg.get(`/assinatura/ocorrencia/${idAssinatura}`)
}

export function assinaturaId (idAssinatura: string) {
    return baseApiHg.get(`/assinaturas/${idAssinatura}`)
}

export function removerItemAssinatura (idAssinatura: number, idItem: number) {
    return baseApiHg.delete(`/assinatura/removeritem?idAssinatura=${idAssinatura}&idItem=${idItem}`)
}

export function atualizarAssinatura (payload: atualizarAssinaturaPayload) {
    return baseApiHg.patch (`/assinatura/${payload.id}`, {situacao: payload.situacao})
}

export function atualizarItemAssinatura (payload: atualizarItemAssinaturaPayload) {
    return baseApiHg.patch (`/assinatura/atualizaritem?idItem=${payload.idItem}&idAssinatura=${payload.idAssinatura}`, {novoValorItem: payload.novoValorItem})
}