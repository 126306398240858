import { Routes as WrapperRoutes, Route, BrowserRouter } from 'react-router-dom'
import ComponentePrivado from '../components/ComponentesPrivado'
import Dashboard from '../pages/Dashboard'
import Responsaveis from '../pages/Responsaveis'
import Clientes from '../pages/Clients'
import Home from '../pages/Home'
import Bug from '../pages/Bug'
import SugestaoMelhoria from '../pages/Sugestao'
import Boletos from '../pages/Boletos'
import Assinaturas from '../pages/Assinaturas'
import Receitas from '../pages/Receitas'
import Vendas from '../pages/Vendas'
import Financeiro from '../pages/Financeiro'
import ClientesDados from '../pages/ClientesDados'
import AssinaturasDados from '../pages/AssinaturasDados'
import Tarefas from '../pages/Tasks'
import Painel from '../pages/MeuPainel'
import SaldoBancario from '../pages/SaldoBancario'
import TarefasAuto from '../pages/TasksAuto'
import ModelosProcessos from '../pages/ModelosProcessos'
import ProcessosEmAndamento from '../pages/ProcessosEmAndamento'
import Leads from '../pages/Leads'
import MenuLateral from '../components/MenuLateral'

function Routes () {

    const pathname = window.location.pathname
    
    return (
        <BrowserRouter>
            {pathname === '/' ? null : <ComponentePrivado><MenuLateral /></ComponentePrivado>}
            <WrapperRoutes>
                <Route path='*' element = { <Home /> } />
                <Route path='/' element = { <Home /> } />
                <Route path='/dashboard' element = { <ComponentePrivado><Dashboard /></ComponentePrivado>} />
                <Route path='/meupainel' element = { <ComponentePrivado><Painel /></ComponentePrivado>} />
                <Route path='/financeiro' element = { <ComponentePrivado><Financeiro /></ComponentePrivado>} />
                <Route path='/:tipo/:perfil' element = { <ComponentePrivado><Tarefas /></ComponentePrivado>} />
                <Route path='/tarefas' element = { <ComponentePrivado><TarefasAuto /></ComponentePrivado>} />
                <Route path='/responsaveis' element = { <ComponentePrivado><Responsaveis /></ComponentePrivado> } />
                <Route path='/clientes' element = { <ComponentePrivado><Clientes /></ComponentePrivado> } />
                <Route path='/cliente/:idCliente' element = { <ComponentePrivado><ClientesDados /></ComponentePrivado> } />
                <Route path='/bug' element = { <ComponentePrivado><Bug /></ComponentePrivado> } />
                <Route path='/sugestao' element = { <ComponentePrivado><SugestaoMelhoria /></ComponentePrivado> } />
                <Route path='/boletos' element = { <ComponentePrivado><Boletos></Boletos></ComponentePrivado> } />
                <Route path='/assinaturas' element = { <ComponentePrivado><Assinaturas /></ComponentePrivado> } />
                <Route path='/assinatura/:idAssinatura' element = { <ComponentePrivado><AssinaturasDados /></ComponentePrivado> } />
                <Route path='/receitas' element = { <ComponentePrivado><Receitas /></ComponentePrivado> } />
                <Route path='/saldobancario' element = { <ComponentePrivado><SaldoBancario /></ComponentePrivado> } />
                <Route path='/vendas' element = { <ComponentePrivado><Vendas /></ComponentePrivado> } />
                <Route path='/modelosProcessos' element={<ModelosProcessos />} />
                <Route path='/modelosProcessos/:id' element={<ModelosProcessos />} />
                <Route path='/processosEmAndamento/:perfil' element={<ProcessosEmAndamento />} />
                <Route path='/leads' element = {<ComponentePrivado><Leads /></ComponentePrivado>} />
            </WrapperRoutes>
        </BrowserRouter>
    )
}

export default Routes