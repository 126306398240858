import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootStore } from '../../store'
import { modelosProcessosRespostaPayload } from '../../interfaces/modelosProcessos'
import { listaModelosProcessos } from '../../services/hg/modelosProcessos'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from "../ConfirmacaoOperacao"
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ErrorOperacao from '../ErroOperacao'
import DivSituacao from '../DivSituacao'
import './listaprocessos.css'

function ListaDeProcessos () {

    const filtrosProcessosModelos = useSelector((store: RootStore) => store.filtrosModelosProcessos)
    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaAssinatura)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const navegate = useNavigate ()

    const [modelosProcessos, setModelosProcessos] = useState<modelosProcessosRespostaPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [confirmacao, setConfirmacao] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [erroOperacao, setErroOperacao] = useState(false)

    const modelosProcessosDados =  (id: string) => {
        return alert(id)
    }

    useEffect (() => {
        
        const listaProcessos = async () => {

            setSolicitacao (true)

            const payload = {
                titulo: filtrosProcessosModelos.titulo,
                situacao: filtrosProcessosModelos.situacao,
            }
            try {
                const resposta = await listaModelosProcessos (payload)
                setResultadoPesquisa (false)
                setModelosProcessos (resposta.data)
                setSolicitacao (false)

                if (resposta.data.length === 0) {
                    return setResultadoPesquisa (true)
                }

            } catch (error) {
                setErroOperacao (true)

                return setTimeout (() => {
                    setErroOperacao (false)
                }, 2500)
            }
        }

        listaProcessos ()

    }, [filtrosProcessosModelos, modalEstado, atualizarDados])

    return (
        <div id = 'modelosProcessos'>
            {confirmacao && <ConfirmacaoOperacao  texto="Assinatura Atualizada com Sucesso"/>}
            {solicitacao && <CarregandoCirculo />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Recuperar os Dados' />}
            {<table id = 'tabelaModelosProcessos'>
                <thead>
                    <tr id = 'assinatura_cabecalho'>
                        <th id = 'id_assinatura'>TÍTULO</th>
                        <th id = 'nome_cliente_assinatura' align='center'>CRIADO POR</th>
                        <th align='center'>SITUAÇÃO</th>
                        <th>ÚLTIMA ALTERAÇÃO</th>
                    </tr>
                </thead>

                <tbody id = 'modelosProcesosLista'>
                    {modelosProcessos.map ((processo) => (
                        <tr key={processo.id} id = 'lista_assinaturas' className= {processo.situacao} onClick = {() => modelosProcessosDados(processo.id)}>
                            <td id = 'id_assinatura'>{processo.titulo.toUpperCase()}</td>
                            <td align='center'>
                                <img id = 'avatarCriadorProcesso' src={processo.usuario.avatar} alt = ''/>
                            </td>
                            <td align='center'>
                                <DivSituacao texto= {processo.situacao.toUpperCase()} cor = {processo.situacao} />
                            </td>
                            <td><b>{processo.alterado.slice(8,10)}/{processo.alterado.slice(5,7)}/{processo.alterado.slice(0,4)}</b> às <b>{processo.alterado.slice(11,19)}</b></td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeProcessos