import { useNavigate } from 'react-router-dom'
import MenuDashboard from '../MenuOpcoesDashboard'
import MenuTarefas from '../MenuOpcoesTarefas'
import MenuAtendimento from '../MenuOpcoesAtendimento'
import MenuComercial from '../MenuOpcoesComercial'
import MenuProcessos from '../MenuOpcoesProcessos'
import MenuFinanceiro from '../MenuOpcoesFinanceiro'
import MenuCadastros from '../MenuOpcoesCadastros'
import MenuAdministrativo from '../MenuOpcoesAdministrativo'
import MenuRelatorios from '../MenuOpcoesRelatorios'
import MenuSuporte from '../MenuOpcoesSuporte'
import MenuConfiguracoes from '../MenuOpcoesConfiguracoes'
import ContaUsuario from '../ContaUsuario'
import logoHg from '../../assets/img/logo_preto.png'
import dashboardIcone from '../../assets/icons/dasboard.png'
import tarefasIcone from '../../assets/icons/tarefas.png'
import atendimentoIcone from '../../assets/icons/clientes.png'
import comercialIcone from '../../assets/icons/comercial.png'
import processosIncone from '../../assets/icons/processos.png'
import financeiroIcone from '../../assets/icons/financeiro_branco.png'
import cadastrosIcone from '../../assets/icons/adicionar.png'
import administraitvoIcone from '../../assets/icons/administrativo.png'
import relatoriosIcone from '../../assets/icons/relatorios.png'
import configuracoesIcone from '../../assets/icons/configuracoes.png'
import suporteIcone from '../../assets/icons/suporte.png'
import gestorIcone from '../../assets/icons/gesttor.png'
import Perfil from '../../middleware/perfil'
import './menulateral.css'

function MenuLateral () {

    const navigate = useNavigate()

    function retormarHome () {
        return navigate ('/meupainel')
    }

    return (
        <div id = 'menu_lateral'>
            <div id = 'hg_logo'>
                <img id = 'logo_hg' src={logoHg} alt = 'logo do hg consultoria' onClick={() => retormarHome ()}></img>
            </div>
            <div id = 'menu_lateral_opcoes'>
                <MenuDashboard icone = {dashboardIcone} titulo = 'Dashboard' alt='icone do dashboard do hg'/>
                <MenuTarefas icone= {tarefasIcone} titulo = 'Tarefas' alt='icone de tarefas do hg'/>
                <MenuAtendimento icone= {atendimentoIcone} titulo = 'Atendimento' alt='icone do atendimento do hg' />
                <Perfil perfil={['master', 'administrativo', 'comercial', 'atendimento']}>
                    <MenuComercial icone={comercialIcone} titulo = 'Comercial' alt='' />
                </Perfil>
                <MenuProcessos icone={processosIncone} titulo = 'Processos' alt='icone de processos do hg' />
                <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                    <MenuCadastros icone={cadastrosIcone} titulo = 'Cadastros' alt='icone de cadastros do hg'  />
                </Perfil>
                <Perfil perfil={['master', 'administrativo']}>
                    <MenuFinanceiro icone= {financeiroIcone} titulo = 'Financeiro' alt='icone do financeiro do hg' />
                </Perfil>
                <Perfil perfil={['master', 'administrativo']}>
                    <MenuAdministrativo icone= {administraitvoIcone} titulo = 'Administrativo' alt='icone do administrativo do hg' />
                </Perfil>
                <Perfil perfil={['master']}>
                    <MenuRelatorios icone= {relatoriosIcone} titulo = 'Relatórios' alt='icone de relatorios do hg' />
                </Perfil>
                <MenuSuporte icone= {suporteIcone} titulo = 'Suporte' alt='icone de suporte do hg' />
                <Perfil perfil={['master', 'administrativo']}>
                    <MenuConfiguracoes icone= {configuracoesIcone} titulo = 'Configuracoes' alt='icone de confiuracoes do hg'  />
                </Perfil>
            </div>
            <ContaUsuario />
        </div>
    )
}

export default MenuLateral