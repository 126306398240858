import { configureStore } from "@reduxjs/toolkit"
import modalNovaSugestao from "./modules/modalNovaSugestao"
import modalNovoProblema from "./modules/modalNovoProblema"
import modalInformarRecebimentoPagamento from "./modules/modalInformarRecebimentoPagamento"
import modalVisualizarDadosProblema from "./modules/modalVisualizarDadosProblema"
import modalVisualizarDadosTarefas from "./modules/modalVisualizarDadosTarefas"
import modalVisualizarDadosSugestao from "./modules/modalVisualizarDadosSugestao"
import modalVisualizarDadosProcessosAndamento from "./modules/modalVisualizarDadosProcessosAndamento"
import modalVisualizarDadosVenda from "./modules/modalVisualizarDadosVenda"
import modalVisualizarDadosLeads from "./modules/modalVisualizarDadosLeads"
import ModalCancelarBoleto from "./modules/ModalCancelarBoleto"
import modalNovaAssinatura from "./modules/modalNovaAssinatura"
import modalNovaTarefa from "./modules/modalNovaTarefa"
import modalNovaTarefaProcesso from "./modules/modalNovaTarefaProcesso"
import modalNovoCliente from "./modules/modalNovoCliente"
import modalNovoResponsavel from "./modules/modalNovoResponsavel"
import modalNovaVenda from "./modules/modalNovaVenda"
import modalRecuperarSenha from "./modules/modalRecuperarSenha"
import modalNovoModeloProcesso from "./modules/modalNovoModeloProcesso"
import modalNovoProcesso from "./modules/modalNovoProcesso"
import modalNovoLead from "./modules/modalNovoLead"
import usuarioReduce from './modules/usuarios'
import filtrosBug from "./modules/filtrosBug"
import filtrosSugestao from "./modules/filtrosSugestao"
import filtrosBoletos from "./modules/filtrosBoletos"
import filtrosReceitas from "./modules/filtrosReceitas"
import filtrosVendas from "./modules/filtrosVendas"
import filtrosAssinaturas from "./modules/filtrosAssinaturas"
import filtrosClientes from "./modules/filtrosClientes"
import filtrosFinanceiro from "./modules/filtrosFinanceiro"
import filtrosTarefas from "./modules/filtrosTarefas"
import filtrosAutoTarefas from "./modules/filtrosTarefasAuto"
import filtrosModelosProcessos from "./modules/filtrosModelosProcessos"
import filtrosProcessosAndamento from "./modules/filtrosProcessosAndamento"
import filtrosLeads from "./modules/filtrosLeads"
import atualizarDados from "./modules/atualizarDados"
import notificacoes from "./modules/notificacoes"

const store = configureStore ({
    reducer: {
        usuarioReduce,
        modalNovaSugestao,
        modalNovoProblema,
        modalNovaAssinatura,
        modalNovaTarefa,
        modalNovoCliente,
        modalNovoResponsavel,
        modalInformarRecebimentoPagamento,
        modalNovaTarefaProcesso,
        ModalCancelarBoleto,
        modalVisualizarDadosTarefas,
        modalVisualizarDadosProblema,
        modalVisualizarDadosSugestao,
        modalVisualizarDadosVenda,
        modalVisualizarDadosLeads,
        modalVisualizarDadosProcessosAndamento,
        modalNovoModeloProcesso,
        modalNovoProcesso,
        modalNovaVenda,
        modalNovoLead,
        modalRecuperarSenha,
        filtrosBug,
        filtrosLeads,
        filtrosSugestao,
        filtrosBoletos,
        filtrosReceitas,
        filtrosVendas,
        filtrosAssinaturas,
        filtrosClientes,
        filtrosFinanceiro,
        filtrosTarefas,
        filtrosAutoTarefas,
        filtrosModelosProcessos,
        filtrosProcessosAndamento,
        atualizarDados,
        notificacoes
    }
})

export type RootStore = ReturnType <typeof store.getState>

export default store