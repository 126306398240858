import { useState, FormEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setOcultarModalNovoCliente } from '../../store/modules/modalNovoCliente'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { consultaCnpj } from '../../services/cnpj'
import { cadastrarCliente, clienteCadastrado } from '../../services/hg/clients'
import { validarCpfCnpj } from '../../helpers'
import { listaDeResponsaveis } from '../../services/hg/responsaveis'
import { responsaveisPayload } from '../../interfaces/responsaveis'
import Alerta from '../Alerta'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './novocliente.css'

function ModalNovoCliente () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const [alertaCpfCpnjCadastrado, setAlertaCpfCnpjCadastrado] = useState (false)
    const [alertaCpfCpnjInvalido, setAlertaCpfCnpjInvalido] = useState (false)

    const [tipo, setTipo] = useState('pessoa jurídica')
    const [cpfCnpj, setCpfCnpj] = useState ('')
    const [nomeFantasia, setNomeFantasia] = useState ('')
    const [razaoSocial, setRazaoSocial] = useState ('')
    const [telefone, setTelefone] = useState ('')
    const [email, setEmail] = useState ('')
    const [rua, setRua] = useState ('')
    const [numero, setNumero] = useState ('')
    const [bairro, setBairro] = useState ('')
    const [complemento, setComplemento] = useState<null | string> (null)
    const [cep, setCep] = useState ('')
    const [cidade, setCidade] = useState ('')
    const [estado, setEstado] = useState ('alagoas')
    const [responsavelLista, setResponsavelLista] = useState< responsaveisPayload []>([])
    const [responsavel, setResponsavel] = useState (0)
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovoCliente ({
            exibir: false
        }))
    }

    const cpfCnpjMascara = (valor: string) => {

        if (tipo === 'pessoa jurídica') {
            valor = valor.replace(/\D/g, '')
            valor = valor.replace(/^(\d{2})(\d)/, "$1.$2")
            valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            valor = valor.replace(/\.(\d{3})(\d)/, ".$1/$2")
            valor = valor.replace(/(\d{4})(\d)/, "$1 - $2")
    
            return valor

        } else {

            valor = valor.replace (/\D/g, '')
            valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
            valor = valor.replace(/(\d{3})(\d)/, "$1.$2")
            valor = valor.replace(/(\d{3})(\d{1,2})$/, "$1 - $2")
    
            return valor
        }

    }

    const cpfCnpjChange = (e: any) => {
        
        const { value } = e.target

        setCpfCnpj (cpfCnpjMascara(value))
    }

    const telefoneMascara = (valor: string) => {
        
        valor = valor.replace(/\D/g, '')
        valor = valor.replace(/^(\d{2})(\d)/g,"($1) $2")
        valor = valor.replace(/(\d)(\d{8})$/,"$1.$2")
        valor = valor.replace(/(\d)(\d{4})$/,"$1 - $2")
        
        return valor
    }

    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    const cepMascara = (valor: string) => {

        valor = valor.replace(/\D/g, '')
        valor = valor.replace(/^(\d{5})(\d)/g,"$1 - $2")
        
        return valor

    }

    const cepChange = (e: any) => {
        
        const { value } = e.target

        setCep (cepMascara(value))
    }

    const consultarCnpjDados = async (cnpj: string) => {

        try {

            const cnpjValor = cnpj.replace(/\D/g, '')

            const resposta = await consultaCnpj (cnpjValor)

            setNomeFantasia (resposta.data.estabelecimento.nome_fantasia.toLowerCase())
            setRazaoSocial (resposta.data.razao_social.toLowerCase())
            setEmail (resposta.data.estabelecimento.email.toLowerCase())
            setTelefone (resposta.data.estabelecimento.ddd1 + '9' + resposta.data.estabelecimento.telefone1)
            setRua (resposta.data.estabelecimento.tipo_logradouro.toLowerCase() + ' ' + resposta.data.estabelecimento.logradouro.toLowerCase())
            setNumero (resposta.data.estabelecimento.numero)
            setBairro (resposta.data.estabelecimento.bairro.toLowerCase())
            setComplemento (resposta.data.estabelecimento.complemento === null ? null : resposta.data.estabelecimento.complemento.toLowerCase())
            setCep (resposta.data.estabelecimento.cep)
            setCidade (resposta.data.estabelecimento.cidade.nome.toLowerCase())
            setEstado (resposta.data.estabelecimento.estado.nome.toLowerCase())
            
        } catch (error) {
            return alert (error)
        }
    }

    const cpfCnpjBanco = async () => {

        if ((tipo === 'pessoa jurídica' && cpfCnpj.length === 20) || (tipo === 'pessoa física' && cpfCnpj.length === 16)) {
            
            try {

                const validarCnpjCpf = validarCpfCnpj (cpfCnpj)

                if (validarCnpjCpf === false) {
                    setAlertaCpfCnpjInvalido (true)
                    setCpfCnpj ('')

                    return setTimeout (() => {
                        setAlertaCpfCnpjInvalido (false)
                    }, 5000)
                }
                
                const resposta = await clienteCadastrado ()

                for (let index = 0; index < resposta.data.length; index++) {
                    if (cpfCnpj === resposta.data[index].cnpj_cpf) {
                        setAlertaCpfCnpjCadastrado (true)
                        setCpfCnpj ('')

                        return setTimeout (() => {
                            setAlertaCpfCnpjCadastrado (false)
                        }, 5000)
                    }
                }

                if (tipo === 'pessoa jurídica') {

                    consultarCnpjDados (cpfCnpj)
                }

                return
    
            } catch (error) {
                return alert (error)
            }
        }

        return

    }


    const novoCliente = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            responsavel_id: responsavel,
            tipo,
            cnpj_cpf: cpfCnpj,
            nome_fantasia: nomeFantasia,
            razao_social: razaoSocial,
            email,
            telefone,
            rua,
            numero,
            bairro,
            complemento,
            cep,
            cidade,
            estado
        }

        try {
            const resposta = await cadastrarCliente(payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2500)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 2000)
        }
    }

    useEffect (() => {

        setTelefone (telefoneMascara(telefone))

    }, [telefone])

    useEffect (() => {

        setCep (cepMascara(cep))

    }, [cep])

    useEffect (() => {

        const responsaveis = async () => {

            try {

                const resposta = await listaDeResponsaveis ()

                return setResponsavelLista (resposta.data)
                
            } catch (error) {
                return alert (error)
            }
        }

        responsaveis ()

    }, [])


    return (
        <div id = 'fundo_modal_novo_cliente'>
            {alertaCpfCpnjCadastrado && <Alerta texto='CPF/CNPJ já cadastrado'/>}
            {alertaCpfCpnjInvalido && <Alerta texto='CPF/CNPJ Inválido'/>}
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionar_novo_cliente">
                <div id="tarefa_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Novo Cliente</p>
                </div>

                <div id="tarefa_dados">
                    <form id = 'form_novo_cliente' onSubmit={novoCliente}>        
                        <div id="tarefa_dados">
                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Tipo</p>
                                    <select id = 'cliente_novo_tipo' value={tipo} required onChange={(e) => setTipo(e.target.value.toLowerCase())}>
                                        <option value = 'pessoa física'>PESSOA FÍSICA</option>
                                        <option value = 'pessoa jurídica' >PESSOA JURÍDICA</option>
                                    </select>
                                </span>

                                <span>
                                    <p>CPF/CNPJ</p>
                                    <input 
                                        type = 'text'
                                        id = 'input_cpf_cnpj'
                                        maxLength={tipo === 'pessoa jurídica' ? 20: 16}
                                        minLength ={tipo === 'pessoa jurídica' ? 20: 16}
                                        value = {cpfCnpj}
                                        placeholder = ' '
                                        required
                                        onChange={cpfCnpjChange}
                                        onKeyUp={cpfCnpjBanco}/>
                                </span>
                            </div> 
                        </div>

                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Nome Fantasia</p>
                                    <input
                                        id = 'input_nome_fantasia' 
                                        type="text"
                                        value={nomeFantasia}
                                        placeholder = ' '
                                        onChange={(e) => setNomeFantasia(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Razão Social</p>
                                    <input
                                        id = 'input_razao_social' 
                                        type="text"
                                        value={razaoSocial}
                                        placeholder = ' '
                                        onChange={(e) => setRazaoSocial(e.target.value.toLowerCase())}
                                        required/>
                                </span>
                            </div>
                        </div>

                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Telefone</p>
                                    <input
                                        id = 'input_telefone_novo_cliente' 
                                        type="text"
                                        minLength={18}
                                        maxLength={18}
                                        value={telefone}
                                        placeholder = ' '
                                        required
                                        onChange={telefoneChange}/>
                                </span>

                                <span>
                                    <p>E-mail</p>
                                    <input
                                        id = 'input_email_novo_cliente' 
                                        type="email"
                                        value={email}
                                        placeholder = ' '
                                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                        required/>
                                </span>
                            </div>
                        </div>
                        
                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>Rua</p>
                                    <input
                                        id = 'input_rua_novo_cliente' 
                                        type="text"
                                        value={rua}
                                        placeholder = ' '
                                        onChange={(e) => setRua(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Número</p>
                                    <input
                                        id = 'input_numero_novo_cliente' 
                                        type="text"
                                        value={numero}
                                        placeholder = ' '
                                        onChange={(e) => setNumero(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Bairro</p>
                                    <input
                                        id = 'input_bairro_novo_cliente' 
                                        type="text"
                                        value={bairro}
                                        placeholder = ' '
                                        onChange={(e) => setBairro(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Complemento</p>
                                    <input
                                        id = 'input_complemento_novo_cliente' 
                                        type="text"
                                        placeholder = ' '
                                        value={complemento === null ? undefined : complemento}
                                        onChange={(e) => setComplemento(e.target.value.toLowerCase())}/>
                                </span>
                            </div>
                        </div>

                        <div id = 'tarefa_dados'>

                            <div id = 'campo_novo_cliente'>
                                <span>
                                    <p>CEP</p>
                                    <input
                                        id = 'input_cep_novo_cliente' 
                                        type="text"
                                        minLength={11}
                                        maxLength={11}
                                        value={cep}
                                        placeholder = ' '
                                        onChange = {cepChange}
                                        required/>
                                </span>

                                <span>
                                    <p>Cidade</p>
                                    <input
                                        id = 'input_cidade_novo_cliente' 
                                        type="text"
                                        value={cidade}
                                        placeholder = ' '
                                        onChange={(e) => setCidade(e.target.value.toLowerCase())}
                                        required/>
                                </span>

                                <span>
                                    <p>Estado</p>
                                    <select id="input_estado_novo_cliente"  value = {estado} onChange = {(e) => setEstado(e.target.value)} required placeholder = ' '>
                                        <option value = 'acre'>ACRE</option>
                                        <option value= 'alagoas'>ALAGOAS</option>
                                        <option value = 'amapá'>AMAPÁ</option>
                                        <option value = 'amazonas'>AMAZONAS</option>
                                        <option value = 'bahia'>BAHIA</option>
                                        <option value = 'ceará'>CEARÁ</option>
                                        <option value = 'espírito santo'>ESPÍRITO SANTOS</option>
                                        <option value = 'goiás'>GOIÁS</option>
                                        <option value = 'maranhão'>MARANHÃO</option>
                                        <option value = 'mato grosso'>MATO GROSSO</option>
                                        <option value = 'mato grosso do sul'>MATO GROSSO DO SUL</option>
                                        <option value = 'minas gerais'>MINAS GERAIS</option>
                                        <option value = 'pará'>PARÁ</option>
                                        <option value = 'paraíba'>PARAÍBA</option>
                                        <option value = 'paraná'>PARANÁ</option>
                                        <option value = 'pernanbuco'>PERNAMBUCO</option>
                                        <option value = 'piauí'>PIAUÍ</option>
                                        <option value = 'rio de janeiro'>RIO DE JANEIRO</option>
                                        <option value = 'rio grande do norte'>RIO GRANDE DO NORTE</option>
                                        <option value = 'rio grande do sul'>RIO GRANDE DO SUL</option>
                                        <option value = 'rondônica'>RONDÔNIA</option>
                                        <option value = 'roraima'>RORAIMA</option>
                                        <option value = 'santa catarina'>SANTA CATARINA</option>
                                        <option value = 'são paulo'>SÃO PAULO</option>
                                        <option value = 'sergipe'>SERGIPE</option>
                                        <option value = 'tocantins'>TOCANTINS</option>
                                    </select>
                                </span>
                            </div>
                        </div>

                        <div id = 'tarefa_dados'>
                            <span>
                                <p>Selecione o Responsável do Cliente</p>
                                <select id="input_responsavel_novo_cliente"  value = {responsavel} onChange = {(e) => setResponsavel(parseInt(e.target.value))} required>
                                    <option></option>
                                    {responsavelLista.map((responsavel) => (
                                        <option key={responsavel.id} value={responsavel.id}>{responsavel.nome.toUpperCase()}</option>
                                    ))}
                                </select>
                            </span>
                        </div>

                        <div id="novo_cliente_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovoCliente