import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { useState, FormEvent } from 'react'
import { setOcultarModalNovoLead } from '../../store/modules/modalNovoLead'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { novoLead } from '../../services/hg/leads'
import { telefoneMascara } from '../../helpers'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './modalnovolead.css'

function ModalNovoLead () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)

    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [empresa, setEmpresa] = useState<null | string>(null)
    const [formaConhecimento, setFormaConhecimento] = useState ('')
    const [mensagem, setMensagem] = useState('')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovoLead ({
            exibir: false
        }))
    }

    const telefoneChange = (e: any) => {

        const { value } = e.target

        setTelefone (telefoneMascara (value))
    }

    const cadastrarLead = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            nome,
            telefone,
            empresa,
            email,
            formaConhecimento,
            mensagem
        }

        try {
            const resposta = await novoLead (payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2500)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 2000)
        }
    }

    return (
        <div id = 'fundo_modal_nova_tarefa'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionar_tarefa">
                <div id="tarefa_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Novo Lead</p>
                </div>

                <div id="processoDados">
                    <form id = 'form_nova_tarefa' onSubmit={cadastrarLead}>        
                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_cliente'>
                                <span>
                                    <p>Nome</p>
                                    <input 
                                        id = 'novoLeadNome' 
                                        type="text" 
                                        value={nome}
                                        required
                                        maxLength={45}
                                        onChange={(e) => setNome (e.target.value)}/>
                                </span>

                                <span>
                                    <p>Telefone</p>
                                    <input 
                                        id = 'novoLeadTelefone' 
                                        type="text" 
                                        value={telefone}
                                        required
                                        minLength={18}
                                        maxLength={18}
                                        onChange={telefoneChange}/>
                                </span>

                                <span>
                                    <p>E-mail</p>
                                    <input 
                                        id = 'novoLeadNome' 
                                        type="email" 
                                        value={email}
                                        required
                                        maxLength={45}
                                        onChange={(e) => setEmail (e.target.value)}/>
                                </span>
                            </div> 
                        </div>

                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_cliente'>
                                <span>
                                    <p>Empresa</p>
                                    <input 
                                        id = 'novoLeadNome' 
                                        type="text" 
                                        value={empresa !== null ? empresa : ''}
                                        maxLength={45}
                                        onChange={(e) => setEmpresa (e.target.value)}/>
                                </span>

                                <span>
                                    <p>Forma de Conhecimento</p>
                                    <select name="formaConhecimentoLead" id="novoLeadFormaConhecimento" onChange={(e) => setFormaConhecimento (e.target.value)} required>
                                        <option value=""></option>
                                        <option value="google">Google</option>
                                        <option value="facebook">Facebook</option>
                                        <option value="instagram">Instagram</option>
                                        <option value="indicação de amigos">Indicação de Amigos</option>
                                        <option value="participação em eventos">Participação em Eventos</option>
                                        <option value="outros">Outros</option>
                                    </select>
                                </span>
                            </div> 
                        </div>

                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_data'>
                                <span>
                                    <p>Mensagem</p>
                                    <textarea 
                                    name="descricao" 
                                    id="novoLeadMensagem" 
                                    rows={10}
                                    value ={mensagem}
                                    required 
                                    placeholder="Descreva o que o cliente está procurando resolver na empresa dele"
                                    onChange={(e) => setMensagem (e.target.value)}/>
                                </span>
                            </div>
                        </div>

                        <div id="tarefa_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_tarefa' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovoLead