import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import FiltrosVendas from "../../components/FiltrosVendas";
import ListaDeVendas from "../../components/ListaDeVendas";
import ModalNovaVenda from "../../components/ModalNovaVenda";

function Vendas() {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaVenda)

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - Faturamento'
    }, [])

    return (
        <DivDados id = 'dados_vendas_fundo'>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    {modal && <ModalNovaVenda />}
                    <FiltrosVendas />
                    <ListaDeVendas />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Vendas