import { useState, useEffect } from 'react'
import { autorizacao } from '../Token'
import Perfil from '../../middleware/perfil'
import clientesAtivos  from '../../services/hg/dashboard/clientesativos'
import clientesMes from '../../services/hg/dashboard/clientesmes'
import clientesInativos from '../../services/hg/dashboard/clientesinativos'
import clientesTotais from '../../services/hg/dashboard/clientestotais'
import clientesAtivosImg from '../../assets/img/clientes_ativos.png'
import clientesMesImg from '../../assets/img/clientes_novos.png'
import clientesInativosImg from '../../assets/img/clientes_inativos.png'
import clientesTotaisImg from '../../assets/img/clientes_totais.png' 
import CarregandoBarra from '../CarregandoBarra'
import './clientesdashboard.css'
import GraficoClienteCidades from '../GraficoClienteCidade'
import GraficoClientesAtivosoMes from '../GraficoClientesAtivosMes'


function ClientesDashboard () {


    const [carregando, setCarregando] = useState(false)

    const [clientesAtivosCarregando, setClientesAtivosCarregando] = useState (false)
    const [clientesAtivosQuantidade, setClientesAtivosQuantidade] = useState <string> ('')

    const [clientesMesCarregando, setClientesMesCarregando] = useState (false)
    const [clientesMesQuantidade, setClientesMesQuantidade] = useState <string> ('')

    const [clientesInativoCarregando, setClientesInativoCarregando] = useState (false)
    const [clientesInativosQuantidade, setClientesInativoQuantidade] = useState <string> ('')

    const [clientesTotaisCarregando, setClientesTotaisCarregando] = useState (false)
    const [clientesTotaisQuantidade, setClientesTotaisQuantidade] = useState <string> ('')

    useEffect (() => {
        autorizacao ()
    }, [])

    useEffect (() => {

        const clientesAtivosDashboard = async () => {

            
            setCarregando (true)
            try {
                const resposta = await clientesAtivos ()
                setClientesAtivosQuantidade (resposta.data)
                setCarregando (false)
                setClientesAtivosCarregando (true)
        
            } catch (error: any) {
    
            }
        }

        clientesAtivosDashboard ()
        
    }, [setClientesAtivosQuantidade])

    useEffect (() => {

        const clientesMesDashboard = async () => {
            
            setCarregando (true)
            try {
                const resposta = await clientesMes ()
                setClientesMesQuantidade (resposta.data)
                setClientesMesCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
                
            }
        }

        clientesMesDashboard ()
        
    }, [setClientesMesQuantidade])

    useEffect (() => {

        const clientesInativoDashboard = async () => {
        

            setCarregando (true)
            try {
                const resposta = await clientesInativos ()
                setClientesInativoQuantidade (resposta.data)
                setClientesInativoCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
    
            }
        }

        clientesInativoDashboard ()
        
    }, [setClientesInativoQuantidade])

    useEffect (() => {

        const clientesTotaisDashboard = async () => {
            
            setCarregando (true)
            try {
                const resposta = await clientesTotais ()
                setClientesTotaisQuantidade (resposta.data)
                setClientesTotaisCarregando (true)
                setCarregando (false)
        
            } catch (error: any) {
                
            }
        }

        clientesTotaisDashboard ()
        
    }, [setClientesTotaisQuantidade])

    return (
        <>
            <Perfil perfil= {['master']}>
                <div id = 'dashboard_clientes_dados'>
                    <div id = 'clientes_dashboard'>
                        <div id = 'quantidade_clientes'>
                            {carregando && <CarregandoBarra />}
                            {clientesAtivosCarregando && <h1>{clientesAtivosQuantidade}</h1>}
                            <p>Clientes Ativos</p>
                        </div>
                        <div>
                            <img id = 'clientes_ativos' src= {clientesAtivosImg} alt="Clientes ativos do Hg" width="90px"></img>
                        </div>
                    </div>

                    <div id = 'clientes_dashboard'>
                        <div id = 'quantidade_clientes'>
                            {carregando && <CarregandoBarra />}
                            {clientesMesCarregando && <h1>{clientesMesQuantidade}</h1>}
                            <p>Clientes do Mês</p>
                        </div>
                        <div>
                            <img id = 'clientes_mes' src= {clientesMesImg} alt="Clientes ativos do Hg" width="90px"></img>
                        </div>
                    </div>  

                    <div id = 'clientes_dashboard'>
                        <div id = 'quantidade_clientes'>
                            {carregando && <CarregandoBarra />}
                            {clientesInativoCarregando && <h1>{clientesInativosQuantidade}</h1>}
                            <p>Clientes Inativos</p>
                        </div>
                        <div>
                            <img id = 'clientes_inativos' src= {clientesInativosImg} alt="Clientes ativos do Hg" width="90px"></img>
                        </div>
                    </div> 

                    <div id = 'clientes_dashboard'>
                        <div id = 'quantidade_clientes'>
                            {carregando && <CarregandoBarra />}
                            {clientesTotaisCarregando && <h1>{clientesTotaisQuantidade}</h1>}
                            <p>Clientes Totais</p>
                        </div>
                        <div>
                            <img id = 'clientes_totais' src= {clientesTotaisImg} alt="Clientes ativos do Hg" width="90px"></img>
                        </div>
                    </div>
                    <div id = 'graficosDashboard'>
                        <GraficoClienteCidades />
                        <GraficoClientesAtivosoMes />  
                    </div>
                </div>
            </Perfil>
        </>
    )
}

export default ClientesDashboard