import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { clienteId } from '../../services/hg/clients'
import { clientesPayload } from '../../interfaces/client'
import CarregandoBarra from '../CarregandoBarra'
import Btn from '../Btn'
import './clientesDadosSuperior.css'

function ClientesDadosSuperior () {

    const params = useParams ()
    const navegate = useNavigate()
    const [cliente, setCliente] = useState<clientesPayload> ()
    const [carregando, setCarregando] = useState (true)
    const [resultado, setResultado] = useState (false)

    const voltar = () => {
        return navegate ('/clientes')
    }

    useEffect (() => {

        const { idCliente } =  params

        const clienteDados = async () => {

            try {
    
                const resposta = await clienteId (idCliente)
                setCliente (resposta.data)
                setResultado (true)
                setCarregando (false)
                
            } catch (error) {
    
                return alert ('Falha ao Recuperar os Dados do Cliente')
            }
        }

        clienteDados ()

    }, [params])

    return (
        <div id = 'cliente_dados_superior'>
            <div id = 'lado_esquerdo'>
                <div id = 'cliente_dados_resumo'>
                    <p>CLIENTE</p>
                    {carregando && <CarregandoBarra />}
                    {resultado && <h1>{cliente?.nome_fantasia}</h1>}
                </div>

                <div id = 'cliente_dados_resumo'>
                    <p>RAZÃO SOCIAL</p>
                    {carregando && <CarregandoBarra />}
                    {resultado && <h1>{cliente?.razao_social}</h1>}
                </div>

                <div id = 'cliente_dados_resumo'>
                    <p>SITUAÇÃO</p>
                    {carregando && <CarregandoBarra />}
                    {resultado && <h1>{cliente?.situacao}</h1>}
                </div>
            </div>
            <Btn id = 'voltar_clientes_dados' titulo='VOLTAR' cor='cancelar' acao={voltar}></Btn>
        </div>
    )
}

export default ClientesDadosSuperior