import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovaVendaReduce = createSlice ({
    name: 'modal_nova_sugestao',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovaVenda (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovaVenda (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovaVenda, setOcultarModalNovaVenda } = modalNovaVendaReduce.actions

export default modalNovaVendaReduce.reducer