import { FormEvent, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalNovoCliente } from '../../store/modules/modalNovoCliente'
import { setFiltrosClientes } from '../../store/modules/filtrosClientes'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import ModalNovoCliente from '../ModalNovoCliente'
import Btn from '../Btn'
import './filtrosclientes.css'

function FiltrosClientes () {

    const modalEstado = useSelector ((store: RootStore) => store.modalNovoCliente)
    const [termo, setTermo] = useState ('')
    const [situacao, setSituacao] = useState('ativo')
    const [modal, setModal] = useState (true)
    const dispatch = useDispatch()

    function filtrosClientes (e: FormEvent) {
        e.preventDefault ()

        dispatch ( setFiltrosClientes ({
            termo,
            situacao
        }))
    }

    function exibirModalNovoCliente () {
        dispatch (setExibirModalNovoCliente ({
            exibir: true,
        }))
    }

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (
        <div id = 'filtro'>
            {modal && <ModalNovoCliente />}
            <form id = 'lado_esquerdo' onSubmit={filtrosClientes}>
                <div id = 'caixa_pesquisa_cliente'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_cliente' 
                        placeholder="busque pelo nome fantasia ou razao social"
                        onChange={(e) => setTermo(e.target.value)}/>
                    <img id = 'icone_pesquisa_cliente' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>
                <select value={situacao} className = "situacao" onChange={(e) => setSituacao(e.target.value)}>
                    <option id = 'situacao' value="ativo">ATIVO</option>
                    <option id = 'situacao' value= 'inativo'>INATIVO</option>
                </select>
                <Btn id = 'pesquisar_cliente' titulo = 'PESQUISAR' cor='novo'/>
            </form>
            <Btn id = 'novo_cliente' titulo='NOVO CLIENTE' cor='novo' acao={exibirModalNovoCliente}></Btn>
        </div>
    )
}

export default FiltrosClientes