import baseApiHg from "../api/config"
import { listaProcessosFiltrosPayload, cadastrarProcessoPayload } from "../../../interfaces/processos"

export function cadastrarNovoProcesso (payload: cadastrarProcessoPayload) {
    return baseApiHg.post('/processos', payload)
}

export function listaProcessosEmAndamento (payload: listaProcessosFiltrosPayload) {

    if (payload.situacao === '' && payload.responsavel === '' && payload.titulo === '') {
        return baseApiHg.get(`/processos?&perfil=${payload.perfil}`)
    } 

    if (payload.situacao === '' && payload.responsavel !== '' && payload.titulo === '') {
        return baseApiHg.get(`/processos?&perfil=${payload.perfil}&responsavel=${payload.responsavel}`)
    }

    if (payload.titulo !== '' && payload.situacao === '' && payload.responsavel === '') {
        return baseApiHg.get(`/processos?&perfil=${payload.perfil}&titulo=${payload.titulo}`)
    }

    if (payload.titulo !== '' && payload.situacao !== '' && payload.responsavel === '') {
        return baseApiHg.get(`/processos?&perfil=${payload.perfil}&titulo=${payload.titulo}&situacao=${payload.situacao}`)
    } 
    
    if (payload.titulo !== '' && payload.responsavel !== '') {
        return baseApiHg.get(`/processos?&perfil=${payload.perfil}&titulo=${payload.titulo}&responsavel=${payload.responsavel}`)
    }else {
        return baseApiHg.get(`/processos?responsavel=${payload.responsavel}&titulo=${payload.titulo}&situacao=${payload.situacao}&perfil=${payload.perfil}`)
    }
}

export function detalhesProcesso (id: string) {
    return baseApiHg.get(`/processos/${id}`)
}