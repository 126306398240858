import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom'
import { useEffect, useState } from "react";
import { RootStore } from "../../store";
import DivDados from "../../components/DivDados";
import FiltrosTarefas from "../../components/FiltrosTarefas";
import ListaDeTarefas from "../../components/ListaDeTarefas";
import ModalNovatarefa from "../../components/ModalNovaTarefa";

function Tarefas () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaTarefa)
    const { tipo } = useParams ()

    const [modal, setModal] = useState (false)

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {
        document.title = 'Netclick - ' + tipo?.charAt(0).toUpperCase() + tipo?.substring(1) + 's'
    }, [tipo])

    return (
        <DivDados id = 'dados_tarefas_fundo'>
            <>  
                {modal && <ModalNovatarefa />}
                <FiltrosTarefas />
                <ListaDeTarefas />
            </>
        </DivDados>
    )
  }
  
  export default Tarefas