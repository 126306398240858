import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalDadosVenda } from '../../store/modules/modalVisualizarDadosVenda'
import { vendasListaResposta } from '../../interfaces/vendas'
import { listaVenda } from '../../services/hg/venda'
import CarregandoCirculo from '../CarregandoCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import VisualizarDadosVenda from '../VisualizarDadosVenda'
import './listarvendas.css'

function ListaDeVendas () {

    const modalVisualizarDadosVendaEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosVenda)
    const modalNovaVendaEstado = useSelector((store: RootStore) => store.modalNovaVenda )
    const filtrosVendas = useSelector((store: RootStore) => store.filtrosVendas)
    const dispatch = useDispatch()
    
    const [modal, setModal] = useState (false)
    const [vendas, setVendas] = useState<vendasListaResposta []> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)

    function exibirModal (id: number) {
        dispatch (setExibirModalDadosVenda ({
            exibir: true,
            id: id
        }))
    }

    useEffect (() => {
        setModal (modalVisualizarDadosVendaEstado.exibir)
    }, [modalVisualizarDadosVendaEstado, modal])

    useEffect (() => {
        const listadeVendas = async () => {

            setSolicitacao (true)

            const payload = {
                dataInicial: filtrosVendas.dataInicial,
                dataFinal: filtrosVendas.dataFinal,
                cliente: filtrosVendas.cliente
            }
            try {
                const resposta = await listaVenda (payload)
                setVendas(resposta.data)
                setSolicitacao (false)
                setResultadoPesquisa (false)

                if (resposta.data.length === 0) {
                    setResultadoPesquisa (true)
                }

            } catch (error) {
                console.log (error)
            }
        }

        listadeVendas ()

    }, [filtrosVendas, modalNovaVendaEstado, modalVisualizarDadosVendaEstado])

    return (
        <div id = 'dados_vendas'>
            {modal && <VisualizarDadosVenda />}
            {solicitacao && <CarregandoCirculo />}
            {<table id = 'tabela_vendas'>
                <thead>
                    <tr id = 'venda_Cabecalho'>
                        <th id = 'id_venda'>ID VENDA</th>
                        <th id = 'nome_cliente'>CLIENTE</th>
                        <th id='descricao_venda'>DESCRIÇÃO</th>
                        <th>VENDEDOR</th>
                    </tr>
                </thead>

                <tbody>
                    {vendas.map ((venda) => (
                        <tr key={Math.random()} id = 'lista_vendas' onClick={() => exibirModal(venda.id)}>
                            <td id = 'id_venda'>{(`00000${venda.id.toString()}`).slice(-5)}</td>
                            <td id = 'nome_cliente'>{venda.cliente.nome_fantasia.toUpperCase()}</td>
                            <td id = 'descricao_venda'>{venda.descricao.toUpperCase()}</td>
                            <td>
                                <div id = 'usuario_vendedor'>
                                    <img id = 'vendedor_img' src = {venda.usuario.avatar} alt = 'img do vendedor' />
                                    {venda.usuario.nome.toUpperCase()}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeVendas