import { useState, FormEvent } from 'react'
import { RootStore } from '../../store'
import { useDispatch, useSelector } from 'react-redux'
import { setFiltrosVendas } from '../../store/modules/filtrosVendas'
import { setExibirModalNovaVenda } from '../../store/modules/modalNovaVenda'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './vendas.css'

function FiltrosVendas () {

    const filtros = useSelector ((store: RootStore) => store.filtrosVendas)

    const [dataInicial, setDataInicial] = useState (`${filtros.dataInicial}`)
    const [dataFinal, setDataFinal] = useState (`${filtros.dataFinal}`)
    const [cliente, setCliente] = useState('')
    const dispatch = useDispatch()

    function exibirModal () {
        dispatch (setExibirModalNovaVenda ({
            exibir: true
        }))
    }

    function filtrosVendas (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosVendas ({
            dataInicial,
            dataFinal,
            cliente
        }))
    }

    return (
        <div id = 'filtro'>
            <form id = 'lado_esquerdo_venda' onSubmit={filtrosVendas}>
                <div id = 'input_data'>
                    <label>DATA INICIAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date' 
                        required
                        value={dataInicial}
                        onChange={(e) => setDataInicial (e.target.value)}/>
                </div>

                <div id = 'input_data'>
                    <label>DATA FINAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date'
                        required
                        value={dataFinal}
                        onChange= {(e) => setDataFinal (e.target.value)}/>
                </div>
                <div id = 'caixa_pesquisa'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_venda' 
                        placeholder="busque pelo nome fantasia do cliente" 
                        onChange={(e) => setCliente(e.target.value)}/>
                    <img id = 'icone_pesquisa_venda' src= {pesquisarImg} alt = 'caixa de pesquisa das vendas'></img>
                </div>

                <Btn id = 'pesquisar_venda' titulo='PESQUISAR' cor='novo' />
            </form>
            <div id = 'lado_direiro_assinatura'>
                <Btn acao={exibirModal} id = 'nova_venda' titulo='NOVA VENDA' cor='novo' />
            </div>
        </div>
    )
}

export default FiltrosVendas