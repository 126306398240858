import { useEffect } from 'react'
import DivDados from "../../components/DivDados";
import MeuPainel from '../../components/MeuPainel';

function Painel () {

    useEffect (() => {
        document.title = 'Netclick - Meu Painel'
    }, [])

    return (
        <DivDados id ='dados_meu_painel'>
            <MeuPainel />
        </DivDados>
    )
  }
  
  export default Painel;