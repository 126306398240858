import { useEffect, useState } from 'react'
import { Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { ClientesCidades } from '../../services/hg/graficos'
import CarregandoPontinhos from '../CarregandoPontinhos'
import Perfil from '../../middleware/perfil'
import './graficoclientescidades.css'

function GraficoClienteCidades () {

    const [carregando, setCarregando] = useState (true)
    const [grafico, setGrafico] = useState (false)
    const [dados, setDados] = useState ([])
    const cores = ['#261132', '#00A6A6', '#F7B558', '#67284C', '#FF8042']

    const raio = Math.PI / 180;
    const renderizarLabelCustomizada = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * raio);
        const y = cy + radius * Math.sin(-midAngle * raio);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    useEffect (() => {

        const graficoCliente = async () => {
            try {
                const resposta = await ClientesCidades ()
                setDados (resposta.data)
                setCarregando (false)
                setGrafico (true)

            } catch (error) {
                
            }
        }

        graficoCliente ()
    }, [])


    return (
        <Perfil perfil = {['master']}>
            <div id = 'grafico_cliente_cidades'>
                <p id = 'p_clientes_cidades'>PRINCIPAIS CIDADES ATENDIDAS</p>
                {carregando && <CarregandoPontinhos cor = 'pontinhosFundoBranco' />}
                {grafico && <ResponsiveContainer>
                    <PieChart>
                        <Pie 
                            data={dados} 
                            nameKey = 'cidade' 
                            dataKey = 'quantidade' 
                            label = {renderizarLabelCustomizada}>
                            {dados.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={cores[index % cores.length]} />
                            ))}
                        </Pie>
                        <Legend verticalAlign="bottom" height={40}/>
                    </PieChart>
                </ResponsiveContainer>}
            </div>
        </Perfil>
        
    )
}

export default GraficoClienteCidades