import baseApiHg from "../api/config"
import { tarefasCadastrarPayload, tarefasParametrosRequisicaoPaylod, tarefaRequisicaoPaylod, tarefaAutoRequisicaoPaylod, tarefaAtualizarPayload, tarefaAtualizarLotePayload } from "../../../interfaces/tasks"

export function novaTarefa (payload: tarefasCadastrarPayload) {
    return baseApiHg.post('/tarefas', payload)
}

export function listaTarefas (params: tarefasParametrosRequisicaoPaylod, payload: tarefaRequisicaoPaylod) {

    if (payload.situacao === '' && payload.responsavel === '') {
        return baseApiHg.get (`/tarefas?tipo=${params.tipo}&perfil=${params.perfil}&dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}`)
    }

    if (payload.situacao === '' && payload.responsavel !== '') {
        return baseApiHg.get(`/tarefas?tipo=${params.tipo}&perfil=${params.perfil}&responsavel=${payload.responsavel}&dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}`)
    } 

    if (payload.situacao !== '' && payload.responsavel === '') {
        return baseApiHg.get(`/tarefas?tipo=${params.tipo}&perfil=${params.perfil}&dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}&situacao=${payload.situacao}`)
    } else {
        return baseApiHg.get(`/tarefas?tipo=${params.tipo}&perfil=${params.perfil}&responsavel=${payload.responsavel}&dataInicial=${payload.dataInicial}&dataFinal=${payload.dataFinal}&situacao=${payload.situacao}`)
    }
}

export function tarefasAuto (payload: tarefaAutoRequisicaoPaylod) {
    return baseApiHg.get(`/tarefasAuto?cliente=${payload.cliente}&servico=${payload.servico}&responsavel=${payload.responsavel}`)
}

export function tarefaDetalhes (idTarefa: number) {
    return baseApiHg.get(`/tarefas/${idTarefa}`)
}

export function atualizarTarefa (idTarefa: number, payload: tarefaAtualizarPayload) {
    return baseApiHg.patch (`/tarefas/${idTarefa}`, payload)
}

export function atualizarLoteTarefa (payload: tarefaAtualizarLotePayload) {
    return baseApiHg.patch ('/tarefasLote', payload)
}

export function deletarTarefa (idTarefa: number) {
    return baseApiHg.delete (`/tarefas/${idTarefa}`)
}