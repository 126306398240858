import { useState, FormEvent, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { usuariosAtivos } from '../../services/hg/usuarios'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { setFiltroAutoTarefas } from '../../store/modules/filtrosTarefasAuto'
import { setExibirModalNovaTarefa } from '../../store/modules/modalNovaTarefa'
import listaDeServicos from '../../services/hg/servicosLista'
import servicosListaPayload from '../../interfaces/servicosListaPayload'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './tarefasauto.css'

function FiltrosTarefasAuto () {

    const [cliente, setCliente] = useState ('')
    const [servico, setServico] = useState ('')
    const [servicoLista, setServicoLista] = useState<servicosListaPayload []>([])
    const [usuarios, setUsuario] = useState<UsuariosAtivos []> ([])
    const [responsavel, setResponsavel] = useState ('')
    const dispatch = useDispatch()

    function exibirModal () {
        dispatch (setExibirModalNovaTarefa ({
            exibir: true
        }))
    }

    function filtrosTarefas (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltroAutoTarefas ({
            servico,
            responsavel,
            cliente
        }))
    }

    useEffect (() => {

        const listaServicos = async () => {
        
            try {
                const resposta = await listaDeServicos()
                setServicoLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de Serviços')
            }
        }

        listaServicos ()

    }, [])

    useEffect (() => {

        const responsavelAtivos = async () => {
            
            try {
                const resposta = await usuariosAtivos ()

                setUsuario (resposta.data)

            } catch (error) {
                
            }
        }

        responsavelAtivos ()

    }, [])

    return (
        <div id = 'filtro_tarefa'>
            <form id = 'filtros_auto_tarefa' onSubmit={filtrosTarefas}>
                <div id = 'lado_esquerdo_auto_tarefas'>
                    <div id = 'caixa_pesquisa'>
                        <input 
                            type = 'text' 
                            id = 'input_pesquisa_venda' 
                            placeholder="busque pelo nome fantasia do cliente" 
                            onChange={(e) => setCliente(e.target.value)}/>
                        <img id = 'icone_pesquisa_venda' src= {pesquisarImg} alt = 'caixa de pesquisa das tarefas automáticas'></img>
                    </div>

                    <div id = 'input_servico_auto_tarefa'>
                        <label>SERVIÇO</label>
                        <select className = "select_auto_servicos" value={servico} onChange={(e) => setServico(e.target.value)}>
                            <option id = 'situacao' value = ''>TODOS</option>
                            {servicoLista.map ((servico) => (
                                <option key={servico.id} value={servico.id}>{servico.nome.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                    
                    <div id = 'input_responsavel_tarefa'>
                        <label>RESPONSÁVEL</label>
                        <select className = "select_responsavel_tarefa" value={responsavel} onChange={(e) => setResponsavel(e.target.value)}>
                            <option id = 'situacao' value = ''>TODOS</option>
                            {usuarios.map ((usuario) => (
                                <option key={usuario.id} value={usuario.id}>{usuario.nome.toUpperCase()}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div id = 'lado_direiro_tarefas'>
                    <Btn id = 'pesquisar_automacao_tarefa' titulo='PESQUISAR' cor='novo' />
                </div>
            </form>
        </div>
    )
}

export default FiltrosTarefasAuto