import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import { listaLeadsRespostaPayload } from '../../interfaces/leads'
import { listarLeads } from '../../services/hg/leads'
import { setExibirModalDadosLead } from '../../store/modules/modalVisualizarDadosLeads'
import CarregandoCirculo from '../CarregandoCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import ErrorOperacao from '../ErroOperacao'
import DivSituacao from '../DivSituacao'
import './listaleads.css'

function ListaLeads () {

    const filtrosLeads = useSelector((store: RootStore) => store.filtrosLeads)
    const modalEstado =  useSelector ((store: RootStore) => store.modalNovaAssinatura)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()

    const [listaDeLeads, setListaDeLeads] = useState<listaLeadsRespostaPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (false)
    const [resultadoPesquisa, setResultadoPesquisa] = useState(false)
    const [erroOperacao, setErroOperacao] = useState(false)

    function detalhesLead (id: string) {

        dispatch (setExibirModalDadosLead ({
            exibir: true,
            id: id
        }))
    }

    useEffect (() => {
        
        const listaLeads = async () => {

            setSolicitacao (true)

            const payload = {
                nome: filtrosLeads.nome,
                situacao: filtrosLeads.situacao,
            }

            try {

                const resposta = await listarLeads (payload)
                setResultadoPesquisa (false)
                setListaDeLeads (resposta.data)
                setSolicitacao (false)

                if (resposta.data.length === 0) {
                    return setResultadoPesquisa (true)
                }

            } catch (error) {
                setErroOperacao (true)

                return setTimeout (() => {
                    setErroOperacao (false)
                }, 2500)

            }
        }

        listaLeads ()

    }, [filtrosLeads, modalEstado, atualizarDados])


    return (
        <div id = 'fundoProcessosEmAndamento'>
            {solicitacao && <CarregandoCirculo />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Recuperar os Dados' />}
            <div id = 'processosEmAndamento'>
                {listaDeLeads.map( lead => (
                    <section key={lead.id} id = 'cardProcessosAndamento' onClick={() => detalhesLead(lead.id)}>
                        <span id = 'processoCliente'>
                            <span>
                                <DivSituacao texto = {lead.situacao.toUpperCase()} cor = {lead.situacao.toLowerCase().replace(/ /g, "")}/>
                            </span>
                            <span>                
                            </span>
                            <span>
                                <b id ='nomeClienteProcesso'>{lead.nome.toUpperCase()}</b>
                                <p>{lead.empresa?.toUpperCase()}</p>
                                <p>{lead.telefone}</p>
                            </span>
                        </span>
                    </section>
                ))}
            </div>
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaLeads