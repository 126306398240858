import { useState, FormEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { useDispatch } from 'react-redux'
import { setFiltrosFinanceiro } from '../../store/modules/filtrosFinanceiro'
import anteriorImg from '../../assets/img/anterior.png'
import proximoImg from '../../assets/img/proximo.png'
import './filtrosfinanceiro.css'

function FiltrosFinanceiro () {

    const data = new Date ()

    const [ano, setAno] = useState (data.getFullYear())
    const dispatch = useDispatch()

    useEffect (() => {

        function filtrosFinanceiro () {

            dispatch (setFiltrosFinanceiro ({
                ano
            }))
        }

        filtrosFinanceiro ()
    }, [ano, dispatch])

    

    return (
        <form id = 'filtro'>
            <div id = 'lado_esquerdo_financeiro'>
                <div id = 'div_ano_financeiro'>
                    <img id = 'anterior_img' src = {anteriorImg} alt = 'incone de anterior' onClick={(e) => setAno (ano - 1)}/>
                    <h1 id = 'ano_financeiro'>{ano}</h1>
                    <img id = 'proximo_img' src = {proximoImg} alt = 'incone de próximo' onClick={(e) => setAno (ano + 1)}/>
                </div>
            </div>
        </form>
    )
}

export default FiltrosFinanceiro