import GraficoAtendimento from '../GraficoAtendimento'
import GraficoAvaliacaoAtendimento from '../GraficoAvaliacaoAtendimento'
import ResumoTarefas from '../ResumoTarefas'
import UltimosAtendimentos from '../UltimasAtividadesConcluidas'
import './meupainel.css'

function MeuPainel () {
    return (
        <div id = 'meuPainelDados'>
            <ResumoTarefas />
            <UltimosAtendimentos />
            <GraficoAtendimento/>
            <GraficoAvaliacaoAtendimento />
        </div>
    )
}

export default MeuPainel