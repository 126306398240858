import { useState, FormEvent } from 'react'
import { useDispatch } from 'react-redux'
import { setFiltrosBoletos } from '../../store/modules/filtrosBoletos'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import './filtrosboletos.css'
import Btn from '../Btn'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'

function FiltrosBoletos () {

    const filtros = useSelector((store: RootStore) => store.filtrosBoletos)

    const [dataInicial, setDataInicial] = useState (`${filtros.dataInicial}`)
    const [dataFinal, setDataFinal] = useState (`${filtros.dataFinal}`)
    const [situacao, setSituacao] = useState ('')
    const [cliente, setCliente] = useState('')
    const dispatch = useDispatch()

    function filtrosBoletos (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosBoletos ({
            dataInicial,
            dataFinal,
            situacao,
            cliente
        }))
    }

    return (
        <form id = 'filtro' onSubmit={filtrosBoletos}>
            <div id = 'lado_esquerdo_boleto'>
                <div id = 'input_data'>
                    <label>DATA INICIAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date' 
                        required
                        value={dataInicial}
                        onChange={(e) => setDataInicial(e.target.value)}/>
                </div>

                <div id = 'input_data'>
                    <label>DATA FINAL</label>
                    <input 
                        id = 'filtro_data' 
                        type = 'date'
                        required
                        value={dataFinal} 
                        onChange={(e) => setDataFinal (e.target.value)}/>
                </div>

                <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value.replace(/ /g, ""))}>
                    <option id = 'situacao' value = ''>TODOS</option>
                    <option id = 'situacao' value = 'EMABERTO'>EM ABERTO</option>
                    <option id = 'situacao' value = 'VENCIDO'>VENCIDO</option>
                    <option id = 'situacao' value = 'PAGO'>PAGO</option>
                    <option id = 'situacao' value = 'EXPIRADO'>EXPIRADO</option>
                    <option id = 'situacao' value = 'CANCELADO'>CANCELADO</option>
                </select>
            </div>
            <Btn id = 'pesquisa_boleto' titulo='PESQUISAR' cor='novo' />
        </form>
    )
}

export default FiltrosBoletos