import { useEffect } from 'react'
import Perfil from "../../middleware/perfil";
import DivDados from "../../components/DivDados";
import FiltrosClientes from "../../components/FiltrosClientes";
import ListadeClientes from "../../components/ListaDeClientes";

function Clientes() {

    useEffect (() => {
        document.title = 'Netclick - Clientes'
    }, [])

    return (
        <DivDados id = 'dados_clientes_fundo'>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    <FiltrosClientes />
                    <ListadeClientes />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Clientes;