import { useEffect } from 'react'
import DivDados from "../../components/DivDados";
import Perfil from "../../middleware/perfil";
import ContasBancarias from '../../components/ContasBancarias';

function SaldoBancario() {

    useEffect (() => {
        document.title = 'Netclick - Saldo Bancário'
    }, [])

    return (
        <DivDados id = 'dados_assinaturas_fundo'>
            <Perfil perfil={['master', 'administrativo']}>
                <ContasBancarias />
            </Perfil>
        </DivDados>
    )
  }
  
  export default SaldoBancario