import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { clientesPayload } from '../../interfaces/client'
import { listaClientes } from '../../services/hg/clients'
import CarregandoCirculo from '../CarregandoCirculo'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import DivSituacao from '../DivSituacao'
import './listaclientes.css'

function ListaDeClientes () {

    const filtrosClientes = useSelector ((store: RootStore) => store.filtrosClientes)
    const atualizarDados = useSelector((store: RootStore) => store.atualizarDados)
    const navegate = useNavigate ()
    const [clientes, setClientes] = useState<clientesPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (true)
    const [resultadoPesquisa, setResultadoPesquisa] = useState (false)

    const clientesDados =  (idCliente: number) => {

        return navegate (`/cliente/${idCliente}`)

    }

    useEffect (() => {

        const clientesLista = async () => {

            setSolicitacao (true)

            const payload = {
                termo: filtrosClientes.termo,
                situacao: filtrosClientes.situacao
            }

            try {
                const resposta = await listaClientes (payload)
                setSolicitacao (false)
                setClientes(resposta.data)
                setResultadoPesquisa (false)

                if (resposta.data.length === 0) {
                    setResultadoPesquisa (true)
                }

            } catch (error) {
                setSolicitacao (false)
                alert ('Erro ao listar os Clientes')
            }
        }

        clientesLista ()

    }, [setClientes, filtrosClientes, atualizarDados])

    return (
        <div id = 'dados_clientes'>
            {solicitacao && <CarregandoCirculo />}
            {<table id = 'tabela_clientes'>
                <thead>
                    <tr id = 'clienteCabecalho'>
                        <th id = 'nomeFantasia'>NOME FANTASIA</th>
                        <th>RAZÃO SOCIAL</th>
                        <th>TELEFONE</th>
                        <th id = 'cidade'>CIDADE</th>
                        <th align = 'center' id = 'estado'>ESTADO</th>
                        <th id = 'situacao_cliente' align = 'center'>STATUS</th>
                    </tr>
                </thead>

                <tbody>

                    {clientes.map ((cliente) => (
                        <tr key={cliente.id} id = 'listaClientes' onClick={() => clientesDados(cliente.id)}>
                            <td id = 'nomeFantasia'>{cliente.nome_fantasia.toUpperCase()}</td>
                            <td>
                                <div>
                                    {cliente.razao_social.substring(0,35).toUpperCase()}
                                    <p id = 'cnpj_cpf'>{`CNPJ/CPF: ${cliente.cnpj_cpf}`}</p>
                                    <p id = 'cnpj_cpf'>{`RESPONSÁVEL: ${cliente.responsavei.nome.toUpperCase()}`}</p>
                                </div>
                            </td>
                            <td>{cliente.telefone.toUpperCase()}</td>
                            <td id = 'cidade'>{cliente.cidade.toUpperCase()}</td>
                            <td align = 'center' id = 'estado'>{cliente.estado.toUpperCase()}</td>
                            <td id = 'situacao_cliente' align = 'center'>
                                <DivSituacao texto= {cliente.situacao.toUpperCase()} cor = {cliente.situacao.toLowerCase()}/>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {resultadoPesquisa && <NenhumResultadoEncontrado />}
        </div>
    )
}

export default ListaDeClientes