import { useEffect } from 'react'
import Perfil from "../../middleware/perfil";
import DivDados from "../../components/DivDados";
import AssinaturaDados from "../../components/AssinaturasDados";

function AssinaturasDados() {

    useEffect (() => {
        document.title = 'Netclick - Dados da Assinatura'
    }, [])

    return (
        <DivDados id = 'dados_assinatura_fundo'>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
                <>
                    <AssinaturaDados />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default AssinaturasDados