import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { RootStore } from '../../store'
import { setExibirModalNovaTarefaProcesso } from '../../store/modules/modalNovaTarefaProcesso'
import { setOcultarModalDadosProcessosAndamento } from '../../store/modules/modalVisualizarDadosProcessosAndamento'
import { setExibirModalDadosTarefa } from '../../store/modules/modalVisualizarDadosTarefas'
import { detalhesProcesso } from '../../services/hg/processos'
import { detalhesProcessoRespostaPayload } from '../../interfaces/processos'
import CarregandoPontinhos from '../CarregandoPontinhos'
import CarregandoCirculo from '../CarregandoCirculo'
import Btn from '../Btn'
import DivSituacao from '../DivSituacao'
import './visualizardadosprocessosAndamento.css'

function VisualizarDadosProcessosAndamento () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosProcessosAndamento)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()

    const [processo, setProcesso] = useState<detalhesProcessoRespostaPayload>()
    const [carregando, setCarregando] = useState (true)
    const [pontinhos, setPontinhos] = useState (true)
    const [dados, setDados] = useState (false)
    const [processando, setProcessando] = useState (false)
    const [modal, setModal] = useState (false)

    function ocultarModal () {

        dispatch (setOcultarModalDadosProcessosAndamento ({
            exibir: false
        }))
    }

    function tarefaDetalhes (id: number) {

        dispatch (setExibirModalDadosTarefa ({
            exibir: true,
            id: id
        }))
    }

    function exibirModalTarefaProcesso (idProceso: string | undefined) {

        if (idProceso === undefined) {
            return alert ('Processo Não Identificado. Tente Novamente')
        }

        dispatch (setExibirModalNovaTarefaProcesso ({
            exibir: true,
            id: idProceso
        }))
    }

    useEffect (() => {

        const idProcesso = modalEstado.id.toString()
    
        if (idProcesso === undefined || idProcesso === null) {
            return alert
        }

        const detalhesProcessos = async () => {
            
            try {
                const resposta = await detalhesProcesso (idProcesso)

                if (resposta.status === 200) {
                    setProcesso (resposta.data)
                    setPontinhos (false)
                    setCarregando (false)
                    setDados (true)
                    setModal (true)
                }

            } catch (error) {
                return alert ('Falha ao Recuperar os Dados do Processo')
            }
        }

        detalhesProcessos ()

    }, [setModal, modalEstado, atualizarDados])
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (

        <div id = 'fundoModalTarefaProcessoAndamento'>
            {processando && <CarregandoCirculo />}
            {modal && <div id="visualizarTarefaProcessoAndamentoFundo">
                <div id="tarefa_superior_dados">
                    <div id = 'visualizar_dados_superior_titulo'>
                        <div>
                            <p id = 'visualisar_dados_titulo'>CLIENTE</p>
                            {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                            {dados && <input
                             type='text'
                             id = 'visualizar_dados_titulo_tarefa'
                             readOnly
                             value={processo?.cliente.nome_fantasia.toUpperCase()}/>}
                        </div>

                        <div>
                            <p id = 'visualisar_dados_titulo'>PROCESSO</p>
                            {pontinhos && <CarregandoPontinhos cor='pontinhosFundoOutro' />}
                            {dados && <input
                             type='text'
                             id = 'visualizar_dados_titulo_tarefa'
                             readOnly
                             value={processo?.modelos_processo.titulo.toUpperCase()}/>}
                        </div>
                    </div>
                </div>

                <div>
                    <span id = 'AcompanhamentoTarefasProcesso'>
                        <p id = 'descricao_titulo'><strong>Acompanhamento das Tarefas</strong></p>
                        <b id = 'vincularTarefaModelo' className='vincularTarefaProcesso' onClick={() => exibirModalTarefaProcesso(processo?.id)}>Adicionar Tarefa ao Processo</b>
                    </span>
                    <span id = 'listaTarefasProcessoAndamentoAcompanhamento'>
                        <table id = 'tabelaListaTarefasProcessosAndamento'>
                            <thead>
                                <tr>
                                    <th align='left'>TÍTULO</th>
                                    <th align='left'>RESPONSÁVEL</th>
                                    <th align='center'>PRAZO</th>
                                    <th>SITUAÇÃO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {processo?.tarefas.map((tarefa) => (
                                    <tr key={tarefa.id} id = 'linhaProcessoTarefaAndamento' onClick={() => tarefaDetalhes(tarefa.id)}>
                                        <td>{tarefa.titulo.toUpperCase()}</td>
                                        <td>
                                            <span id = 'responsavelTarefaProcessoAncompanhamento'>
                                                <img src ={tarefa.usuario.avatar} alt = ''/>
                                                {tarefa.usuario.nome.toUpperCase()}
                                            </span>
                                        </td>
                                        <td align='center'>{`${tarefa.data_termino.slice(8,10)}/${tarefa.data_termino.slice(5,7)}/${tarefa.data_termino.slice(0,4)}`}</td>
                                        <td align='center'>
                                            <DivSituacao texto={tarefa.situacao.toUpperCase()} cor = {tarefa.situacao.toLowerCase()}/>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>    
                        </table>
                    </span>
                </div>

                <div id="tarefa_botao">
                    <div>
                        <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='SAIR' cor='cancelar' />
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default VisualizarDadosProcessosAndamento