import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { RootStore } from '../../store'
import { setOcultarModalDadosVenda } from '../../store/modules/modalVisualizarDadosVenda'
import { novoBoletoInter } from '../../services/inter/boletos'
import { vendasListaResposta } from '../../interfaces/vendas'
import { NovaFatura } from '../../services/hg/email'
import { detalhesVendas } from '../../services/hg/venda'
import { receitaBoletoPdf, detalhesReceita } from '../../services/hg/receitas'
import visualizarBoletoImg from '../../assets/img/visualizar_boleto.png'
import enviarEmailImg from '../../assets/img/enviar_email.png'
import CarregandoCirculo from '../CarregandoCirculo'
import fecharIcone from '../../assets/img/fechar.png'
import DivSituacao from '../DivSituacao'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import './visualizardadosvenda.css'

function VisualizarDadosVenda () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosVenda)
    const dispatch = useDispatch ()

    const [vendaDetalhes, setVendaDetalhes ] = useState<vendasListaResposta []>([])
    const [processandoSolicitacao, setProcessandoSolicitacao] = useState (false)
    const [modalDetalhes, setModalDetalhes] = useState (true)
    const [itensVendidos, setItensVendidos] = useState (true)
    const [financeiro, setFinanceiro] = useState (false)
    const [confirmacaoEmail, setConfirmacaoEmail ] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    const [modal, setModal] = useState (true)


    function ocultarModal () {

        dispatch (setOcultarModalDadosVenda ({
            exibir: false
        }))
    }

    function exibirItens () {
        setItensVendidos (true)
        setFinanceiro (false)
    }

    function exibirFinanceiro () {
        setItensVendidos (false)
        setFinanceiro (true)
    }

    function receitaNovoBoleto (idReceita: number) {
        
        const novoBoleto = async () => {

            setProcessandoSolicitacao (true)

            try {
                
                const resposta = await novoBoletoInter (idReceita)
                setProcessandoSolicitacao (false)

                return visualizarBoletoVendaPdf (idReceita)

            } catch (error) {
                setProcessandoSolicitacao(false)
                return alert ('Ocorreu um Erro. Tente Novamente')
            }
        }

        novoBoleto ()
    }

    function visualizarBoletoVendaPdf (idReceita: number) {

        const visualizarPdf = async () => {

            setProcessandoSolicitacao (true)

            try {
                const resposta = await receitaBoletoPdf (idReceita)
                const pdf = resposta.data.boleto

                if (pdf !== null) {
                    setProcessandoSolicitacao (false)
                    return window.open(pdf, '_blank')
                }

                return receitaNovoBoleto (idReceita)

            } catch (error) {
                setProcessandoSolicitacao (true)
                
                setTimeout (() => {
                    return setProcessandoSolicitacao (false)
                }, 2500)
            }
        }

        visualizarPdf ()

    }

    function emailFatura (idReceita: number) {

        
        const enviarEmail = async () => {
            
            setProcessandoSolicitacao (true)

            try {

                const boleto = await detalhesReceita (idReceita)

                if (boleto.data.boleto === null) {

                    setErroOperacao (true)

                    return setTimeout (() => {
                        setProcessandoSolicitacao (false)
                        setErroOperacao (false)
                    }, 2500)
                }
                
                const resposta = await NovaFatura (idReceita)

                setProcessandoSolicitacao (false)
                setConfirmacaoEmail(true)

                setTimeout (() => {
                    return setConfirmacaoEmail (false)
                }, 3000)

            } catch (error) {
                console.log (error)
                setProcessandoSolicitacao (false)
                return alert ('Falha ao Enviar o Email. Tente Novamente!')
            }
        }

        enviarEmail ()
    }


    useEffect (() => {

        const payload = modalEstado.id
    
        if (payload === undefined || payload === null) {
            return
        }

        const informacoesVenda = async () => {
            
            try {
                const resposta = await detalhesVendas (payload)
                setVendaDetalhes (resposta.data)
            
                setModalDetalhes (true)

            } catch (error) {
                setProcessandoSolicitacao (false)
                return alert ('Falha ao Recuperar os Dados')
            }
        }

        informacoesVenda ()

    }, [setModal, modalEstado])

    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (

        <div id = 'fundo_modal_detalhes_venda'>
            {processandoSolicitacao && <CarregandoCirculo />}
            {confirmacaoEmail && <ConfirmacaoOperacao texto='E-mail Enviado com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Não Existe Boleto para essa Receita' />}
            {modalDetalhes && <div id="visualizar_venda_fundo">
                <div id="venda_superior_dados">
                    <div id = 'visualizar_dados_venda_superior_titulo'>
                        <div>
                            <p id = 'visualisar_dados_titulo'>ID VENDA</p>
                            <p id = 'visualizar_dados_titulo_vendas'><strong>{vendaDetalhes.map ((venda) => ((`000000${venda.id.toString().slice(-6)}`)))}</strong></p>
                        </div>
                    </div>
                    <div id = 'visualizar_dados_venda_superior_cliente'>
                        <div>
                            <p id = 'visualisar_dados_titulo'>CLIENTE</p>
                            <p id = 'visualizar_dados_titulo_vendas'><strong>{vendaDetalhes.map ((venda) => (venda.cliente.nome_fantasia.toUpperCase()))}</strong></p>
                        </div>
                    </div>
                    <div id = 'visualizar_dados_superior_usuario'>
                        {vendaDetalhes.map((venda) => (
                            <img key={Math.random()} id = 'imagem_usuario_venda_postagem' src= {venda.usuario.avatar} alt = ''></img>
                        ))}
                        <div>
                            <p id = 'visualisar_dados_titulo'>VENDIDO POR</p>
                            {vendaDetalhes.map((venda) => (
                                <p key={Math.random()} id = 'visualizar_dados_titulo'><strong>{venda.usuario.nome.toUpperCase()}</strong></p>
                            ))}
                        </div>
                    </div>
                    <img onClick={() => ocultarModal()} id = 'btn_fechar_visualizar_dados' src= {fecharIcone} alt = 'botão de fechar do hg'></img>
                </div>
                <div id = 'venda_detalhes_opcoes'>
                    <div id = 'venda_opcoes'>
                        <p id = 'opcoes_vendas' onClick={() => exibirItens()}>ITENS VENDIDOS</p>
                        <p id = 'opcoes_vendas'onClick={() => exibirFinanceiro()}>FINANCEIRO</p>
                    </div>
                    
                    <div id ='informacoes_venda'>
                        {itensVendidos && <table id = 'venda_itens'>
                            <thead id = 'tb_venda_informacoes_cabecalho'>
                                <th align='left'>DESCRIÇÃO</th>
                                <th align='left'>VALOR</th>
                            </thead>
                            <tbody>
                                {vendaDetalhes.map((venda) =>  venda.vendas_itens.map((item) => (
                                    <tr key={Math.random()}>
                                        <td>{item.servico.nome.toUpperCase()}</td>
                                        <td>{`R$ ${item.valor.replace('.', ',')}`}</td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>}
                        {financeiro && <table id = 'venda_financeiro'>
                            <thead>
                                <th align='left'>DESCRIÇÃO</th>
                                <th align='center'>VENCIMENTO</th>
                                <th align='center'>VALOR TOTAL</th>
                                <th align='center'>SITUAÇÃO</th>
                                <th align='center'>OPÇÕES</th>
                            </thead>
                            <tbody>
                                {vendaDetalhes.map((venda) =>  venda.receitas.map((receita) => (
                                    <tr key={Math.random()}>
                                        <td id = 'descricao_venda_receita'>{receita.descricao.toUpperCase()}</td>
                                        <td align='center'>{`${receita.vencimento.slice(8,10)}/${receita.vencimento.slice(5,7)}/${receita.vencimento.slice(0,4)}`}</td>
                                        <td align='center'>{`R$ ${receita.valor_total}`}</td>
                                        <td align='center'>
                                            <DivSituacao texto= {receita.situacao.toUpperCase()} cor = {receita.situacao.toLowerCase()} />
                                        </td>
                                        <td align='center'>
                                            <div id = 'opcoes_vendas_financeiro'>
                                                <img title='Visualizar Boleto' id = 'visualizar_receita' className={receita.situacao.toUpperCase()} alt='Visualizar receita' src= {visualizarBoletoImg}onClick = {() => visualizarBoletoVendaPdf(receita.id)}/>
                                                <img title='Enviar Boleto por E-mail' id = 'enviar_email_boleto' className={receita.situacao.toUpperCase()} alt='Enviar por E-mail' src= {enviarEmailImg} onClick = {() => emailFatura(receita.id)}/>
                                            </div>
                                        </td>
                                    </tr>
                                )))}
                            </tbody>
                        </table>}
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default VisualizarDadosVenda