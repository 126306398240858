import { useEffect } from 'react'
import DivDados from "../../components/DivDados";
import FiltrosFinanceiro from "../../components/FiltrosFinanceiro";
import DadosFinanceiros from '../../components/DadosFinanceiro';

function Financeiro () {

    useEffect (() => {
        document.title = 'Netclick - Dashboard Finaceiro'
    }, [])

    return (
        <DivDados id ='dados_financeiro_fundo'>
            <>
                <FiltrosFinanceiro />
                <DadosFinanceiros />
            </>
        </DivDados>
    )
  }
  
  export default Financeiro