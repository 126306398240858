import { useState, useEffect } from 'react'
import { ultimosAtendimentos } from '../../services/hg/meuPainel'
import UltimosAtendimentoResposta from '../../interfaces/ultimosAtendimentos'
import NenhumDadoGrafico from '../NenhumDadoGrafico'
import CarregandoPontinhos from '../CarregandoPontinhos'
import './ultimosatendimentos.css'

function UltimosAtendimentos () {

    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [carregando, setCarregando] = useState (true)
    const [atendimentos, setAtendimentos] = useState<UltimosAtendimentoResposta []> ([])
    const [dados, setDados] = useState (false)
    const [semDados, setSemDados] = useState (false)

    useEffect (() => {

        const atendimentos = async () => {

            if (idUsuario === null || idUsuario === undefined) {
                return
            }

            try {

                const resposta = await ultimosAtendimentos (parseInt(idUsuario))

                setCarregando (false)
                setAtendimentos (resposta.data)

                if (resposta.data.length === 0) {
                    return setSemDados(true)
                }
                setDados (true)
                
            } catch (error: any) {

                if (error.status === 401) {
                    return window.location.href = '/'
                }
            }
        }

        atendimentos ()

    }, [idUsuario])


    return (
        
        <div id = 'grafico_ultimo_atendimento'>
            <p id = 'p_ultimo_atendimento'>ÚLTIMAS ATIVIDADES CONCLUÍDAS</p>
            {dados && <section>
                <table id = 'tabela_ultimos_atendimentos'>
                    <thead>
                        <tr id = 'atendimento_Cabecalho'>
                            <th align='left'>CLIENTE</th>
                            <th align='left'>TÍTULO</th>
                            <th align='left'>SERVIÇO</th>
                            <th align='center'>TIPO</th>
                        </tr>
                    </thead>

                    <tbody>
                        {atendimentos.map ((atendimento) => (
                            <tr key={atendimento.id} id = 'lista_atendimentos'>
                                <td align='left'>{atendimento.cliente.nome_fantasia.toUpperCase()}</td>
                                <td align='left'>{atendimento.titulo.toUpperCase()}</td>
                                <td align='left'>{atendimento.servico?.nome.toUpperCase()}</td>
                                <td align='center'>{atendimento.tipo.toUpperCase()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>}
            {carregando && <CarregandoPontinhos cor = 'pontinhosFundoBranco' />}
            {semDados && <NenhumDadoGrafico texto='Nenhuma atividade recente' subtexto='foi concluída' />}
        </div>
    )
}

export default UltimosAtendimentos