import iconeFacebook from '../../assets/img/facebook.png'
import iconeInstagram from '../../assets/img/instagram.png'
import iconeYoutube from '../../assets/img/youtube.png'
import iconeLinkedin from '../../assets/img/linkedin.png'
import iconeGithub from '../../assets/img/github.png'
import iconeBehance from '../../assets/img/behance.png'
import iconeSpotify from '../../assets/img/spotify.png'
import './footer.css'

function Footer () {
    const target = '_blank'
    return (
        <footer>
            <p>
                Inteligência que Transforma
            </p>
            <div id = 'redes_sociais'>
                <a href="https://www.facebook.com/hgconsultoria" target= {target}><img src= {iconeFacebook} width="30px" alt="Página do Hg no Facebook"></img></a>

                <a href="https://www.instagram.com/hgconsultoria" target= {target}><img src= {iconeInstagram} width="30px" alt="Página do Hg no Instagram"></img></a>

                <a href="https://www.youtube.com/channel/UCnH75_kQD4mw8f_LdE7NO3g" target= {target}><img src= {iconeYoutube}width="30px" alt="Canal do Hg no Youtube"></img></a>

                <a href="https://www.linkedin.com/company/hg-consultoria-empresarial/" target= {target}><img src= {iconeLinkedin} width="30px" alt="Página do Hg no Instagram"></img></a>

                <a href="https://github.com/hgconsultoria" target= {target}><img src= {iconeGithub} width="30px" alt="Página do Hg no GitHub"></img></a>

                <a href="https://www.behance.net/hgconsultoria" target= {target}><img src= {iconeBehance} width="30px" alt="Página do Hg no Behance"></img></a>

                <a href="https://open.spotify.com/show/6sSyNgIHFTwaZ0Eo21iLiA?si=8MhS4Zw6RymJveTqc5CKKwnd=1" target= {target}><img src= {iconeSpotify}  width="30px" alt="Página do Hg no Spotify"></img></a>
            </div>
        </footer>
    )
}

export default Footer