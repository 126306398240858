import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuSuporte (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (
        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                {props.titulo}
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '/bug' texto = 'Reportar Problema' />}    
                {subMenu && <SubMenuOpcoes redirect = '/sugestao' texto = 'Sugestão de Melhoria' />}
            </div>
            
        </div>
    )
}

export default MenuSuporte