import { useEffect } from 'react'
import ClientesDashboard from "../../components/ClientesDasboard";
import DivDados from "../../components/DivDados";

function Dashboard() {

    useEffect (() => {
        document.title = 'Netclick - Dashboard'
    }, [])

    return (
        <DivDados id ='dados_dashboard'>
            <ClientesDashboard />
        </DivDados>
    )
  }
  
  export default Dashboard;