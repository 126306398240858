import { createSlice } from "@reduxjs/toolkit"
import { sugestaoListaRequisicaoPayload } from "../../../interfaces/sugestoes"

const filtrosSugestaoReduce = createSlice ({
    name: 'filtros_sugestao',
    initialState: {
        termo: '',
        status:''
    } as sugestaoListaRequisicaoPayload,
    reducers: {
        setFiltrosSugestao (state, action) {
            Object.assign (state, {
                termo: action.payload.termo,
                status: action.payload.status
            })
        }
}})

export const { setFiltrosSugestao } = filtrosSugestaoReduce.actions

export default filtrosSugestaoReduce.reducer