import { useState } from 'react'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuCadastros (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                {props.titulo}
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '/clientes' texto = 'Clientes' />}
                {subMenu && <SubMenuOpcoes redirect = '/responsaveis' texto = 'Responsáveis' />}
                <Perfil perfil={['master', 'administrativo']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Colaboradores' />}
                        {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Fornecedores' />}     
                    </>
                </Perfil>
            </div>        
        </div>
    )
}

export default MenuCadastros