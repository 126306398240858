import './carregando.css'

function CarregandoBarra () {
    return (
        <div id = 'carregando_barra'>
            <span id = "barra_carregando"></span>
        </div>
    )
}

export default CarregandoBarra