import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalNovoModeloProcesso = createSlice ({
    name: 'modalNovoModeloProcesso',
    initialState: {
        exibir: false
    } as ModalEstado,
    reducers: {
        setExibirModalNovoModeloProcesso (state, action) {
            Object.assign (state, {
                exibir: true
            })
        },

        setOcultarModalNovoModeloProcesso (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalNovoModeloProcesso, setOcultarModalNovoModeloProcesso } = modalNovoModeloProcesso.actions

export default modalNovoModeloProcesso.reducer