import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalVisualizarDadosProblemaReduce = createSlice ({
    name: 'modal_visualizar_dados_problema',
    initialState: {
        exibir: false,
        id: 0
    } as ModalEstado,
    reducers: {
        setExibirModalDadosProblema (state, action) {
            Object.assign (state, {
                exibir: true,
                id: action.payload.id
            })
        },

        setOcultarModalDadosProblema (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalDadosProblema, setOcultarModalDadosProblema } = modalVisualizarDadosProblemaReduce.actions

export default modalVisualizarDadosProblemaReduce.reducer