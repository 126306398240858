import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { setFiltrosBugs } from '../../store/modules/filtrosBug'
import { setExibirModalNovoProblema } from '../../store/modules/modalNovoProblema'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './filtrosbugs.css'

function FiltrosBug () {

    const [situacao, setSituacao] = useState ('')
    const [termo, setTermo] = useState('')
    const dispatch = useDispatch ()

    function exibirModalProblemas () {
        dispatch (setExibirModalNovoProblema ({
            exibir: true
        }))
    }

    useEffect (() => {

        function filtrosBugs () {
            dispatch (setFiltrosBugs ({
                termo,
                status: situacao
            }))
        }
    
        filtrosBugs()

    }, [situacao, termo, dispatch])

    return (
        <div id = 'filtro'>
            <div id = 'lado_esquerdo'>
                <div id = 'caixa_pesquisa'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_bug' 
                        placeholder="Busque pelo titulo ou solicitante"
                        onChange={(e) => setTermo (e.target.value)}/>
                    <img id = 'icone_pesquisa' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>
                <select className = "situacao" onChange={(e) => setSituacao (e.target.value)}>
                    <option id = 'situacao' value="">TODOS</option>
                    <option id = 'situacao'>ABERTO</option>
                    <option id = 'situacao'>EM ANÁLISE</option>
                    <option id = 'situacao'>CONCLUÍDO</option>
                </select>
            </div>
            <Btn acao ={exibirModalProblemas} id = 'novo_bug' titulo='NOVO PROBLEMA ' cor='novo'></Btn>
        </div>
    )
}

export default FiltrosBug