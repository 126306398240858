import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { useState, FormEvent, useEffect } from 'react'
import { setOcultarModalNovaTarefaProcesso } from '../../store/modules/modalNovaTarefaProcesso'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { usuariosAtivos } from '../../services/hg/usuarios'
import { listaDeClientes } from '../../services/hg/clients'
import { novaTarefa } from '../../services/hg/tasks'
import { UsuariosAtivos } from '../../interfaces/usuarios'
import { clientesPayload } from '../../interfaces/client'
import listaDeServicos from '../../services/hg/servicosLista'
import servicosListaPayload from '../../interfaces/servicosListaPayload'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './novatarefaprocesso.css'

function ModalNovaTarefaProcesso () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const novaTarefaProcesso = useSelector ((store: RootStore) => store.modalNovaTarefaProcesso)

    let data = new Date()

    let ano = data.getFullYear()
    let mesAtual = (`0${data.getMonth() + 1}`).slice(-2)
    let diaAtual = (`0${data.getDate()}`).slice(-2)
    let dataFormatada = `${ano}-${mesAtual}-${diaAtual}`

    const [clientes, setClientes] = useState<clientesPayload[]> ([])
    const [cliente, setCliente] = useState (0)
    const [responsaveis, setResponsaveis] = useState <UsuariosAtivos []>([])
    const [responsavel, setResponsavel] = useState (0)
    const [servicoLista, setServicoLista] = useState<servicosListaPayload []>([])
    const [servico, setServico] = useState (0)
    const [titulo, setTitulo] = useState('')
    const [tipo, setTipo] = useState ('tarefa')
    const [prioridade, setPrioridade] = useState ('')
    const [descricao, setDescricao] = useState('')
    const [dataInicio, setDataInicio] = useState (`${dataFormatada}`)
    const [dataTermino, setDataTermino] = useState ('')
    const [automacao] = useState(false)
    const [recorrencia] = useState<string | null>(null)
    const [recorrenciaDia] = useState<number | null>(null)
    const [recorrenciaMes] = useState <(Number | null)> (null)
    const [prazo] = useState<number | null> (null)
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalNovaTarefaProcesso ({
            exibir: false
        }))
    }


    const cadastrarTarefaProcesso = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            cliente_id: cliente,
            servico_id: servico,
            processo_id: `${novaTarefaProcesso.id}`,
            titulo,
            descricao,
            responsavel,
            data_inicio: dataInicio,
            data_termino: dataTermino,
            prioridade,
            tipo: tipo,
            automacao,
            recorrencia,
            recorrencia_dia: recorrenciaDia,
            recorrencia_mes: recorrenciaMes,
            prazo
        }

        console.log(payload)

        try {
            const resposta = await novaTarefa(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2500)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 2000)
        }
    }

    
    useEffect (() => {
        
        const clientesLista = async () => {
            
            try {
                const resposta = await listaDeClientes ()
                setClientes(resposta.data)
            } catch (error) {
                alert ('Erro ao listar os Clientes')
            }
        }
        
        clientesLista ()
        
    }, [setClientes])

    useEffect (() => {

        const listaServicos = async () => {
        
            try {
                const resposta = await listaDeServicos()
                setServicoLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de Serviços')
            }
        }

        listaServicos ()

    }, [])

    useEffect (() => {

        const usuariosLista = async () => {

            try {
                const resposta = await usuariosAtivos ()
                return setResponsaveis (resposta.data)
                
            } catch (error) {
                return alert ('Erro ao Listar os Responsáveis')
            }
        }

        usuariosLista ()
    }, [setResponsaveis])

    return (
        <div id = 'fundoModalNovaTarefaProcesso'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="adicionar_tarefa">
                <div id="tarefa_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Nova Tarefa</p>
                </div>

                <div id="tarefa_dados">
                    <form id = 'form_nova_tarefa' onSubmit={cadastrarTarefaProcesso}>        
                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_cliente'>
                                <span>
                                    <p>Cliente</p>
                                    <select id = 'lista_clientes_tarefa' value={cliente} required onChange={(e) => setCliente(parseInt(e.target.value))}>
                                        <option />
                                        {clientes.map((cliente) => (
                                            <option  key={cliente.id} value = {cliente.id}>{cliente.nome_fantasia.toUpperCase()}</option>
                                        ))}
                                    </select>
                                </span>

                                <span>
                                    <p>Serviço</p>
                                    <select id = 'lista_servicos_tarefa' value={servico} required onChange={(e) => setServico(parseInt(e.target.value))}>
                                        <option />
                                        {servicoLista.map ((servico) => (
                                            <option key={servico.id} value = {servico.id}>{servico.nome.toUpperCase()}</option>
                                        ))}
                                    </select>
                                </span>

                                <span>
                                    <p>Prioridade</p>
                                    <select id = 'lista_prioridades_tarefa' value={prioridade} required onChange={(e) => setPrioridade(e.target.value)}>
                                        <option />
                                        <option value = 'urgente'>Urgente</option>
                                        <option value = 'alta'>Alta</option>
                                        <option value = 'baixa'>Baixa</option>
                                    </select>
                                </span>
                            </div> 
                        </div>

                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa'>
                                <p>Título</p>
                                <input 
                                    type = 'text'
                                    id = 'input_tarefa_titulo'
                                    required
                                    onChange={(e) => setTitulo(e.target.value.toLowerCase())}/>
                            </div>
                            
                            <span>
                            <label>Tipo</label>
                            <select id = 'tipoNovaTarefaProcesso' value={tipo} onChange = {(e) => setTipo(e.target.value)}>
                                <option value = 'atendimento'>ATENDIMENTO</option>
                                <option value = 'tarefa'>TAREFA</option>
                                <option value = 'flowup'>FLOWUP</option>
                                <option value = 'chamado' >CHAMADO</option>
                            </select>
                        </span>  
                        </div>

                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa'>
                                <p>Descrição</p>
                                <textarea 
                                    name="descricao_tarefa" 
                                    required
                                    id="observacoes_nova_tarefa" rows={10}  
                                    onChange={(e) => setDescricao (e.target.value.toLowerCase())}/>
                            </div>  
                        </div>

                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_data'>
                                <span>
                                    <p>Data de Início</p>
                                    <input 
                                        id = 'data_inicio_nova_tarefa' 
                                        type="date" 
                                        value={dataInicio}
                                        required
                                        onChange={(e) => setDataInicio (e.target.value)}/>
                                </span>
                                <span>
                                    <p>Data de Término</p>
                                    <input 
                                        id = 'data_termino_nova_tarefa' 
                                        type="date" 
                                        required
                                        onChange={(e) => setDataTermino (e.target.value)}/>
                                </span>
                                <span>
                                    <p>Responsável</p>
                                    <select id = 'lista_responsaveis_tarefa' value={responsavel} required onChange={(e) => setResponsavel(parseInt(e.target.value))}>
                                        <option />
                                        {responsaveis.map((responsavel) => (
                                            <option  key={responsavel.id} value = {responsavel.id}>{responsavel.nome.toUpperCase()}</option>
                                        ))}
                                    </select>
                                </span>
                            </div>
                        </div>

                        <div id="tarefa_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_tarefa' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovaTarefaProcesso