import baseApiHg from "../api/config"

export function tarefasAtrasadas (idUsuario: number) {
    return baseApiHg.get (`/tarefasatrasadas?usuario=${idUsuario}`)
}

export function tarefasPendentes (idUsuario: number) {
    return baseApiHg.get (`/tarefaspendentes?usuario=${idUsuario}`)
}

export function flowupsPendentes (idUsuario: number) {
    return baseApiHg.get (`/flowupspendentes?usuario=${idUsuario}`)
}

export function chamadosPendentes (idUsuario: number) {
    return baseApiHg.get (`/chamadospendentes?usuario=${idUsuario}`)
}

export function atendimentosPendentes (idUsuario: number) {
    return baseApiHg.get (`/atendimentospendentes?usuario=${idUsuario}`)
}

export function ultimosAtendimentos (idUsuario: number) {
    return baseApiHg.get (`/ultimosatendimentos?usuario=${idUsuario}`)
}
