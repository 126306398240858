import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect, FormEvent } from 'react'
import { setOcultarModalDadosSugestao } from '../../store/modules/modalVisualizarDadosSugestao'
import { sugestoesDetalhesResposta } from '../../interfaces/sugestoes'
import { RootStore } from '../../store'
import comentarios from '../../interfaces/comentarios'
import dadosSugestoesDetalhes from '../../services/hg/sugestoesDetalhes'
import sugestaoCadastrarComentarios from '../../services/hg/sugestaoCadastrarComentarios'
import sugestoesCarregarComentarios from '../../services/hg/sugestoesCarregarComentarios'
import CarregandoBarra from '../CarregandoBarra'
import CarregandoCirculo from '../CarregandoCirculo'
import bugIcone from '../../assets/img/bug.png'
import fecharIcone from '../../assets/img/fechar.png'
import Btn from '../Btn'
import './visualizardadossugestao.css'

function VisualizarDadosSugestao () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosSugestao)
    const dispatch = useDispatch ()

    const [sugestoesDetalhes, setSugestoesDetalhes ] = useState<sugestoesDetalhesResposta []>([])
    const [comentariosLista, setComentariosLista] = useState<comentarios []> ([])
    const [carregandoComentarios, setCarregandoComentairos] = useState (false)
    const [comentario, setComentario] = useState<string> ('')
    const [processando, setProcessando] = useState (false)
    const [processandoSolicitacao, setProcessandoSolicitacao] = useState (false)
    const [carregandoBarra, setCarregandoBarra] = useState (true)
    const [btnComentar, setBtnComentar] = useState (true)
    const [modal, setModal] = useState (false)
    const [modalDetalhes, setModalDetalhes] = useState (false)

    function ocultarModal () {
        dispatch (setOcultarModalDadosSugestao ({
            exibir: false
        }))
    }
    
    const comentarSugestao = async (e: FormEvent) => {
        e.preventDefault ()

        const idSugestao = modalEstado.id

        const payload = {
            comentario: comentario
        }

        setProcessando (true)
        setBtnComentar (false)

        try {

            const resposta = await sugestaoCadastrarComentarios (idSugestao, payload)

            if (resposta.status === 200) {
                setComentario ('')
                setProcessando (false)
                setBtnComentar (true)
                return alert ('Comentário postado com sucesso')
            }
            
        } catch (error) {
            setProcessando (false)
            setBtnComentar (true)
            return alert ('Falha ao salvar o seu comentário. Tente Novamente')
        }
    }

    useEffect (() => {

        setProcessandoSolicitacao (true)

        const payload = modalEstado.id
    
        if (payload === undefined || payload === null) {
            return
        }

        const sugestoesDetalhes = async () => {
            
            try {
                const resposta = await dadosSugestoesDetalhes (payload)
                setSugestoesDetalhes (resposta.data)
                
                setProcessandoSolicitacao (false)
                setModalDetalhes (true)

            } catch (error) {
                setProcessandoSolicitacao (false)
                return alert ('Falha ao Recuperar os Dados')
            }
        }

        sugestoesDetalhes ()

    }, [setModal, modalEstado])

    useEffect (() => {
        
        const idSugestao = modalEstado.id

        const comentarios = async () => {

            try {
                const resposta = await sugestoesCarregarComentarios(idSugestao)
                setComentariosLista (resposta.data[0].sugestao_comentarios)
                setCarregandoBarra(false)
                setCarregandoComentairos (true)

            } catch (error) {
                alert ('Falha ao recuperar os comentários')
            }
        }

        comentarios ()

    }, [setComentariosLista, modalEstado])
    
    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    return (
        <div id = 'fundo_modal_detalhes_sugestao'>
            {processandoSolicitacao && <CarregandoCirculo />}
            {modalDetalhes && <div id="visualizar_sugestao_fundo">
                <div id="sugestao_superior_dados">
                    <div id = 'visualizar_dados_superior_titulo'>
                        <img id = 'bug_icone' src= {bugIcone} alt = 'icone de bug do hg'></img>
                        <div>
                            <p id = 'visualisar_dados_titulo'>TÍTULO</p>
                            <p id = 'visualizar_dados_titulo'><strong>{sugestoesDetalhes.map((sugestao) => (sugestao.titulo.toUpperCase()))}</strong></p>
                        </div>
                    </div>
                    <div id = 'visualizar_dados_superior'>
                        <p id = 'visualisar_dados_titulo'>SETOR</p>
                        {sugestoesDetalhes.map((sugestao) => (
                            <p key={Math.random()} id = 'visualizar_dados_titulo'><strong>{sugestao.setore.nome.toUpperCase()}</strong></p>
                        ))}
                    </div>
                    <div id = 'visualizar_dados_superior_usuario'>
                        {sugestoesDetalhes.map((sugestao) => (
                            <img key={Math.random()} id = 'imagem_usuario_problema_postagem' src= {sugestao.usuario.avatar} alt = ''></img>
                        ))}
                        <div>
                            <p id = 'visualisar_dados_titulo'>REPORTADO POR</p>
                            {sugestoesDetalhes.map((sugestao) => (
                                <p key={Math.random()} id = 'visualizar_dados_titulo'><strong>{sugestao.usuario.nome.toUpperCase()}</strong></p>
                            ))}
                        </div>
                    </div>
                    <img onClick={() => ocultarModal()} id = 'btn_fechar_visualizar_dados' src= {fecharIcone} alt = 'botão de fechar do hg'></img>
                </div>

                <div id="visualizar_sugestao_descricao">
                    <div id = 'visualizar_sugestaos_detalhes'>
                        <div id="sugestao_detalhes">
                            <p id = 'descricao_titulo'><strong>Descrição</strong></p>
                            <textarea value = {sugestoesDetalhes.map ((sugestao) => (sugestao.descricao[0].toUpperCase() + sugestao.descricao.substring(1)))} name="descricao_sugestao" id="descricao_sugestao_dados" rows={5} disabled></textarea>
                        </div>
                    </div>      
                    
                    <p id = 'comentario_titulo'><strong>Comentários</strong></p>
                    {carregandoBarra && <CarregandoBarra />}
                    {carregandoComentarios && <div id = 'lista_comentrios_sugestao'>
                        {comentariosLista.map((comentario) => (
                            <div key={Math.random()} id = 'comentario_sugestao'>
                                <div>
                                    <img id = 'usuário_comentario_realizado' src={comentario.usuario.avatar} alt="" />
                                </div>
                                <div id = 'usuario_comentario'>
                                    <p id = 'comentario_usuario'><strong>{comentario.usuario.nome}</strong></p>
                                    <p id = 'comentario_realizado'>{comentario.comentario}</p>
                                </div>
                            </div>))}
                    </div>}
                            
                    <form id = 'comentar_sugestao' onSubmit={comentarSugestao}>
                        <input
                            id = 'input_comentar_problema' 
                            type="text" 
                            required
                            value={comentario}
                            onChange= {(e) => setComentario(e.target.value)}
                        />
                        {processando && <CarregandoBarra />}
                        {btnComentar && <Btn id = 'btn_comentar_problema' titulo='Comentar' cor='novo' />}
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default VisualizarDadosSugestao