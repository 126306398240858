import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { RootStore } from '../../store'
import { setExibirModalDadosProblema } from '../../store/modules/modalVisualizarDadosProblema'
import { bugsListaRespostaPayload } from '../../interfaces/bugs'
import { listaDeBugs } from '../../services/hg/bugs'
import CarregandoCirculo from '../CarregandoCirculo'
import ErrorOperacao from '../ErroOperacao'
import VisualizarDadosProblema from '../VisualizarDadosProblema'
import DivSituacao from '../DivSituacao'
import './listabugs.css'


function ListaDeBugs () {

    const modalEstado =  useSelector ((store: RootStore) => store.modalVisualizarDadosProblema)
    const filtrosBugs = useSelector ((store: RootStore) => store.filtrosBug)
    const dispatch = useDispatch ()

    const [modal, setModal] = useState (false)
    const [bugs, setBugs] = useState<bugsListaRespostaPayload[]> ([])
    const [solicitacao, setSolicitacao] = useState (true)
    const [resultadoPesquisa, setResultadoPesquisa] = useState (false)

    function exibirModalDadosProblemas (id: number) {
        dispatch (setExibirModalDadosProblema ({
            exibir: true,
            id: id
        }))
    }

    useEffect (() => {
        setModal (modalEstado.exibir)
    }, [modalEstado, modal])

    useEffect (() => {

        const payload = {
            termo: filtrosBugs.termo,
            status: filtrosBugs.status
        }

        const bugLista = async () => {

            try {
                const resposta = await listaDeBugs (payload)

                setBugs(resposta.data)
                setSolicitacao (false)

                if (resposta.data.length === 0 && filtrosBugs.status !== "" && filtrosBugs.termo !== "") {
                    setSolicitacao (false)
                    setResultadoPesquisa (true)

                    setTimeout (() => {
                        setResultadoPesquisa (false)
                    }, 2500)
                }
                
            } catch (error) {
                alert ('Erro ao listar dos bugs')
            }
        }

        bugLista ()

    }, [setBugs, filtrosBugs])

    return (
        <div id = 'dados_bug'>
            {modal && <VisualizarDadosProblema />}
            {solicitacao && <CarregandoCirculo />}
            {resultadoPesquisa && <ErrorOperacao texto='Nenhum Resultado Encontrado' />}
            {<table id = 'tabela_bug'>
                <thead>
                    <tr id = 'bugCabecalho'>
                        <td id = 'id_bug'>ID</td>
                        <td>TÍTULO</td>
                        <td>REPORTADO POR</td>
                        <td align = 'center' id = 'status_bug'>STATUS</td>
                    </tr>
                </thead>

                <tbody>
                    {bugs.map ((bug) => (
                        <tr key={bug.id} id = 'lista_bug' onClick={() => exibirModalDadosProblemas(bug.id)}>
                            <td id = 'id_bug'>{(`0000${bug.id}`).slice (-5)}</td>
                            <td>{bug.titulo.toUpperCase()}</td>
                            <td>{bug.usuario.nome.toUpperCase()}</td>
                            <td align = 'center'>
                                <DivSituacao texto= {bug.status.toUpperCase()} cor= {bug.status.replace(/ /g, "")} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
        </div>
    )
}

export default ListaDeBugs