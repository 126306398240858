import { FormEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setOcultarModalNovoProblema } from '../../store/modules/modalNovoProblema'
import { bugsCadastrar } from '../../services/hg/bugs'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import setoresListaPayload from '../../interfaces/setoresListaPayload'
import listaDeSetores from '../../services/hg/setorLista'
import setorId from '../../services/hg/setorId'
import adicionarIcone from '../../assets/icons/adicionar_preto.png'
import Btn from '../Btn'
import './novoproblema.css'

function ModalNovoProblema () {
    
    const dispatch = useDispatch ()

    const [titulo, setTitulo] = useState('')
    const [setor, setSetor] = useState(0)
    const [descricao, setDescricao] = useState('')
    const [anexo, setAnexo] = useState<File>()
    const [setorLista, setSetorLista] = useState<setoresListaPayload []>([])
    const [setorTermo, setSetorTermo] = useState ('')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)

    function ocultarModal () {
        dispatch (setOcultarModalNovoProblema ({
            exibir: false
        }))
    }

    function anexarBug (e: any) {
        if (e.target.files[0] === null || e.target.files[0] === undefined) {
            return
        }
        return setAnexo (e.target.files[0]) 
    }

    const novoBug = async (e: FormEvent) => {
        e.preventDefault ()

        if (anexo === null || anexo === undefined) {
            return
        }

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            titulo,
            setor_id: setor,
            descricao,
            anexo
        }

        try {
            const resposta = await bugsCadastrar(payload)

            if (resposta.status === 201) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()
                }, 3000)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (true)
            return alert ('Erro ao salvar os dados. Tente novamente')
        }
    }

    useEffect (() => {

        const listaSetores = async () => {
        
            try {
                const resposta = await listaDeSetores()
                setSetorLista (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar a lista de setores')
            }
        }

        listaSetores ()

    }, [])

    useEffect (() => {
        
        const setorIdDb = async () => {

            const termo = setorTermo

            try {

                const resposta = await setorId(termo)
                return setSetor(resposta.data[0].id)
            
            } catch (error) {
                return alert ('Falha ao recuperar o id do setor')    
            }
        }

        setorIdDb ()

    }, [setorTermo])
    

    return (
        <div id = 'fundo_modal_novo_problema'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {salvarDados && <div id="adicionar_problema">
                <div id="problema_superior">
                    <img id = 'adicionar_icone' src= {adicionarIcone} alt = 'icone de adicionar novo problema'></img>
                    <p id = 'modal_titulo'>Novo Problema</p>
                </div>

                <div id="problema_dados">
                    <form id = 'form_novo_problema' onSubmit={novoBug}>        
                        <div id="problema_dados">
                            <div id = 'campo_problema'>
                                <p>Título</p>
                                <input
                                    id = 'problema_titulo_novo' 
                                    type="nome" 
                                    maxLength={100} 
                                    required
                                    onChange={(e) => setTitulo (e.target.value)}>
                                </input>
                            </div>
                            <div id = 'campo_problema'>
                                <p>Setor</p>
                                <select id = 'problema_setor' value={setorTermo} onChange={(e) => setSetorTermo (e.target.value)}>
                                    <option>SELECIONE O SETOR</option>
                                    {setorLista.map ((setor) => (
                                        <option key={Math.random()}>{setor.nome.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div>  
                        </div>

                        <div id="problema_dados">
                            <div id ='campo_problema'>
                                <p>Descrição</p>
                                <textarea 
                                    name="descricao" 
                                    id="descricao_novo_problema" 
                                    rows={10}
                                    required 
                                    placeholder="Descreva com riqueza de detalhes o problema ocorrido"
                                    onChange={(e) => setDescricao (e.target.value)}>
                                </textarea>
                                <div id ='campo_problema' className = 'bug_anexo'>
                                    <label htmlFor="bug_print">ANEXE UM PRINT DO BUG</label>
                                    <input 
                                        type="file" 
                                        name="bug_print" 
                                        id="bug_print"
                                        required
                                        onChange={(e) => anexarBug (e)}>
                                    </input>
                                </div>
                            </div>
                        </div>

                        <div id="problema_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_bug' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_bug' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalNovoProblema