import { useState } from 'react'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import './menuopcoes.css'

function MenuFinanceiro (props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu () {
        setSubMenu (subMenu => !subMenu)
    }

    return (

        <div>
            <div id = 'menu_opcoes' onClick={exibirSubmenu}>
                {<img id = 'icone' src= {props.icone} alt = {props.alt}></img>}
                {props.titulo}
            </div>
            <div id = 'submenu_opcoes'>
                {subMenu && <SubMenuOpcoes redirect = '/assinaturas' texto = 'Assinaturas' />}
                {subMenu && <SubMenuOpcoes redirect = '/vendas' texto = 'Vendas' />}
                {subMenu && <SubMenuOpcoes redirect = '/receitas' texto = 'Receitas' />}
                {subMenu && <SubMenuOpcoes redirect = '#' texto = 'Despesas' />}
                {subMenu && <SubMenuOpcoes redirect = '/saldobancario' texto = 'Saldo Bancário' />}    
            </div>        
        </div>
    )
}

export default MenuFinanceiro