import { FormEvent, useState} from 'react'
import { useDispatch } from 'react-redux'
import { setFiltrosModelosProcessos } from '../../store/modules/filtrosModelosProcessos'
import { setExibirModalNovoModeloProcesso } from '../../store/modules/modalNovoModeloProcesso'
import pesquisarImg from '../../assets/icons/pesquisar_preto.png'
import Btn from '../Btn'
import './filtrosModelosProcessos.css'

function FiltrosModelosProcessos () {

    const [situacao, setSituacao] = useState ('')
    const [titulo, setTitulo] = useState('')
    const dispatch = useDispatch()

    function exibirModal () {
        dispatch (setExibirModalNovoModeloProcesso ({
            exibir: true
        }))
    }

    function filtrosModelosProcessos (e: FormEvent) {
        e.preventDefault()

        dispatch (setFiltrosModelosProcessos ({
            titulo,
            situacao
        }))
    }

    return (
        <div id = 'filtro'>
            <form id = 'lado_esquerdo_assinatura' onSubmit={filtrosModelosProcessos}>
                <div id = 'caixa_pesquisa_assinatura'>
                    <input 
                        type = 'text' 
                        id = 'input_pesquisa_assinatura' 
                        placeholder="busque pelo título do modelo do processo"
                        onChange={(e) => setTitulo(e.target.value)}/>
                    <img id = 'icone_pesquisa_assinatura' src= {pesquisarImg} alt = 'caixa de pesquisa de responsáveis'></img>
                </div>

                <select className = "situacao" value={situacao} onChange={(e) => setSituacao(e.target.value.replace(/ /g, ""))}>
                    <option id = 'situacao' value = ''>TODOS</option>
                    <option id = 'situacao' value = 'ativo'>ATIVOS</option>
                    <option id = 'situacao' value = 'inativo'>INATIVOS</option>
                </select>
                <Btn id = 'pesquisar_boleto' titulo='PESQUISAR' cor='novo'/>
            </form>
            <div id = 'lado_direiro_assinatura'>
                <Btn id = 'nova_assinatura' titulo='NOVO MODELO  ' cor='novo' acao={exibirModal} />
            </div>
        </div>
    )
}

export default FiltrosModelosProcessos