import { createSlice } from "@reduxjs/toolkit"
import ModalEstado from "../../../interfaces/modalEstado"

const modalVisualizarDadosSugestaoReduce = createSlice ({
    name: 'modal_visualizar_dados_sugestao',
    initialState: {
        exibir: false,
        id: 0
    } as ModalEstado,
    reducers: {
        setExibirModalDadosSugestao (state, action) {
            Object.assign (state, {
                exibir: true,
                id: action.payload.id
            })
        },

        setOcultarModalDadosSugestao (state, action) {
            Object.assign (state, {
                exibir: false
            })
        }
    }
})

export const { setExibirModalDadosSugestao, setOcultarModalDadosSugestao } = modalVisualizarDadosSugestaoReduce.actions

export default modalVisualizarDadosSugestaoReduce.reducer