import baseApiHg from "../api/config"

export function usuariosAtivos () {
    return baseApiHg.get('/usuarios/ativos')
}

export function usuarioId (termo: string) {
    return baseApiHg.get(`/usuarios/ativos?termo=${termo}`)
}

export default function usuarioLogado () {
    return baseApiHg.post('/login/perfil')
}


