import { useState, FormEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '../../store'
import { setOcultarModalCancelarBoleto } from '../../store/modules/ModalCancelarBoleto'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { cancelarBoleto } from '../../services/inter/boletos'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import cancelarImg from '../../assets/img/cancelar_boleto.png'
import Btn from '../Btn'
import './cancelarboleto.css'

function ModalCancelarBoleto () {

    const dispatch = useDispatch ()
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const modalEstado = useSelector ((store: RootStore) => store.ModalCancelarBoleto)

    const [motivoCancelamento, setMotivoCancelamento] = useState ('PAGODIRETOAOCLIENTE')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    
    function ocultarModal () {
        
        dispatch (setOcultarModalCancelarBoleto ({
            exibir: false
        }))
    }

    const cancelarBoletoInter = async (e: FormEvent) => {

        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            motivoCancelamento
        }

        const nossoNumero = modalEstado.id.toString().padStart(11, '0')


        try {
            const resposta = await cancelarBoleto(nossoNumero, payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 2500)
            }

        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 2000)
        }
    }


    return (
        <div id = 'fundo_modal_cancelar_boleto'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Boleto Cancelado com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Cancelar o Boleto' />}
            {salvarDados && <div id="adicionar_tarefa">
                <div id="tarefa_superior">
                    <img id = 'adicionar_icone' src= {cancelarImg} alt = 'icone de cancelar boleto'></img>
                    <p id = 'modal_titulo'>Cancelar Boleto</p>
                </div>

                <div id="tarefa_dados">
                    <form id = 'form_nova_tarefa' onSubmit={cancelarBoletoInter}>        
                        <div id="tarefa_dados">
                            <div id = 'campo_nova_tarefa_cliente'>
                                <span>
                                    <p>Motivo do Cancelamento</p>
                                    <select id = 'lista_clientes_tarefa' value={motivoCancelamento} onChange = {(e) => setMotivoCancelamento(e.target.value)} required>
                                        <option value = 'ACERTOS'>Cancelado para Acertos</option>
                                        <option value = 'APEDIDODOCLIENTE'>Cancelado a Pedido do Cliente</option>
                                        <option value = 'PAGODIRETOAOCLIENTE'>Cancelado por Ter Sido pado Direto ao Cliente</option>
                                        <option value = 'SUBSTITUICAO'>Cancelado por Substituição</option>
                                    </select>
                                </span>
                            </div> 
                        </div>

                        <div id="tarefa_botao">
                            <div>
                                <Btn acao ={ocultarModal} id = 'cancelar_operacao_tarefa' titulo='CANCELAR' cor='cancelar' />
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_tarefa' titulo='CANCELAR BOLETO' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalCancelarBoleto