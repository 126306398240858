import { useDispatch, useSelector } from 'react-redux'
import { useState, FormEvent, useEffect } from 'react'
import { RootStore } from '../../store'
import { setAtualizarDados } from '../../store/modules/atualizarDados'
import { setOcultarModalRecebimentoPagamento } from '../../store/modules/modalInformarRecebimentoPagamento'
import { receitaAtualizar } from '../../services/hg/receitas'
import listaDeContasBancarias from '../../services/hg/contasBancarias'
import contasPayload from '../../interfaces/contasBancarias'
import contaId from '../../services/hg/contaId'
import { NovoReciboPagamento } from '../../services/hg/email'
import CarregandoCirculo from '../CarregandoCirculo'
import ConfirmacaoOperacao from '../ConfirmacaoOperacao'
import ErrorOperacao from '../ErroOperacao'
import recebimentoPagamentoImg from '../../assets/img/recebimento_pagamento.png'
import fecharImg from '../../assets/img/fechar.png'
import Btn from '../Btn'
import './pagamentorecebimento.css'

function ModalInformarPagamentoRecebimento () {

    const modalEstado = useSelector ((store: RootStore) => store.modalInformarRecebimentoPagamento)
    const atualizarDados = useSelector ((store: RootStore) => store.atualizarDados)
    const dispatch = useDispatch ()
    const data = new Date ()
    const dataAtual = `${data.getFullYear()}-${(`0${data.getMonth() + 1}`).slice(-2)}-${(`0${data.getDate()}`).slice(-2)}`

    const [conta, setConta] = useState (0)
    const [contas, setContas] = useState<contasPayload[]> ([])
    const [contaTermo, setContaTermo] = useState ('')
    const [valor, setValor] = useState (0)
    const [compensacao, setCompensacao] = useState (`${dataAtual}`)
    const [formaRecebimento, setFormaRecebimento] = useState ('boleto bancário')
    const [carregando, setCarregando] = useState (false)
    const [salvarDados, setSalvarDados] = useState (true)
    const [confirmacao, setConfirmacao] = useState (false)
    const [erroOperacao, setErroOperacao] = useState (false)
    const valorContertivo = valor.toString().replace(',', '.')
    
    function ocultarModal () {
        
        dispatch (setOcultarModalRecebimentoPagamento ({
            exibir: false
        }))
    }

    const atualizarReceita = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            pagamento: compensacao,
            forma_recebimento: formaRecebimento,
            conta_bancaria_id: conta,
            valor_recebido: valorContertivo,
            situacao: 'paga'
        }

        try {

            const idReceita = modalEstado.id
            const resposta = await receitaAtualizar(idReceita, payload)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 3000)
            }


        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 3000)
        }
    }


    const atualizarReceitaEmailRecibo = async (e: FormEvent) => {
        e.preventDefault ()

        setCarregando (true)
        setSalvarDados (false)

        const payload = {
            pagamento: compensacao,
            forma_recebimento: formaRecebimento,
            conta_bancaria_id: conta,
            valor_recebido: valorContertivo,
            situacao: 'paga'
        }

        try {

            const idReceita = modalEstado.id
            const resposta = await receitaAtualizar(idReceita, payload)
            
            const emailRecibo = await NovoReciboPagamento (idReceita)

            if (resposta.status === 200) {
                setCarregando (false)
                setConfirmacao (true)

                return setTimeout (() => {
                    setConfirmacao (false)
                    ocultarModal ()

                    dispatch (setAtualizarDados ({
                        atualizar: (atualizarDados.atualizar === true ? false : true)
                    }))
                }, 3000)
            }


        } catch (error) {
            setCarregando (false)
            setSalvarDados (false)
            setErroOperacao (true)
            
            return setTimeout (() => {
                setErroOperacao (false)
                ocultarModal ()
            }, 3000)
        }
    }

    useEffect (() => {

        const listadeContas = async () => {
        
            try {
                const resposta = await listaDeContasBancarias()
                setContas (resposta.data)

            } catch (error) {
                return alert ('Falha ao recuperar as Contas Bancárias')
            }
        }

        listadeContas ()

    }, [])
    
    useEffect (() => {
        
        const contaIdDb = async () => {

            const termo = contaTermo

            try {

                const resposta = await contaId(termo)
                return setConta(resposta.data[0].id)
            
            } catch (error) {
                return alert ('Falha ao recuperar o id da Conta Bancária')    
            }
        }

        contaIdDb ()

    }, [contaTermo])


    return (
        <div id = 'fundo_modal_pagamento_recebimento'>
            {carregando && <CarregandoCirculo />}
            {confirmacao && <ConfirmacaoOperacao texto='Dados Salvos com Sucesso' />}
            {erroOperacao && <ErrorOperacao texto='Falha ao Salvar os Dados' />}
            {salvarDados && <div id="dados_baixa">
                <div id="assinatura_superior">
                    <span>
                        <img id = 'adicionar_icone' src= {recebimentoPagamentoImg} alt = 'icone de adicionar novo problema'></img>
                        <p id = 'modal_titulo'>DADOS DA BAIXA</p>
                    </span>
                    <span>
                        <img id = 'fechar_modal_informar_recebimento' src= {fecharImg} alt = 'fehcar modal de informar recebimento' onClick={ocultarModal}></img>
                    </span>
                </div>

                <div id="assinatura_dados">
                    <form id = 'form_nova_assinatura' onSubmit={atualizarReceita}>

                        <div id="assinatura_dados">
                            <div id = 'campo_nova_assinatura'>
                                <p>Data de Compensação</p>
                                <input 
                                    id = 'vencimento_nova_assinatura' 
                                    type="date"
                                    value={compensacao} 
                                    required
                                    onChange={(e) => setCompensacao (e.target.value)}/>
                            </div>
                            <div id = 'campo_nova_assinatura'>
                                <p id = 'p_conta_nova_assinatura'>Conta</p>
                                <select id = 'conta_nova_assinatura' value={contaTermo} required onChange={(e) => setContaTermo(e.target.value)}>
                                    <option />
                                    {contas.map ((conta) => (
                                        <option key={Math.random()}>{conta.descricao.toUpperCase()}</option>
                                    ))}
                                </select>
                            </div>  
                        </div>

                        <div id="assinatura_dados_item">
                            <div id = 'campo_nova_assinatura_item'>
                                <p>Forma de Pagamento</p>
                                <select id = 'servico_nova_assinatura' value={formaRecebimento} onChange={(e) => setFormaRecebimento(e.target.value)} required>
                                    <option value = 'boleto bancário'>BOLETO BANCÁRIO</option>
                                    <option value = 'transferência bancária'>TRANSFERÊNCIA BANCÁRIA</option>
                                    <option value = 'pix'>PIX</option>
                                    <option value = 'dinheiro'>DINHEIRO</option>
                                </select>
                            </div>
                            <div id = 'campo_nova_assinatura_item'>
                                <p id = 'p_valor_nova_assinatura'>Valor Recebido</p>
                                <input 
                                id = 'valor_nova_assinatura'
                                type = 'text'
                                required
                                onChange={(e) => setValor (parseFloat(e.target.value))}/>
                            </div>
                        </div>

                        <div id="dados_baixa_botao">
                            <div>
                                <button id = 'salvar_dados_enviar_recibo' type='submit' onClick={(e: FormEvent) => atualizarReceitaEmailRecibo(e)} >SALVAR DADOS E ENVIAR RECIBO</button>
                            </div>
                            <div>
                                <Btn id = 'salvar_dados_assinatura' titulo='SALVAR DADOS' cor='salvar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>}
        </div>
    )
}

export default ModalInformarPagamentoRecebimento