import { useEffect } from 'react'
import Perfil from "../../middleware/perfil";
import DivDados from "../../components/DivDados";
import FiltrosResponsaveis from "../../components/FiltrosResponsaveis";
import ListaDeResponsaveis from "../../components/ListaDeResponsaveis";

function Responsaveis() {

    useEffect (() => {
        document.title = 'Netclick - Responsáveis'
    }, [])

    return (
        <DivDados id = 'dados_responsaveis_fundo'>
            <Perfil perfil={['master', 'atendimento']}>
                <>
                    <FiltrosResponsaveis />
                    <ListaDeResponsaveis />
                </>
            </Perfil>
        </DivDados>
    )
  }
  
  export default Responsaveis;