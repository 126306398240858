import { useState, useEffect } from 'react'
import { tarefasAtrasadas, tarefasPendentes, flowupsPendentes, chamadosPendentes, atendimentosPendentes } from '../../services/hg/meuPainel'
import meuPainelTarefasResposta from '../../interfaces/meuPainel'
import CarregandoPontinhos from '../CarregandoPontinhos'
import zeroAtrasadasImg from '../../assets/img/gif_apaixonado.gif'
import tarefasAtrasadasImg from '../../assets/img/gif_triste.gif'
import './resumotarefas.css'

function ResumoTarefas () {

    const idUsuario = localStorage.getItem('idUsuarioLogadoHg')

    const [carregando, setCarregando] = useState (true)
    const [tarefaAtrasadas, setTarefasAtrasadas] = useState<meuPainelTarefasResposta>()
    const [tarefaPendentes, setTarefasPendentes] = useState<meuPainelTarefasResposta>()
    const [flowupPendentes, setFlowupsPendentes] = useState<meuPainelTarefasResposta>()
    const [chamadoPendentes, setChamadosPendentes] = useState<meuPainelTarefasResposta>()
    const [atendimentoPendentes, setAtendimentosPendentes] = useState<meuPainelTarefasResposta>()
    const [dados, setDados] = useState (false)


    useEffect (() => {

        const tarefas = async () => {

            if (idUsuario === null || idUsuario === undefined) {
                return
            }

            try {

                const respotaTarefasAtrasadas = await tarefasAtrasadas (parseInt (idUsuario))
                const respotaTarefasPendentes = await tarefasPendentes (parseInt (idUsuario))
                const respostaFlowupsPendentes = await flowupsPendentes (parseInt(idUsuario))
                const respostaChamadosPendentes = await chamadosPendentes (parseInt(idUsuario))
                const respostaAtendimentoPendente = await atendimentosPendentes (parseInt(idUsuario))
                setTarefasAtrasadas (respotaTarefasAtrasadas.data)
                setTarefasPendentes (respotaTarefasPendentes.data)
                setFlowupsPendentes (respostaFlowupsPendentes.data)
                setChamadosPendentes (respostaChamadosPendentes.data)
                setAtendimentosPendentes (respostaAtendimentoPendente.data)
                setCarregando (false)
                setDados (true)
                
            } catch (error) {
                
            }
        }

        tarefas ()

    }, [idUsuario])

    return (
        <div id = 'resumo_tarefas'>
            <p id = 'p_tarefas'>QUADRO RESUMO</p>
            {carregando && <CarregandoPontinhos cor='pontinhosFundoBranco' />}
            {dados && <div id = 'quadro_resumo'>
                <span id = 'tarefas_atrasadas'>
                    <h1>{tarefaAtrasadas?.quantidade}</h1>
                    <img id = 'tarefas_img' src = {tarefaAtrasadas?.quantidade === 0 ? zeroAtrasadasImg : tarefasAtrasadasImg } alt = '' />
                    <p>Tarefas Atrasadas</p>

                </span>
                <span id = 'demais_tarefas'>
                    <span>
                        <p>Tarefas Pendentes</p>
                        <h1>{tarefaPendentes?.quantidade}</h1>
                    </span>
                    <span>
                        <p>Flowups Pendentes</p>
                        <h1>{flowupPendentes?.quantidade}</h1>
                    </span>
                    <span>
                        <p>Chamados Pendentes</p>
                        <h1>{chamadoPendentes?.quantidade}</h1>
                    </span>
                    <span>
                        <p>Atendimentos Pendentes</p>
                        <h1>{atendimentoPendentes?.quantidade}</h1>
                    </span>
                </span>
            </div>}
        </div>
    )
}

export default ResumoTarefas